import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import CircleIcon from '@mui/icons-material/Circle'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import parse from 'html-react-parser'

import {
  AdasAccordion,
  AdasAccordionDetails,
  AdasAccordionSummary,
  AdasList,
  AdasListItem,
  AdasListItemIcon,
  AdasListItemText,
  AdasTypography
} from '@components/wrapper-components'
import { DEFAULT_FAQS } from '../../constants'

const Accordion = styled((props) => <AdasAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0
  },
  '&:before': {
    display: 'none'
  }
}))

const AccordionSummary = styled((props) => (
  <AdasAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)'
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1)
  }
}))

export function FaqList({ faqs = DEFAULT_FAQS, filter = '' }) {
  const filteredFaq = useMemo(() => {
    const lowerFilter = filter.toLowerCase()
    const retFAQs = faqs.filter((faq) => {
      const filteredAnswers = faq.a.find((answ) => answ.toLowerCase().includes(lowerFilter))
      return filteredAnswers?.length > 0 || faq.q.toLowerCase().includes(lowerFilter)
    })
    return retFAQs
  }, [faqs, filter])

  return (
    <>
      {filteredFaq?.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary aria-controls='panel1a-content' id='panel1a-header'>
            <AdasTypography color='primary'>{faq.q}</AdasTypography>
          </AccordionSummary>
          <AdasAccordionDetails bordertop='1px solid rgba(0, 0, 0, .125)'>
            <AdasList>
              {faq.a.map((ans) => (
                <AdasListItem key={`${index}-${ans}`} sx={{ padding: '0' }}>
                  <AdasListItemIcon sx={{ minWidth: '28px', marginLeft: '1rem' }}>
                    <CircleIcon sx={{ fontSize: '.6rem' }} />
                  </AdasListItemIcon>
                  <AdasListItemText>{parse(ans)}</AdasListItemText>
                </AdasListItem>
              ))}
            </AdasList>
          </AdasAccordionDetails>
        </Accordion>
      ))}
    </>
  )
}

FaqList.propTypes = {
  faqs: PropTypes.array,
  filter: PropTypes.string
}
