import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import { getPhotosSummary, getWorkOrderByReferenceNumber, getInvoiceByReferenceNumber } from '../../api/api'
import { PHOTO_SUMMARY_FIELD_MAP } from '../../variable-maps'
import { MultiPhotoSection, PhotoInput } from '@components/photo'
import { AdasBox, AdasButton, AdasContainer, AdasPaper, AdasTypography } from '@components/wrapper-components'
import { PHOTO_LABELS, PARAM_NAMES, REDIRECT_URLS } from '../../constants'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { Logger } from '../../logger'
import { FieldNames, OperationType, StatusEnum } from '../../enums/enums'
import { useScrollTop } from '../../utils/useScrollTop'

export const PhotoPage = (props) => {
  useScrollTop()
  const { [PARAM_NAMES.REFERENCE_NUMBER]: referenceNumber } = useParams()
  const { fromInvoice = false } = props
  const history = useHistory()
  const [dataUrls, setDataUrls] = useState({
    dashImageUrl: {},
    dashPostCalibrationImageUrl: {},
    weightImageUrl1: {},
    weightImageUrl2: {},
    driverFrontImageUrl: {},
    driverRearImageUrl: {},
    passengerFrontImageUrl: {},
    passengerRearImageUrl: {},
    driverFrontWheelImageUrl: {},
    driverRearWheelImageUrl: {},
    passengerFrontWheelImageUrl: {},
    passengerRearWheelImageUrl: {},
    alignmentImageUrl: {},
    alignmentImageUrl2: {}
  })

  const [additionalPhotos, setAdditionalPhotos] = useState([])
  const [frontCameraPhotos, setFrontCameraPhotos] = useState([])
  const [rearCameraPhotos, setRearCameraPhotos] = useState([])
  const [surroundViewPhotos, setSurroundViewPhotos] = useState([])
  const [blindSpotPhotos, setBlindSpotPhotos] = useState([])
  const [frontRadarCameraPhotos, setFrontRadarCameraPhotos] = useState([])
  const [labelList, setLabelList] = useState([])
  const [workOrderData, setWorkOrderData] = useState({}) //Object is filled with only required fields
  const setLoading = useLoadingStore((store) => store.setLoading)
  const loading = useLoadingStore((store) => store.loading)

  const redirectToForbiddenPage = () => {
    history.replace(REDIRECT_URLS.FORBIDDEN_PAGE)
  }

  const requestPhotoSummary = useCallback(async (params) => {
    try {
      setLoading(true)
      const response = await getPhotosSummary(params)
      if (response.status === 200) {
        const photoSummaryData = response.data
        for (const key of Object.keys(PHOTO_SUMMARY_FIELD_MAP)) {
          if (photoSummaryData[key]) {
            const imageItemDetail = photoSummaryData[key]
            setDataUrls((prevState) => {
              const imageUrls = {
                ...prevState,
                [PHOTO_SUMMARY_FIELD_MAP[key]]: imageItemDetail
              }
              return imageUrls
            })
          }
        }
        setAdditionalPhotos(photoSummaryData.additionalPhotos)
        setFrontCameraPhotos(photoSummaryData.frontCameraPhotos)
        setBlindSpotPhotos(photoSummaryData.blindSpotMonitoringPhotos)
        setSurroundViewPhotos(photoSummaryData.surroundViewCameraPhotos)
        setRearCameraPhotos(photoSummaryData.rearCameraPhotos)
        setFrontRadarCameraPhotos(photoSummaryData.frontRadarCameraPhotos)
      }
    } catch (error) {
      Logger.error({ payload: params, message: error })
    } finally {
      setLoading(false)
    }
  }, [])

  const isHistoricalImagesPresent = () => {
    return (
      frontCameraPhotos?.length > 0 ||
      blindSpotPhotos?.length > 0 ||
      surroundViewPhotos?.length > 0 ||
      rearCameraPhotos?.length > 0 ||
      frontRadarCameraPhotos?.length > 0
    )
  }

  const createDefaultLabelList = useCallback(() => {
    let labelList = []
    if (workOrderData.calibrations.length > 0) {
      const calibrationLabels = workOrderData.calibrations.map((item) => item.calibration.name)
      labelList = [...calibrationLabels]
    }

    if (workOrderData.services.length > 0) {
      const serviceLabels = workOrderData.services.map((item) => item.service.name)
      labelList = [...labelList, ...serviceLabels]
    }

    if (workOrderData.customItems.length > 0) {
      const customItemsLabels = workOrderData.customItems.map((item) => item.name)
      labelList = [...labelList, ...customItemsLabels]
    }
    setLabelList((prev) => {
      const updated = Array.from(new Set([...prev, ...labelList]))
      return updated
    })
  }, [workOrderData.calibrations, workOrderData.services, workOrderData.customItems])

  const refresh = useCallback(
    (refreshedData) => {
      if (refreshedData) {
        const multiplePhotosFields = [
          FieldNames.ADDITIONAL_PHOTOS,
          FieldNames.FRONT_CAMERA_PHOTOS,
          FieldNames.REAR_CAMERA_PHOTOS,
          FieldNames.SURROUND_VIEW_PHOTOS,
          FieldNames.BLIND_SPOT_PHOTOS
        ]
        const propertyName = refreshedData.imageLocation
        if (!multiplePhotosFields.includes(propertyName)) {
          if (refreshedData.operation === OperationType.ROTATE) {
            setDataUrls((prevState) => ({
              ...prevState,
              [propertyName]: {
                ...prevState[propertyName],
                img_rotation: refreshedData.img_rotation
              }
            }))
          }
          if (refreshedData.operation === OperationType.DELETE) {
            setDataUrls((prevState) => ({
              ...prevState,
              [propertyName]: null
            }))
          }
          if (refreshedData.operation === OperationType.RETAKE) {
            const imageItemDetail = {
              img_rotation: refreshedData.img_rotation,
              id: refreshedData.attachmentId
            }

            setDataUrls((prevState) => ({
              ...prevState,
              [propertyName]: imageItemDetail
            }))
          }
        }
      } else if (workOrderData.id) {
        const params = {
          workorderId: workOrderData.id
        }
        requestPhotoSummary(params)
        createDefaultLabelList()
      }
    },
    [requestPhotoSummary, createDefaultLabelList, workOrderData.id]
  )

  useEffect(() => {
    refresh()
  }, [refresh, requestPhotoSummary, workOrderData.id])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (fromInvoice) {
          let response = await getInvoiceByReferenceNumber({ referenceNumber })
          if (response?.status === 200) {
            if (!response.data?.invoiceDto) {
              redirectToForbiddenPage()
              return
            }
            let commonWorkOrderData = {}
            commonWorkOrderData.safetySystemsItems = response.data.safetySystemsItems
            commonWorkOrderData.calibrations = response.data.workOrderCalibrations
            commonWorkOrderData.services = response.data.workOrderServices
            commonWorkOrderData.customItems = response.data.customItems
            commonWorkOrderData.id = response.data.workOrderDto.id
            commonWorkOrderData.type = response.data.invoiceDto.type
            commonWorkOrderData.status = response.data.invoiceDto.status
            setWorkOrderData(commonWorkOrderData)
          } else {
            //Given referenceNumber doesnot exist or no permission granted
            redirectToForbiddenPage()
            return
          }
        } else {
          let response = await getWorkOrderByReferenceNumber({ referenceNumber })
          if (response?.status === 200) {
            //Url Tampared i.e invoice/quote record tried to open in workorder path
            if (StatusEnum[response.data?.workOrder?.status] === StatusEnum.MOVED) {
              redirectToForbiddenPage()
              return
            }
            let commonWorkOrderData = {}
            commonWorkOrderData.safetySystemsItems = response.data.safetySystemsItems
            commonWorkOrderData.calibrations = response.data.workOrderCalibrations
            commonWorkOrderData.services = response.data.workOrderServices
            commonWorkOrderData.customItems = response.data.customItems
            commonWorkOrderData.id = response.data.workOrder.id
            commonWorkOrderData.type = response.data.workOrder.type
            commonWorkOrderData.status = response.data.workOrder.status
            setWorkOrderData(commonWorkOrderData)
          } else {
            //Given referenceNumber doesnot exist or no permission granted
            redirectToForbiddenPage()
            return
          }
        }
      } catch (error) {
        Logger.error({
          message: error,
          payload: {
            referenceNumber
          }
        })
      }
    }
    if (referenceNumber) fetchData()
  }, [referenceNumber])

  const generatePhotoInputProps = (fieldLocation, labelText, title) => {
    const fieldData = dataUrls[fieldLocation] || {}
    return {
      labelText,
      imageLocation: fieldLocation,
      workorderId: workOrderData.id,
      onUpdate: refresh,
      disabled: disableEdit,
      properties: fieldData,
      imageId: fieldData.id || '',
      img_rotation: fieldData.img_rotation || 0,
      title: title
    }
  }

  const classes = {
    subHeading: {
      fontWeight: '700 !important',
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '266%',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      color: 'rgba(26, 27, 31, 0.87)',
      padding: '10px 0'
    }
  }

  const isServiceOnly = workOrderData.type === 'SERVICE_ONLY'
  const disableEdit = StatusEnum[workOrderData.status] === StatusEnum.CLOSED || fromInvoice
  return (
    <AdasContainer maxWidth='lg'>
      {!loading && (
        <AdasPaper elevation={0} sx={{ padding: '1.25rem', marginTop: '1.25rem' }}>
          <AdasBox>
            <h2>Photos</h2>
          </AdasBox>
          <AdasBox>
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                marginBottom: '20px',
                background: '#FDFBFF'
              }}
            >
              <AdasTypography sx={classes.subHeading}>DASHBOARD</AdasTypography>
              <AdasBox sx={{ display: 'flex' }}>
                <AdasBox sx={{ marginRight: '20px', width: '50%' }}>
                  <PhotoInput {...generatePhotoInputProps(FieldNames.DASH_IMAGE_URL, 'DASH PRE CALIBRATION')} />
                </AdasBox>
                <AdasBox sx={{ width: '50%' }}>
                  <PhotoInput {...generatePhotoInputProps('dashPostCalibrationImageUrl', 'DASH POST CALIBRATION')} />
                </AdasBox>
              </AdasBox>
            </AdasPaper>
          </AdasBox>
          {!isServiceOnly && (
            <AdasBox sx={{ marginTop: '20px' }}>
              <AdasPaper variant='outlined' sx={{ padding: '0px 20px', background: '#FDFBFF' }}>
                <AdasTypography sx={classes.subHeading}>FUEL WEIGHT COMPENSATION</AdasTypography>
                <AdasBox sx={{ display: 'flex' }}>
                  <AdasBox sx={{ marginRight: '20px', width: '50%' }}>
                    <PhotoInput {...generatePhotoInputProps(FieldNames.WEIGHT_IMAGE_URL_1, 'ADD PHOTO OF WEIGHT')} />
                  </AdasBox>
                  <AdasBox sx={{ width: '50%' }}>
                    <PhotoInput {...generatePhotoInputProps(FieldNames.WEIGHT_IMAGE_URL_2, 'ADD PHOTO OF WEIGHT')} />
                  </AdasBox>
                </AdasBox>
              </AdasPaper>
            </AdasBox>
          )}
          <AdasBox sx={{ marginTop: '20px' }}>
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                marginBottom: '20px',
                background: '#FDFBFF'
              }}
            >
              <AdasTypography sx={classes.subHeading}>FOUR CORNERS</AdasTypography>
              <AdasBox sx={{ display: 'flex' }}>
                <AdasBox sx={{ marginRight: '20px', width: '50%' }}>
                  <PhotoInput {...generatePhotoInputProps(FieldNames.DRIVER_FRONT_IMAGE_URL, 'DRIVER SIDE FRONT CORNER')} />
                  <PhotoInput {...generatePhotoInputProps(FieldNames.DRIVER_REAR_IMAGE_URL, 'DRIVER SIDE REAR CORNER')} />
                </AdasBox>
                <AdasBox sx={{ width: '50%' }}>
                  <PhotoInput {...generatePhotoInputProps(FieldNames.PASSENGER_FRONT_IMAGE_URL, 'PASSENGER SIDE FRONT CORNER')} />
                  <PhotoInput {...generatePhotoInputProps(FieldNames.PASSENGER_REAR_IMAGE_URL, 'PASSENGER SIDE REAR CORNER')} />
                </AdasBox>
              </AdasBox>
            </AdasPaper>

            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                marginBottom: '20px',
                background: '#FDFBFF'
              }}
            >
              <AdasTypography sx={classes.subHeading}>TIRE PRESSURE</AdasTypography>
              <AdasBox sx={{ display: 'flex' }}>
                <AdasBox sx={{ marginRight: '20px', width: '50%' }}>
                  <PhotoInput {...generatePhotoInputProps(FieldNames.DRIVER_FRONT_WHEEL_IMAGE_URL, 'DRIVER SIDE FRONT WHEEL')} />
                  <PhotoInput {...generatePhotoInputProps(FieldNames.DRIVER_REAR_WHEEL_IMAGE_URL, 'DRIVER SIDE REAR WHEEL')} />
                </AdasBox>
                <AdasBox sx={{ width: '50%' }}>
                  <PhotoInput {...generatePhotoInputProps(FieldNames.PASSENGER_FRONT_WHEEL_IMAGE_URL, 'PASSENGER SIDE FRONT WHEEL')} />
                  <PhotoInput {...generatePhotoInputProps(FieldNames.PASSENGER_REAR_WHEEL_IMAGE_URL, 'PASSENGER SIDE REAR WHEEL')} />
                </AdasBox>
              </AdasBox>
            </AdasPaper>
          </AdasBox>
          <AdasBox sx={{ marginTop: '20px' }}>
            <AdasPaper variant='outlined' sx={{ padding: '0px 20px', background: '#FDFBFF' }}>
              <AdasTypography sx={classes.subHeading}>WHEEL ALIGNMENT</AdasTypography>
              <AdasBox sx={{ display: 'flex' }}>
                <AdasBox sx={{ marginRight: '20px', width: '50%' }}>
                  <PhotoInput
                    {...generatePhotoInputProps(FieldNames.ALIGNMENT_IMAGE_URL, 'ADD PHOTO OF WHEEL ALIGNMENT')}
                    allowDelete={true}
                  />
                </AdasBox>
                {(dataUrls[FieldNames.ALIGNMENT_IMAGE_URL]?.id || dataUrls[FieldNames.ALIGNMENT_IMAGE_URL_2]?.id) && (
                  <AdasBox sx={{ marginRight: '20px', width: '50%' }}>
                    <PhotoInput
                      {...generatePhotoInputProps(FieldNames.ALIGNMENT_IMAGE_URL_2, 'ADD PHOTO OF WHEEL ALIGNMENT')}
                      allowDelete={true}
                    />
                  </AdasBox>
                )}
              </AdasBox>
            </AdasPaper>
          </AdasBox>
          {(!isServiceOnly || isHistoricalImagesPresent()) && (
            <>
              <MultiPhotoSection
                title={PHOTO_LABELS.FRONT_CAMERA}
                addText={PHOTO_LABELS.ADD_PHOTO}
                photoInfoArray={frontCameraPhotos}
                workOrderId={workOrderData.id}
                refresh={refresh}
                disabled={disableEdit}
                imageLocation={FieldNames.FRONT_CAMERA_PHOTOS}
                labelList={labelList}
                setMultiPhotos={setFrontCameraPhotos}
                isMultiUpload={true}
                maxPhotos={16}
              />
              <MultiPhotoSection
                title={PHOTO_LABELS.REAR_CAMERA}
                addText={PHOTO_LABELS.ADD_PHOTO}
                photoInfoArray={rearCameraPhotos}
                workOrderId={workOrderData.id}
                refresh={refresh}
                disabled={disableEdit}
                imageLocation={FieldNames.REAR_CAMERA_PHOTOS}
                labelList={labelList}
                setMultiPhotos={setRearCameraPhotos}
                isMultiUpload={true}
                maxPhotos={16}
              />
              <MultiPhotoSection
                title={PHOTO_LABELS.SURROUND_VIEW_CAMERA}
                addText={PHOTO_LABELS.ADD_PHOTO}
                photoInfoArray={surroundViewPhotos}
                workOrderId={workOrderData.id}
                refresh={refresh}
                disabled={disableEdit}
                imageLocation={FieldNames.SURROUND_VIEW_PHOTOS}
                labelList={labelList}
                isMultiUpload={true}
                maxPhotos={24}
              />
              <MultiPhotoSection
                title={PHOTO_LABELS.BLIND_SPOT_MONITORING}
                addText={PHOTO_LABELS.ADD_PHOTO}
                photoInfoArray={blindSpotPhotos}
                workOrderId={workOrderData.id}
                refresh={refresh}
                disabled={disableEdit}
                imageLocation={FieldNames.BLIND_SPOT_PHOTOS}
                labelList={labelList}
                isMultiUpload={true}
                maxPhotos={16}
              />
              <MultiPhotoSection
                title={PHOTO_LABELS.FRONT_RADAR}
                addText={PHOTO_LABELS.ADD_PHOTO}
                photoInfoArray={frontRadarCameraPhotos}
                workOrderId={workOrderData.id}
                refresh={refresh}
                disabled={disableEdit}
                imageLocation={FieldNames.FRONT_RADAR_CAMERA_PHOTOS}
                labelList={labelList}
                isMultiUpload={true}
                maxPhotos={16}
              />
            </>
          )}
          <MultiPhotoSection
            title={PHOTO_LABELS.ADDITIONAL_PHOTOS}
            photoInfoArray={additionalPhotos}
            workOrderId={workOrderData.id}
            refresh={refresh}
            disabled={disableEdit}
            imageLocation={FieldNames.ADDITIONAL_PHOTOS}
            labelList={labelList}
            isMultiUpload={true}
            maxPhotos={isServiceOnly ? (additionalPhotos?.length > 12 ? 24 : 12) : 24}
          />
          <AdasBox>
            <AdasButton variant='contained' aria-label='DONE' color='primary' onClick={() => history.goBack()}>
              DONE
            </AdasButton>
          </AdasBox>
        </AdasPaper>
      )}
    </AdasContainer>
  )
}

PhotoPage.propTypes = {
  fromInvoice: PropTypes.bool
}
