import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import PropTypes from 'prop-types'

export const AdasLocalizationProvider = ({ children }) => {
  return <LocalizationProvider dateAdapter={AdapterDayjs}>{children}</LocalizationProvider>
}

AdasLocalizationProvider.propTypes = {
  children: PropTypes.node.isRequired
}
