import { useEffect, useRef, useState } from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined'
import { useTheme } from '@mui/material'
import { red } from '@mui/material/colors'
import PropTypes from 'prop-types'

import { ConfirmModal } from '@components/common'
import { GENERIC_MESSAGES, BUTTONS_DISPLAY_LABEL } from '../../../constants'
import { AdasBox, AdasButton, AdasTooltip, AdasTypography, AdasImage } from '@components/wrapper-components'
import { AdasLoader } from '@components/adas-loader'
import { Logger } from '../../../logger'
import { PhotoViewerWithoutThumbnail } from '../photo-viewer-without-thumbnail'

const DeleteMe = ({ whenDelete }) => {
  return (
    <AdasTooltip title='Delete'>
      <AdasButton
        buttonType='iconButton'
        size='medium'
        sx={{
          position: 'absolute',
          top: '.5rem',
          right: '.5rem'
        }}
        onClick={whenDelete}
      >
        <DeleteForeverIcon fontSize='inherit' sx={{ color: red[400] }} />
      </AdasButton>
    </AdasTooltip>
  )
}

export const PhotoInputWithoutThumbnail = ({
  parentId,
  labelText,
  title,
  imageLocation,
  onUpdate,
  allowDelete = false,
  imageDataObj,
  fetchImage,
  disabled = false
}) => {
  const theme = useTheme()
  const fileRef = useRef(null)
  const [isModalOpen, setModalOpen] = useState(false)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [imageUrl, setImageUrl] = useState(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchImageData = async () => {
      if (imageDataObj?.id) {
        try {
          setLoading(true)
          const imageData = await fetchImage(imageDataObj.id)
          const url = URL.createObjectURL(imageData.data)
          setImageUrl(url)
        } catch (error) {
          Logger.error({ message: error, payload: { path: 'PhotoInput/getSupportPhoto', imageDataObj } })
        } finally {
          setLoading(false)
        }
      } else {
        setImageUrl(imageDataObj?.imageUrl)
      }
    }

    fetchImageData()
  }, [imageDataObj])

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const handleUpload = async (ref) => {
    const file = ref.current.files[0]
    if (file) {
      const formData = new FormData()
      formData.append('file', file, file.name)
      setImageUrl(URL.createObjectURL(file))
      onUpdate(formData, imageLocation, URL.createObjectURL(file))
    }
  }

  const deleteAttachment = async () => {
    setShowConfirmModal(false)
    setImageUrl('')
    onUpdate(null, imageLocation, null)
  }

  const deleteWithConfirmation = () => {
    setShowConfirmModal(true)
  }

  return !loading && !imageDataObj ? (
    <AdasBox
      sx={{
        height: '200px',
        border: `1px dashed ${theme.palette.divider}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px',
        borderRadius: '4px'
      }}
    >
      <AdasButton
        component='label'
        sx={{
          fontSize: 12,
          margin: 'auto',
          display: 'flex',
          alignItems: 'center'
        }}
        startIcon={<PhotoCameraOutlinedIcon />}
        disabled={disabled}
      >
        <AdasTypography sx={{ fontSize: 12 }}>{labelText}</AdasTypography>
        <input ref={fileRef} type='file' hidden accept='image/*' onChange={() => handleUpload(fileRef)} />
      </AdasButton>
    </AdasBox>
  ) : (
    <AdasBox
      sx={{
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <AdasBox id='image' sx={{ position: 'relative' }}>
        {loading ? (
          <AdasLoader
            sx={{
              height: '200px',
              minWidth: { xs: '100%', sm: '300px' },
              borderRadius: '4px'
            }}
          />
        ) : (
          <AdasImage
            src={imageUrl}
            alt={title}
            sx={{
              height: '200px',
              objectFit: 'contain',
              borderRadius: '4px',
              width: '100%'
            }}
          />
        )}
        {allowDelete && !disabled && imageDataObj && <DeleteMe whenDelete={deleteWithConfirmation} />}
        <>
          <AdasButton
            component='label'
            sx={{
              color: 'white',
              position: 'absolute',
              bottom: '0px',
              left: '0px',
              right: '0px',
              fontSize: '13px',
              backgroundColor: theme.palette.background.shaded,
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '20px',
              borderRadius: '0px',
              borderBottomLeftRadius: '4px',
              borderBottomRightRadius: '4px'
            }}
            startIcon={<PhotoCameraOutlinedIcon />}
          >
            <AdasButton onClick={openModal} variant='button-small'>
              <AdasTypography variant='button-small' sx={{ flex: '1' }}>
                View Photo
              </AdasTypography>
            </AdasButton>
          </AdasButton>
          <PhotoViewerWithoutThumbnail
            parentId={parentId}
            fetchImage={fetchImage}
            isOpen={isModalOpen}
            closeModal={closeModal}
            imageUrl={imageUrl}
            imageDataObj={imageDataObj}
            imageLocation={imageLocation}
            onUpdate={onUpdate}
            allowDelete={allowDelete}
            disabled={disabled}
          />
        </>
      </AdasBox>
      {showConfirmModal && (
        <ConfirmModal
          cancelLabel={BUTTONS_DISPLAY_LABEL.CANCEL}
          confirmLabel={BUTTONS_DISPLAY_LABEL.DELETE}
          open={showConfirmModal}
          title={GENERIC_MESSAGES.DELETE_PHOTO}
          message={GENERIC_MESSAGES.CONFIRM_DELETE_PHOTO}
          handleClose={() => setShowConfirmModal(false)}
          handleContinue={deleteAttachment}
        />
      )}
    </AdasBox>
  )
}

PhotoInputWithoutThumbnail.propTypes = {
  labelText: PropTypes.string,
  imageLocation: PropTypes.string,
  onUpdate: PropTypes.func,
  allowDelete: PropTypes.bool,
  disabled: PropTypes.bool,
  img_rotation: PropTypes.number,
  title: PropTypes.string,
  fetchImage: PropTypes.func,
  imageDataObj: PropTypes.object
}
