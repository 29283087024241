import { AssignedUser } from '@components/assigned-user'
import { DropdownActionButtons } from '@components/buttons'
import { CarImageHolder } from '@components/car-image-holder'
import { ConfirmModal } from '@components/common'
import { CustomItem, ItemList } from '@components/custom-item'
import { EmailForm } from '@components/email'
import { IdDisplay } from '@components/id-display'
import { Note } from '@components/note'
import { ScoreDrawer } from '@components/score-drawer'
import {
  AdasBox,
  AdasButton,
  AdasContainer,
  AdasDivider,
  AdasPaper,
  AdasStack,
  AdasStep,
  AdasStepper,
  AdasTypography,
  AdasImage
} from '@components/wrapper-components'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import LibraryAddCheckOutlinedIcon from '@mui/icons-material/LibraryAddCheckOutlined'
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined'
import { CircularProgress, useTheme } from '@mui/material'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  getInvoiceByReferenceNumber,
  getInvoiceCertificateOfCalibration,
  getInvoiceEmails,
  getInvoicePdf,
  reOpenInvoices,
  revertToWorkOrder,
  updateNote
} from '../../../api/api'
import {
  BUTTONS_DISPLAY_LABEL,
  DATE_FORMATS,
  GENERIC_MESSAGES,
  PARAM_NAMES,
  getWorkOrderSummaryUrl,
  REDIRECT_URLS,
  getUrlByNameAndId
} from '../../../constants'
import { useLoadingStore, useUserStore } from '../../../store'
import { useScrollTop } from '../../../utils/useScrollTop'
import { currencyFormatter, formatDate } from '../../../utils/utils'
import { Logger } from '../../../logger'
import { StatusEnum } from '../../../enums/enums'

export const InvoiceSummaryPage = () => {
  useScrollTop()
  const { [PARAM_NAMES.REFERENCE_NUMBER]: referenceNumber } = useParams()
  const user = useUserStore((store) => store.currentUser)
  const [packages, setPackages] = useState([]) // Calibrations == Packages
  const [services, setServices] = useState([])
  const [customItemList, setCustomItemList] = useState([])
  const [isScorecardOpen, setIsScorecardOpen] = useState(false)
  const setLoading = useLoadingStore((store) => store.setLoading)
  const [isServiceOnly, setIsServiceOnly] = useState(false)

  const [wo, setWo] = useState({})
  const [invoiceData, setInvoiceData] = useState({})
  const [isScored, setIsScored] = useState(false)
  const isActive = false
  const [pdfLoader, setPdfLoader] = useState(false)
  const [isEmail, setIsEmail] = useState(false)
  const [emailContacts, setEmailContacts] = useState({})
  const [invoicePdf, setInvoicePdf] = useState()
  const [showModal, setShowModal] = useState(false)
  const [showReopenModal, setShowReopenModal] = useState(false)
  const [showNotifyError, setShowNotifyError] = useState(false)
  const [showWarningError, setShowWarningError] = useState(false)
  const [notes, setNotes] = useState({
    notes_external: wo.notes_external || '',
    notes_internal: wo.notes_internal || ''
  })

  const textDisplay = { textAlign: 'center' }
  const theme = useTheme()

  const history = useHistory()

  const redirectToForbiddenPage = () => {
    history.replace(REDIRECT_URLS.FORBIDDEN_PAGE)
  }

  const redirectPreCalibration = () => {
    history.push(getUrlByNameAndId(REDIRECT_URLS.INVOICE_PRE_CALIBRATION, referenceNumber))
  }

  const redirectCalibration = () => {
    history.push(getUrlByNameAndId(REDIRECT_URLS.INVOICE_CALIBRATION, referenceNumber))
  }
  const redirectPostCalibration = () => {
    history.push(getUrlByNameAndId(REDIRECT_URLS.INVOICE_POST_CALIBRATION, referenceNumber))
  }
  const redirectVehicleInfo = () => {
    history.push(getUrlByNameAndId(REDIRECT_URLS.INVOICE_VEHICLE_INFO, referenceNumber))
  }
  const redirectDocuments = () => {
    history.push(getUrlByNameAndId(REDIRECT_URLS.INVOICE_DOCUMENT, referenceNumber))
  }

  const redirectPhotos = () => {
    history.push(getUrlByNameAndId(REDIRECT_URLS.INVOICE_PHOTO, referenceNumber))
  }

  const packagesTotal = useMemo(() => {
    return packages.length > 0 ? packages.reduce((total, current) => total + current.price, 0) : 0
  }, [packages])

  const servicesTotal = useMemo(() => {
    return services.length > 0 ? services.reduce((total, current) => total + current.price, 0) : 0
  }, [services])

  const customItemTotal = useMemo(() => {
    return customItemList.length > 0
      ? customItemList.reduce((total, current) => {
          if (current.item_type === 'discount') {
            return total - current.price
          } else {
            return total + current.price
          }
        }, 0)
      : 0
  }, [customItemList])

  const totalCost = useMemo(() => packagesTotal + servicesTotal + customItemTotal, [packagesTotal, servicesTotal, customItemTotal])

  const revertToWO = useCallback(async () => {
    try {
      const revertToWOResponse = await revertToWorkOrder({
        invoiceId: invoiceData.id
      })

      if (revertToWOResponse.status === 200) {
        history.push(getWorkOrderSummaryUrl(invoiceData.reference_number), { dataFromInvoiceScreen: true })
      }
    } catch (error) {
      console.error('ERROR', error)
    }
  }, [invoiceData.id, history])

  const modalContinue = useCallback(() => {
    setShowModal(false)
    revertToWO()
  }, [revertToWO])

  const saveNote = useCallback(
    async (data) => {
      const response = await updateNote({ type: 'workorders', id: wo.id, data })
      if (response.status === 200 || response.status === 201) {
        setNotes(data.note)
      }
    },
    [wo.id]
  )

  const displayPDFContent = (pdfContent, fileName) => {
    const fileValue = new Blob([pdfContent], { type: 'application/pdf' }, fileName)
    fileValue.id = invoiceData.id
    const fileURL = URL.createObjectURL(fileValue)
    const pdfWindow = window.open()
    if (pdfWindow === null) {
      alert('Pop-up was blocked by your browser! Please allow pop-ups for caradasplus.com to view Invoice.')
    } else {
      pdfWindow.location.href = fileURL
    }
  }

  const viewPdfHandler = useCallback(async (invoice) => {
    try {
      setPdfLoader(true)
      const invoiceId = invoice.id
      const invoicePdfResponse = await getInvoicePdf({
        invoiceId: invoiceId
      })
      if (invoicePdfResponse.status === 200) {
        displayPDFContent(invoicePdfResponse.data, `invoice_${invoice.reference_number}.pdf`)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { invoice, path: 'InvoiceSummaryPage/viewPdf' } })
    } finally {
      setPdfLoader(false)
    }
  }, [])

  const viewCOCPdf = useCallback(async (invoice) => {
    try {
      setPdfLoader(true)
      const invoiceId = invoice.id
      const invoicePdfResponse = await getInvoiceCertificateOfCalibration({
        invoiceId: invoiceId
      })
      setPdfLoader(false)
      if (invoicePdfResponse.status === 200) {
        displayPDFContent(invoicePdfResponse.data, `certificate_${invoiceId}.pdf`)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { invoice, path: 'InvoiceSummaryPage/viewCOC' } })
    } finally {
      setPdfLoader(false)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      if (isScored) {
        try {
          const invoiceResponse = await getInvoiceByReferenceNumber({ referenceNumber })
          invoiceResponse.data.invoiceDto.safetySystemsItems = invoiceResponse.data.safetySystemsItems
          invoiceResponse.data.invoiceDto.calibrations = invoiceResponse.data.workOrderCalibrations
          invoiceResponse.data.invoiceDto.services = invoiceResponse.data.workOrderServices
          invoiceResponse.data.invoiceDto.customItems = invoiceResponse.data.customItems
          setIsServiceOnly(invoiceResponse.data.invoiceDto.type === 'SERVICE_ONLY')
          setInvoiceData(invoiceResponse.data.invoiceDto)
        } catch (error) {
          console.error(error)
        }
      }
    }

    fetchData()
  }, [referenceNumber, isScored])

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true)

        const getInvoiceData = async () => {
          const invoiceResponse = await getInvoiceByReferenceNumber({ referenceNumber })
          if (invoiceResponse && invoiceResponse.status === 200) {
            const data = invoiceResponse.data
            //Url Tampared i.e work order/quote record tried to open in invoice
            if (!data.invoiceDto) {
              redirectToForbiddenPage()
              return
            }
            setWo(data.workOrderDto)
            setNotes({
              notes_external: data.workOrderDto.notes_external || '',
              notes_internal: data.workOrderDto.notes_internal || ''
            })
            data.invoiceDto.safetySystemsItems = invoiceResponse.data.safetySystemsItems
            data.invoiceDto.calibrations = invoiceResponse.data.workOrderCalibrations
            data.invoiceDto.services = invoiceResponse.data.workOrderServices
            data.invoiceDto.customItems = invoiceResponse.data.customItems

            setIsServiceOnly(invoiceResponse.data.invoiceDto.type === 'SERVICE_ONLY')
            setInvoiceData(data.invoiceDto)

            if (data.workOrderCalibrations.length > 0) {
              const flatenedPackages = data.workOrderCalibrations.map((item) => ({
                ...item,
                calibration_id: item.calibration.id,
                name: item.calibration.name,
                price: item.calibration.price,
                description: item.calibration.description,
                calibration_center_id: item.calibration.calibration_center_id,
                itemNum: item.calibration.itemNum,
                category: item.calibration.category,
                is_defaulted: item.calibration.is_defaulted,
                is_active: item.calibration.is_active,
                calibration_created_at: item.calibration.created_at,
                calibration_updated_at: item.calibration.updated_at
              }))

              setPackages(flatenedPackages)
            }

            if (data.workOrderServices.length > 0) {
              const flatenedServices = data.workOrderServices.map((item) => ({
                ...item,
                service_id: item.service.id,
                name: item.service.name,
                price: item.service.price,
                description: item.service.description,
                calibration_center_id: item.service.calibration_center_id,
                itemNum: item.service.itemNum,
                category: item.service.category,
                is_defaulted: item.service.is_defaulted,
                is_active: item.service.is_active,
                calibration_created_at: item.service.created_at,
                calibration_updated_at: item.service.updated_at
              }))

              setServices(flatenedServices)
            }

            if (data.customItems.length > 0) {
              setCustomItemList(data.customItems)
            }
          } else {
            //Given referenceNumber doesnot exist or no permission granted
            redirectToForbiddenPage()
            return
          }
        }

        await getInvoiceData()
      } catch (error) {
        Logger.error({
          message: error,
          payload: {
            ccid: invoiceData.calibrationCenter?.id,
            referenceNumber
          }
        })
      } finally {
        setLoading(false)
      }
    }

    if (referenceNumber) fetchData()
  }, [referenceNumber])

  const emailInvoiceHandler = useCallback(async () => {
    const params = {
      invoiceId: invoiceData.id
    }

    setLoading(true)
    try {
      const emailContactResponse = await getInvoiceEmails(params)
      if (emailContactResponse.status === 200) {
        setIsEmail(true)
        setEmailContacts(emailContactResponse.data)
      }
      const invoicePdfResponse = await getInvoicePdf({
        invoiceId: invoiceData.id
      })
      if (invoicePdfResponse.status === 200) {
        const fileValue = new File([invoicePdfResponse.data], `invoice_${invoiceData.reference_number}.pdf`)
        fileValue.id = invoiceData.id
        setInvoicePdf(fileValue)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { InvoiceId: invoiceData.id, path: 'InvoiceSummaryPage/emailInvoiceHandler' } })
    } finally {
      setLoading(false)
    }
  }, [invoiceData])

  const WODocumentationItem = (props) => {
    const { topic, onClickReview } = props
    return (
      <AdasBox sx={{ margin: '20px' }}>
        <span style={{ display: 'inline-flex' }}>
          <CheckCircleOutlinedIcon sx={{ marginRight: '10px' }} />
          <b>{topic}</b>
        </span>
        <span style={{ float: 'right' }}>
          <AdasButton onClick={onClickReview}> Review</AdasButton>
        </span>
      </AdasBox>
    )
  }

  const ErrorMessages = (messagesMap) => {
    return (
      <AdasBox sx={{ margin: 0 }}>
        {messagesMap.map((msg, index) => (
          <AdasTypography
            key={`msg_${index}`}
            variant='body1'
            sx={{
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '20px',
              letterSpacing: '0.15000000596046448px',
              textAlign: 'left',
              margin: '20px 0',
              color: theme.palette.error.main
            }}
          >
            {msg}
          </AdasTypography>
        ))}
      </AdasBox>
    )
  }

  const handleReopen = useCallback(async () => {
    try {
      const reopenInvoiceResponse = await reOpenInvoices({
        invoiceIds: [invoiceData.id]
      })

      if (reopenInvoiceResponse.status === 200) {
        history.push('/invoices')
      }
    } catch (error) {
      console.error('ERROR', error)
    }
  }, [invoiceData.id, history])

  const ValidateAndReopenModal = () => {
    if ((user.is_owner || user.is_manager) && invoiceData && invoiceData.closedAt) {
      const currentDate = new Date()
      const formattedDate = new Date(formatDate(invoiceData.closedAt, DATE_FORMATS.ISO_DATE_STRING))
      if (formattedDate.getMonth() !== currentDate.getMonth()) {
        setShowNotifyError(true)
      } else {
        setShowWarningError(true)
      }
    }
    setShowReopenModal(true)
  }

  const reOpenModalContinue = useCallback(() => {
    closeReopenModal()
    handleReopen()
  }, [handleReopen])

  const closeReopenModal = () => {
    setShowNotifyError(false)
    setShowWarningError(false)
    setShowReopenModal(false)
  }

  const options = [
    { label: 'View Invoice', action: () => viewPdfHandler(invoiceData), visible: true },
    {
      label: 'View Certificate',
      action: () => viewCOCPdf(invoiceData),
      visible: true
    },
    {
      label: 'Edit',
      action: revertToWO,
      visible: StatusEnum[invoiceData.status] === StatusEnum.OPEN && (user.is_admin || user.is_owner || user.is_manager)
    },
    {
      label: 'Scorecard',
      action: () => setIsScorecardOpen(true),
      visible: (user.is_admin && StatusEnum[invoiceData.status] === StatusEnum.CLOSED) || invoiceData.quality_review?.is_completed
    },
    {
      label: 'Reopen Invoice',
      action: () => ValidateAndReopenModal(true),
      visible: (user.is_admin || user.is_owner || user.is_manager) && StatusEnum[invoiceData.status] === StatusEnum.CLOSED
    }
  ]

  const vehicleData = useMemo(() => wo?.vehicle || {}, [wo])

  return (
    <AdasContainer maxWidth='lg'>
      {invoiceData.id && (
        <AdasPaper elevation={0} sx={{ padding: '1.25rem', marginTop: '1.25rem', marginBottom: '60px' }}>
          <EmailForm
            invoicePdf={invoicePdf}
            isOpen={isEmail}
            contacts={emailContacts}
            emailData={invoiceData}
            onClose={() => setIsEmail(false)}
          />
          {((user.is_admin && StatusEnum[invoiceData.status] === StatusEnum.CLOSED) || invoiceData?.quality_review?.is_completed) && (
            <ScoreDrawer
              referenceNumber={invoiceData.reference_number}
              invoiceId={invoiceData.id}
              roNumber={invoiceData.repair_order_num}
              isOpen={isScorecardOpen}
              setIsScored={setIsScored}
              onClose={() => {
                setIsScorecardOpen(false)
              }}
            />
          )}

          <AdasBox sx={{ marginBottom: '40px' }}>
            <AdasBox sx={{ overflow: 'hidden' }}>
              <span
                style={{
                  float: 'left',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <IdDisplay itemId={invoiceData.reference_number} roNumber={invoiceData.repair_order_num} itemType={'Invoice'} />
              </span>
              <span style={{ float: 'right', display: 'flex', alignItems: 'center' }}>
                <AssignedUser type='invoices' workOrderId={invoiceData.id} initialAssignee={invoiceData.assigned_to} closedStatus={true} />
                <span style={{ marginLeft: '20px' }}>
                  <AdasTypography>Created: {new Date(invoiceData.created_at).toLocaleDateString()}</AdasTypography>
                </span>
              </span>
            </AdasBox>
            <AdasBox
              style={{
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '2px'
              }}
            >
              <span style={{ float: 'left' }}>
                {invoiceData.quality_review?.is_completed && (
                  <AdasBox component='span' sx={{ display: 'flex', alignItems: 'center', pl: '.2rem' }}>
                    <DoneAllIcon sx={{ marginRight: '.2rem', fontSize: '20px' }} />
                    <AdasTypography sx={{ fontSize: '14px' }}>Scorecard</AdasTypography>
                  </AdasBox>
                )}
              </span>
              <span style={{ float: 'right', padding: '6px', paddingRight: 0 }}>
                <DropdownActionButtons options={options} />
              </span>
            </AdasBox>
            <ConfirmModal
              open={showReopenModal}
              handleClose={closeReopenModal}
              handleContinue={reOpenModalContinue}
              title={showNotifyError ? GENERIC_MESSAGES.UNABLE_TO_REOPEN_INVOICE_TITLE : GENERIC_MESSAGES.REOPEN_INVOICE_TITLE}
              warningMessage={showWarningError && GENERIC_MESSAGES.INVOICES_CAN_REOPEN_WITH_IN_MONTH}
              message={
                showNotifyError
                  ? ErrorMessages([
                      GENERIC_MESSAGES.INVOICES_CAN_REOPEN_WITH_IN_MONTH,
                      GENERIC_MESSAGES.INVOICES_CAN_REOPEN_ASSISTANCE_MESSAGE
                    ])
                  : GENERIC_MESSAGES.CONFIRM_REOPEN_INVOICE
              }
              confirmLabel={BUTTONS_DISPLAY_LABEL.REOPEN_INVOICE_LABEL}
              typeCheck={GENERIC_MESSAGES.REOPEN_CODE}
              notifyError={showNotifyError}
            />
            {vehicleData?.year && (
              <AdasBox id='carInfo'>
                <AdasBox id='image' sx={{ marginTop: vehicleData.image_url ? '0px' : '40px' }}>
                  {vehicleData.image_url ? <AdasImage src={vehicleData.image_url} alt='vehicle' /> : <CarImageHolder showText={false} />}
                  <AdasTypography sx={{ ...textDisplay, fontSize: '24px' }}>
                    <b>{`${vehicleData.year} ${vehicleData.make} ${vehicleData.model} ${vehicleData.trim}`}</b>
                  </AdasTypography>
                </AdasBox>
                <AdasTypography sx={{ ...textDisplay, fontSize: '20px', fontWeight: '500' }}>
                  <b>Total Cost: {currencyFormatter(totalCost)}</b>
                </AdasTypography>
              </AdasBox>
            )}
            <AdasBox id='clientInfo' sx={{ ...textDisplay }}>
              <AdasTypography sx={{ ...textDisplay }} color={theme.typography.secondaryColor}>
                {invoiceData.client?.name}
              </AdasTypography>
            </AdasBox>
          </AdasBox>
          <AdasBox>
            <AdasStepper orientation='vertical' connector={null} activeStep={-1}>
              <AdasStep key='packages'>
                <AdasPaper variant='outlined' sx={{ background: theme.palette.background.default }}>
                  {!isServiceOnly && (
                    <>
                      <AdasBox sx={{ margin: '20px' }}>
                        <span>
                          <b>Calibrations</b>
                        </span>
                        <span style={{ float: 'right' }}>
                          <b>{currencyFormatter(packagesTotal)}</b>
                        </span>
                        <ItemList itemList={packages} name='Packages' />
                        {(user.is_admin || user.is_owner || user.is_manager) && (
                          <AdasBox sx={{ textAlign: 'end' }}>
                            <AdasButton disabled={!isActive} startIcon={<LibraryAddCheckOutlinedIcon />}>
                              Manage Calibrations
                            </AdasButton>
                          </AdasBox>
                        )}
                      </AdasBox>
                      <AdasDivider orientation='horizontal' flexItem sx={{ margin: '20px' }} />
                    </>
                  )}
                  <AdasBox sx={{ margin: '20px' }}>
                    <span>
                      <b>Services</b>
                    </span>
                    <span style={{ float: 'right' }}>
                      <b>{currencyFormatter(servicesTotal)}</b>
                    </span>
                    <ItemList itemList={services} name='Services' />
                    {(user.is_admin || user.is_owner || user.is_manager) && (
                      <AdasBox sx={{ textAlign: 'end' }}>
                        <AdasButton disabled={!isActive} startIcon={<LibraryAddCheckOutlinedIcon />}>
                          Manage Services
                        </AdasButton>
                      </AdasBox>
                    )}
                  </AdasBox>
                  <AdasDivider orientation='horizontal' flexItem sx={{ margin: '20px' }} />
                  {user.is_admin || user.is_owner || user.is_manager ? (
                    <CustomItem
                      customItemTotal={customItemTotal}
                      existingCustomItemList={customItemList}
                      workOrderData={invoiceData}
                      onSaveCustomItem={(props) => setCustomItemList(props)}
                      disabled={!isActive}
                    />
                  ) : (
                    <AdasBox sx={{ margin: '20px' }}>
                      <span>
                        <b>Custom Charges & Discount</b>
                      </span>
                      <span style={{ float: 'right' }}>
                        <b>{currencyFormatter(customItemTotal)}</b>
                      </span>
                      <ItemList itemList={customItemList} name='additional items' isCustomItem={false} />
                    </AdasBox>
                  )}
                </AdasPaper>
              </AdasStep>
            </AdasStepper>
          </AdasBox>
          <Note
            existingExternalNote={notes.notes_external}
            existingInternalNote={notes.notes_internal}
            submit={(props) => saveNote(props)}
          />
          {!isServiceOnly && (
            <AdasBox>
              <h3>Work Order Documentation</h3>
              <AdasPaper variant='outlined' sx={{ background: theme.palette.background.default }}>
                <WODocumentationItem topic={'Pre Calibration Set Up Complete'} onClickReview={redirectPreCalibration} />
                <WODocumentationItem topic={'Calibration Documentation Complete'} onClickReview={redirectCalibration} />
                <WODocumentationItem topic={'Post Calibration Complete'} onClickReview={redirectPostCalibration} />
              </AdasPaper>
            </AdasBox>
          )}
          <AdasBox sx={{ margin: '10px 0px' }}>
            <AdasStack
              direction='row'
              spacing={2}
              divider={<AdasDivider orientation='vertical' flexItem sx={{ backgroundColor: '#0071E3' }} />}
            >
              <AdasButton onClick={redirectVehicleInfo} startIcon={<InfoOutlinedIcon />} sx={{ width: '30%' }}>
                Vehicle Info
              </AdasButton>
              <AdasButton startIcon={<PhotoCameraOutlinedIcon />} sx={{ width: '30%' }} onClick={redirectPhotos}>
                Photos
              </AdasButton>
              <AdasButton startIcon={<InsertDriveFileOutlinedIcon />} sx={{ width: '30%' }} onClick={redirectDocuments}>
                Documents
              </AdasButton>
            </AdasStack>
            <AdasDivider orientation='horizontal' flexItem sx={{ marginTop: '10px' }} />
          </AdasBox>
          <AdasBox
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: '20px',
              flexWrap: 'wrap-reverse'
            }}
          >
            <AdasButton
              sx={{ fontWeight: '600' }}
              onClick={() =>
                history.push({
                  pathname: '/invoices',
                  search: `?status=${invoiceData.status || 'OPEN'}`
                })
              }
              startIcon={<KeyboardDoubleArrowLeftIcon />}
            >
              Invoices
            </AdasButton>
            <AdasBox
              sx={{
                display: 'flex',
                justifyContent: 'space-between'
              }}
            >
              <AdasButton
                sx={{ marginRight: '10px' }}
                variant='outlined'
                isDebounceEnabled={true}
                startIcon={pdfLoader ? <CircularProgress size={20} /> : <DownloadOutlinedIcon />}
                onClick={() => viewPdfHandler(invoiceData)}
              >
                View PDF
              </AdasButton>
              <AdasButton
                disabled={false}
                isDebounceEnabled={true}
                variant='contained'
                startIcon={<EmailOutlinedIcon />}
                onClick={emailInvoiceHandler}
              >
                Email Invoice Summary
              </AdasButton>
            </AdasBox>
            <ConfirmModal
              open={showModal}
              handleClose={() => setShowModal(false)}
              handleContinue={modalContinue}
              title={GENERIC_MESSAGES.REVERT_TO_WO_TITLE}
              message={GENERIC_MESSAGES.REVERT_TO_WO}
              confirmLabel={BUTTONS_DISPLAY_LABEL.CONFIRM}
              adminCodeCheck={false}
            />
          </AdasBox>
        </AdasPaper>
      )}
    </AdasContainer>
  )
}
