import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  AdasBackdrop,
  AdasBox,
  AdasButton,
  AdasButtonGroup,
  AdasCircularProgress,
  AdasFormControl,
  AdasPaper,
  AdasSearchMenu,
  AdasTabs,
  AdasTextField,
  AdasTypography
} from '@components/wrapper-components'
import { createCalibrationCenter, getOwnersList } from '../../api/api'

export const OwnerForm = (props) => {
  const { cancel, ccInfo } = props
  const history = useHistory()

  const [existingOwner, setExistingOwner] = useState({})
  const [ownerList, setOwnerList] = useState([])
  const [ownerType, setOwnerType] = useState('new')
  const [newOwner, setNewOwner] = useState({
    name: '',
    email: ''
  })
  const [role, setRole] = useState('owner')
  const [isLoading, setIsLoading] = useState(false)
  const tabs = [
    { label: 'New Owner', value: 'new' },
    { label: 'Existing Owner', value: 'existing' }
  ]

  useEffect(() => {
    const fetchOwnersList = async () => {
      const ownersListResponse = await getOwnersList({})
      if (ownersListResponse.status === 200) {
        setOwnerList(ownersListResponse.data.sort(sortByName))
      }
    }
    fetchOwnersList()
  }, [])

  const handleInputChange = (e) => {
    const value = e.target.value
    setNewOwner((prev) => {
      const contact = {
        ...prev,
        [e.target.name]: value
      }
      return contact
    })
  }

  const isDisabled = () => {
    if (ownerType === 'new') {
      return !(newOwner.name && newOwner.email)
    } else {
      return !existingOwner
    }
  }

  const onTabChange = (e, value) => {
    setOwnerType(value)
  }

  const sortByName = (a, b) => {
    const name1 = a.name.toUpperCase()
    const name2 = b.name.toUpperCase()

    let comparison = 0

    if (name1 > name2) {
      comparison = 1
    } else if (name1 < name2) {
      comparison = -1
    }
    return comparison
  }

  const submitCalibrationCenter = () => {
    setIsLoading(true)
    const data = {
      owner_name: ownerType === 'new' ? newOwner.name : existingOwner.name,
      owner_email: ownerType === 'new' ? newOwner.email : existingOwner.email,
      owner_id: ownerType === 'new' ? null : existingOwner.id,
      ...ccInfo
    }
    const createCCResponse = createCalibrationCenter({
      data
    })
    createCCResponse
      .then((response) => {
        if (response.status === 200) {
          setIsLoading(false)
          history.push('/manage-location')
        } else {
          setIsLoading(false)
        }
      })
      .finally(() => setIsLoading(false))
  }

  const ExistingOnwerForm = (props) => {
    const { ownerList } = props

    const generateOptions = () => {
      const options = ownerList.map((user) => ({
        value: user.id,
        name: user.name
      }))
      return options.filter(Boolean)
    }
    const handleSelect = (props) => {
      const selectedCustomer = ownerList?.find((user) => user.id === props.target.value)
      setExistingOwner(selectedCustomer)
    }

    return (
      <AdasFormControl sx={{ width: '100%' }}>
        <AdasSearchMenu
          id='existing-owner'
          select
          label='Choose an Owner'
          name='existingOwner'
          value={existingOwner.id}
          onChange={handleSelect}
          sx={{ backgroundColor: 'white' }}
          options={generateOptions()}
        />
      </AdasFormControl>
    )
  }

  return [
    <AdasPaper key='ownerForm' variant='outlined' sx={{ background: '#FDFBFF', padding: '16px', margin: '20px 0px' }}>
      <AdasBackdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading} onClick={() => setIsLoading(false)}>
        <AdasCircularProgress color='inherit' />
      </AdasBackdrop>
      <AdasTypography
        variant='overline'
        sx={{
          fontSize: '12px',
          display: 'inline-block',
          marginBottom: '20px'
        }}
      >
        Set An Owner For The New Location
      </AdasTypography>
      <AdasTabs
        value={ownerType}
        onChange={onTabChange}
        sx={{
          marginBottom: '20px'
        }}
        tabs={tabs}
      />
      {ownerType === 'new' ? (
        <AdasBox>
          <AdasTextField
            onChange={handleInputChange}
            name='name'
            sx={{ marginBottom: '20px' }}
            id='name'
            label='Name'
            variant='outlined'
            value={newOwner.name}
            fullWidth={true}
            InputLabelProps={{
              shrink: true
            }}
          />
          <AdasTextField
            onChange={handleInputChange}
            name='email'
            sx={{ marginBottom: '20px' }}
            id='name'
            label='Email'
            variant='outlined'
            value={newOwner.email}
            fullWidth={true}
            InputLabelProps={{
              shrink: true
            }}
            helperText={'The use will use this email to sign in. Email invite will be sent upon Complete Setup.'}
          />
          <AdasTypography variant='overline' sx={{ fontWeight: '500', display: 'block' }}>
            ROLE
          </AdasTypography>
          <AdasButtonGroup variant='contained'>
            <AdasButton onClick={() => setRole('owner')} variant={role === 'owner' ? 'contained' : 'outlined'}>
              Owner
            </AdasButton>
          </AdasButtonGroup>
        </AdasBox>
      ) : (
        <ExistingOnwerForm ownerList={ownerList} />
      )}
    </AdasPaper>,
    <AdasBox key='buttonGroup' sx={{ margin: '20px 0px' }}>
      <AdasBox sx={{ float: 'right' }}>
        <AdasButton variant='outlined' aria-label='cancel' color='primary' onClick={cancel} sx={{ marginRight: '10px' }}>
          Cancel
        </AdasButton>
        <AdasButton disabled={isDisabled()} variant='contained' aria-label='save' color='primary' onClick={submitCalibrationCenter}>
          Complete Setup
        </AdasButton>
      </AdasBox>
    </AdasBox>
  ]
}

OwnerForm.propTypes = {
  cancel: PropTypes.func,
  ccInfo: PropTypes.object
}
