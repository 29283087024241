import PropTypes from 'prop-types'
import { useTheme } from '@mui/material'
import { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useAuthStore } from '@caradasstore/AuthStore'
import { useCenterStore } from '@caradasstore/CenterStore'
import { useUserStore } from '@caradasstore/UserStore'
import { CaradasSnackbar } from '@components/snackbar'
import { CaradasAvatar, UserProfile } from '@components/user'
import {
  AdasBox,
  AdasContainer,
  AdasDrawer,
  AdasListItemIcon,
  AdasListItemText,
  AdasMenuList,
  AdasPaper,
  AdasPopper,
  AdasTypography
} from '@components/wrapper-components'
import { purge } from '../../utils/persist'

export const CaradasAppBar = ({ title, sx }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const authCtx = useAuthStore()
  const [isProfileOpen, setIsProfileOpen] = useState(false)
  const theme = useTheme()
  const history = useHistory()
  const currentUser = useUserStore((store) => store.currentUser)
  const setCenterDetail = useCenterStore((store) => store.setCenterDetail)
  const setCcId = useCenterStore((store) => store.setCcId)

  const handleLogOut = useCallback(() => {
    authCtx.logout()
    setCenterDetail({})
    setCcId({})
    purge()
    history.push('/authenticate')
  }, [authCtx, history, setCenterDetail])

  const handleOpen = useCallback((evt) => {
    setAnchorEl(evt.currentTarget)
  }, [])

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleRefresh = () => {
    window.location.reload(true)
  }
  const handleIsProfileOpen = () => {
    setIsProfileOpen(true)
  }

  const open = Boolean(anchorEl)

  return (
    <AdasContainer maxWidth='lg'>
      <AdasBox
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          mb: '.5rem',

          maxWidth: '1200px',
          ...sx
        }}
      >
        <AdasTypography
          variant='h5'
          component='span'
          sx={{
            mt: { xs: '0.5rem', md: '1.25rem' },
            fontSize: '1.25rem'
          }}
        >
          {title}
        </AdasTypography>
        <AdasBox
          sx={{
            backgroundColor: 'white',
            borderRadius: '4px',
            color: theme.palette.primary.main,
            marginTop: '.75rem',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '2px 2px',
            marginLeft: { xs: '0', md: '0' },
            order: { xs: 3, md: 0 }
          }}
          onClick={handleOpen}
        >
          <AdasListItemIcon sx={{ minWidth: '0', margin: '0px 8px' }}>
            <CaradasAvatar user={currentUser} size='sm' />
          </AdasListItemIcon>
          <AdasListItemText sx={{ marginRight: '10px' }}>{currentUser?.name}</AdasListItemText>
        </AdasBox>
        <CaradasSnackbar
          sx={{
            marginLeft: { xs: '0', md: '0' }
          }}
        />
        <AdasDrawer anchor='right' open={isProfileOpen}>
          <UserProfile user={currentUser} closeDrawer={() => setIsProfileOpen(false)} />
        </AdasDrawer>

        <AdasPopper open={open} anchorEl={anchorEl} placement='bottom-start'>
          <AdasPaper>
            <AdasMenuList
              id='user-popover-menu'
              onClickAway={handleClose}
              variant='withClickAway'
              items={[
                { label: 'User Profile', onClick: handleIsProfileOpen },
                { label: 'Refresh', onClick: handleRefresh },
                { label: 'Logout', onClick: handleLogOut }
              ]}
            />
          </AdasPaper>
        </AdasPopper>
      </AdasBox>
    </AdasContainer>
  )
}

CaradasAppBar.propTypes = {
  title: PropTypes.string,
  sx: PropTypes.object
}
