import PropTypes from 'prop-types'

import { AdasPaper } from '@components/wrapper-components'

export const StickyFooter = ({ children }) => {
  return (
    <AdasPaper sx={{ position: 'sticky', bottom: 0, width: 'inherit', zIndex: 100, padding: '20px 0px' }} elevation={0}>
      {children}
    </AdasPaper>
  )
}

StickyFooter.propTypes = {
  children: PropTypes.node.isRequired
}
