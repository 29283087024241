import axios from 'axios'
import base64 from 'base-64'
import { useState } from 'react'

import { useAuthStore } from '@caradasstore/AuthStore'
import { BACKEND_URL } from '../config'
import { Logger } from '../logger'

export const baseUrl = BACKEND_URL

export const axiosPublic = axios.create({
  baseURL: baseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
})

export const axiosSecure = axios.create({
  baseURL: baseUrl
})

export const useAxiosSecure = () => {
  axiosSecure.interceptors.request.use(
    async (config) => {
      const authCtx = useAuthStore.getState()
      const token = authCtx.token
      if (token) {
        config.headers = {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Bypass-Tunnel-Reminder': '*',
          'Access-Control-Allow-Origin': '*',
          ...config.headers
        }
      }

      return config
    },
    (error) => Promise.reject(error)
  )

  return axiosSecure
}
// if (axiosSecure) {
//   useAxiosSecure();
//  }

export const requestAuth = async (params) => {
  try {
    const auth = `${params.email}:${params.password}`
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Bypass-Tunnel-Reminder': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: `Basic ${base64.encode(auth)}`
      }
    }

    return await axiosPublic.post(`/authenticate`, {}, config)
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'requestAuth', params } })
    throw error
  }
}

export const refreshAuth = async () => {
  try {
    const refreshToken = JSON.parse(localStorage.getItem('auth-state')).state.refreshToken
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Bypass-Tunnel-Reminder': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': '*',
        Authorization: `Bearer ${refreshToken}`
      }
    }

    return await axiosPublic.post(`/refresh-token`, {}, config)
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'refreshAuth' } })
    throw error
  }
}

export const requestPasswordReset = async (params) => {
  try {
    const data = {
      email: params.email,
      password: params.password,
      returnSecureToken: true
    }

    return await axiosSecure.post(`/v1/users/password/reset/request?email=${params.email}`, data)
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'requestPasswordReset', params } })
    throw error
  }
}

export const passwordResetAuth = async (params) => {
  try {
    const { token, password } = params
    const data = {
      newPassword: password,
      token: token
    }

    return await axiosSecure.post(`/v1/users/password/reset`, data, {
      headers: { 'content-type': 'application/x-www-form-urlencoded' }
    })
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'passwordResetAuth', params } })
    throw error
  }
}

export const passwordResetTokenValidation = async (params) => {
  try {
    const { token } = params
    return await axiosSecure.get(`/v1/users/password/reset/${token}`)
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'passwordResetTokenValidation', params } })
    throw error
  }
}

export const requestVehicleInfo = async (params) => {
  try {
    const { vin } = params
    return await axiosSecure.get(`/v1/vins/${vin}`)
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'requestVehicleInfo', params } })
    throw error
  }
}

export const requestVinDataForQuotesWOInvoicesByVin = async (params) => {
  try {
    const { vin, ccid } = params
    const response = await axiosSecure.post(`/v1/workorders/vin-search?vin=${vin}&ccid=${ccid}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'requestVinDataForQuotesWOInvoicesByVin', params } })
    throw error
  }
}

export const requestSafetySystems = async () => {
  try {
    return await axiosSecure.get(`/v1/safetysystems`)
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'requestSafetySystems' } })
    throw error
  }
}

export const requestCreateWO = async (params) => {
  try {
    return await axiosSecure.post(`/v1/workorders`, params.data)
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'requestCreateWO', params } })
    throw error
  }
}

export const useSubmitFileAttachment = () => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [progress, setProgress] = useState(0)
  const [attachmentId, setAttachmentId] = useState()
  const [startTime, setStartTime] = useState(null)
  const [timeLeft, setTimeLeft] = useState(null)

  const uploadData = async (params) => {
    try {
      setStartTime(Date.now()) // Set the start time
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
          setProgress(progress)
          // Calculate time left
          const elapsedTime = Date.now() - startTime
          const estimatedTotalTime = elapsedTime / (progress / 100)
          const remainingTime = estimatedTotalTime - elapsedTime
          setTimeLeft(Math.round(Math.max(0, remainingTime))) // Prevent negative time left
        }
      }

      const response = await axiosSecure.post(
        `/v1/${params.type}/${params.workorderId}/attachment?type=${params.attachmentType}`,
        params.data,
        config
      )

      if (response.status === 200 || response.status === 201) {
        setAttachmentId(response.data.id)
        setIsSuccess(true)
      } else {
        Logger.error({ message: 'Upload failed', payload: { response, file: 'api', method: 'useSubmitFileAttachment/uploadData', params } })
        if (params.onFailed) {
          params.onFailed()
        }
      }
    } catch (error) {
      if (params.onFailed) {
        params.onFailed()
      }
      Logger.error({ message: error, payload: { file: 'api', method: 'useSubmitFileAttachment/uploadData', params } })
      throw error
    }
  }

  const resetIsSuccess = () => {
    setIsSuccess(false)
  }

  return {
    progress,
    uploadData,
    isSuccess,
    attachmentId,
    resetIsSuccess,
    timeLeft
  }
}

export const uploadImageAttachment = async (params) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    return await axiosSecure.post(`/v1/workorders/${params.workorderId}/attachment?type=${params.type}`, params.data, config)
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'uploadImageAttachment', params } })
    throw error
  }
}

export const updateAttachmentRotation = async (params) => {
  try {
    const response = await axiosSecure.post(`/v1/attachments/${params.attachmentId}/rotation?rotation=${params.rotation}`, {})
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'updateAttachmentRotation', params } })
    throw error
  }
}

export const submitPreCalibration = async (params) => {
  try {
    const response = await axiosSecure.post(`/v1/workorders/${params.workorderId}/precalibrations`, params.data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'submitPreCalibration', params } })
    throw error
  }
}

export const submitPostCalibration = async (params) => {
  try {
    const response = await axiosSecure.post(`/v1/workorders/${params.workorderId}/postcalibrations`, params.data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'submitPostCalibration', params } })
    throw error
  }
}

export const getAttachmentProperties = async (params) => {
  try {
    const response = await axiosSecure.get(`/v1/attachments/images/${params.attachmentId}/properties`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getAttachmentProperties', params } })
    throw error
  }
}

export const getWorkOrder = async (params) => {
  try {
    const response = await axiosSecure.get(`/v1/workorders/${params.workorderId}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getWorkOrder', params } })
    throw error
  }
}

export const getQWIByWorkOrderId = async (workOrderId) => {
  try {
    const response = await axiosSecure.get(`/v1/workorders/${workOrderId}/qwi`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getQWIByWorkOrderId', workOrderId } })
    throw error
  }
}

export const getQWIByQuoteId = async (quoteId) => {
  try {
    const response = await axiosSecure.get(`/v1/quotes/${quoteId}/qwi`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getQWIByQuoteId', quoteId } })
    throw error
  }
}

export const getQWIByInvoiceId = async (invoiceId) => {
  try {
    const response = await axiosSecure.get(`/v1/invoices/${invoiceId}/qwi`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getQWIByInvoiceId', invoiceId } })
    throw error
  }
}

export const getWorkOrderByReferenceNumber = async ({ referenceNumber }) => {
  try {
    const response = await axiosSecure.get(`/v1/workorders/by-reference/${referenceNumber}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getWorkOrder', referenceNumber } })
    throw error
  }
}

export const updateWorkOrder = async (params) => {
  try {
    const response = await axiosSecure.post(`/v1/workorders/${params.data.workorder_id}`, params.data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'updateWorkOrder', params } })
    throw error
  }
}

export const getPreCalibration = async (params) => {
  try {
    const response = await axiosSecure.get(`/v1/workorders/${params.workorderId}/precalibrations`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getPreCalibration', params } })
    throw error
  }
}

export const getPostCalibration = async (params) => {
  try {
    const response = await axiosSecure.get(`/v1/workorders/${params.workorderId}/postcalibrations`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getPostCalibration', params } })
    throw error
  }
}

export const getFile = async (params) => {
  const config = {
    responseType: 'blob'
  }

  try {
    const response = await axiosSecure.get(`/v1/attachments/${params.attachmentId}`, config)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getFile', params } })
    throw error
  }
}

export const getImageThumbnail = async (params) => {
  const config = {
    responseType: 'blob'
  }
  try {
    const response = await axiosSecure.get(`/v1/attachments/images/${params.attachmentId}/thumbnail`, config)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getImageThumbnail', params } })
    throw error
  }
}

export const getDocuments = async ({ type, id }) => {
  try {
    const response = await axiosSecure.get(`/v1/documents/${type}/${id}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getDocuments', type, id } })
    throw error
  }
}

export const getPhotosSummary = async (params) => {
  try {
    const response = await axiosSecure.get(`/v1/photos/workorders/${params.workorderId}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getPhotosSummary', params } })
    throw error
  }
}

export const removeAttachment = async (params) => {
  try {
    const response = await axiosSecure.delete(`/v1/attachments/${params.attachmentId}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'removeAttachment', params } })
    throw error
  }
}

export const getWorkOrderList = async (params) => {
  try {
    const { pageNo = 0, pageSize = 25, ccid, search, assignedTo = '' } = params
    const response = await axiosSecure.get(
      `/v1/workorders?pageNo=${pageNo}&pageSize=${pageSize}&ccid=${ccid}&search=${search}&assignedTo=${assignedTo}`
    )
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getWorkOrderList', params } })
    throw error
  }
}

export const getCalibrationList = async (params) => {
  try {
    const response = await axiosSecure.get(
      `/v1/calibrations?ccid=${params.ccid}&effectiveDate=${params.effectiveDate ? encodeURIComponent(params.effectiveDate) : ''}`
    )
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getCalibrationList', params } })
    throw error
  }
}

export const updateWorkOrderCalibrations = async ({ type, id, calibrations }) => {
  try {
    let calibrationParams = ''
    const calibrationList = calibrations
    calibrationList.forEach((item, index) => {
      if (index === 0) {
        calibrationParams += `calibrations=${item.id}`
      } else {
        calibrationParams += `&calibrations=${item.id}`
      }
    })
    const response = await axiosSecure.post(`/v1/${type}/${id}/calibrations?${calibrationParams}`, {})
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'updateWorkOrderCalibrations', type, id, calibrations } })
    throw error
  }
}

export const getServiceList = async (params) => {
  try {
    const response = await axiosSecure.get(
      `/v1/services?ccid=${params.ccid}&effectiveDate=${params.effectiveDate ? encodeURIComponent(params.effectiveDate) : ''}`
    )
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getServiceList', params } })
    throw error
  }
}

export const updateWorkOrderServices = async ({ type, id, services }) => {
  try {
    let serviceParams = ''
    const serviceList = services
    serviceList.forEach((item, index) => {
      if (index === 0) {
        serviceParams += `services=${item.id}`
      } else {
        serviceParams += `&services=${item.id}`
      }
    })
    const response = await axiosSecure.post(`/v1/${type}/${id}/services?${serviceParams}`, {})
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'updateWorkOrderServices', type, id, services } })
    throw error
  }
}

export const deleteWorkOrder = async (params) => {
  try {
    const response = await axiosSecure.delete(`/v1/workorders/${params.workOrderId}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'deleteWorkOrder', params } })
    throw error
  }
}

export const getUserList = async (params) => {
  try {
    const isActiveOnly = params.isActiveOnly === false ? false : true
    const response = await axiosSecure.get(`/v1/users?ccid=${params.ccid}&isActiveOnly=${isActiveOnly}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getUserList', params } })
    throw error
  }
}

export const getAllUserByCcid = async ({ ccid, isActive, pageNo, pageSize, sort, sortBy, search }) => {
  try {
    const response = await axiosSecure.get(
      `/v1/users/by-ccid?ccid=${ccid}&isActive=${isActive}&pageNo=${pageNo}&pageSize=${pageSize}&sort=${sort}&sortBy=${sortBy}&search=${search}`
    )
    return response
  } catch (error) {
    Logger.error({
      message: error,
      payload: { file: 'api', method: 'getUserList', params: { ccid, isActive, pageNo, pageSize, sort, sortBy, search } }
    })
    throw error
  }
}

export const getUserById = async (params) => {
  try {
    const response = await axiosSecure.get(`/v1/users/${params.userId}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'users', params } })
    throw error
  }
}

export const updateUser = async (params) => {
  try {
    const { data } = params
    const response = await axiosSecure.put(`/v1/users`, data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'updateUser', params } })
    throw error
  }
}

export const updateUserPassword = async (params) => {
  try {
    const { data } = params
    const response = await axiosSecure.post(`/v1/users/password`, data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'updateUserPassword', params } })
    throw error
  }
}

export const updateAssignee = async ({ type, id, userId }) => {
  try {
    const response = await axiosSecure.put(`/v1/${type}/${id}/assigned/${userId}`, {})
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'updateAssignee', data: { type, id, userId } } })
    throw error
  }
}

export const getClients = async (params) => {
  try {
    const isActiveOnly = params.isActiveOnly === false ? false : true
    const response = await axiosSecure.get(`/v1/clients?ccid=${params.ccid}&isActiveOnly=${isActiveOnly}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getClients', params } })
    throw error
  }
}

export const updateClient = async (params) => {
  try {
    const { data } = params
    const response = await axiosSecure.post(`/v1/clients`, data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'updateClient', params } })
    throw error
  }
}

export const updateContact = async (params) => {
  try {
    const { data } = params
    const response = await axiosSecure.post(`/v1/contacts`, data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'updateContact', params } })
    throw error
  }
}

export const deleteContact = async (params) => {
  try {
    const response = await axiosSecure.delete(`/v1/contacts/${params.id}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'deleteContact', params } })
    throw error
  }
}

export const saveCalibration = async (params) => {
  try {
    const { data } = params
    const response = await axiosSecure.post(`/v1/calibrations`, data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'saveCalibration', params } })
    throw error
  }
}

export const saveService = async (params) => {
  try {
    const { data } = params
    const response = await axiosSecure.post(`/v1/services`, data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'saveService', params } })
    throw error
  }
}

export const getCalibrationChecklist = async (params) => {
  try {
    const response = await axiosSecure.get(`/v1/workorders/${params.workorderId}/calibrationchecklist`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getCalibrationChecklist', params } })
    throw error
  }
}

export const submitCalibrationChecklist = async (params) => {
  try {
    const response = await axiosSecure.post(`/v1/workorders/${params.workorderId}/calibrationchecklist`, params.data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'submitCalibrationChecklist', params } })
    throw error
  }
}

export const requestCreateQuote = (params) => {
  try {
    return axiosSecure.post(`/v1/quotes`, params.data)
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'requestCreateQuote', params } })
    throw error
  }
}

export const updateQuote = async (params) => {
  try {
    const response = await axiosSecure.put(`/v1/quotes/${params.quoteId}`, params.data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'updateQuote', params } })
    throw error
  }
}

export const updateNote = async ({ type, id, data }) => {
  try {
    let noteType = ''
    let note = ''
    if (data.noteType === 'external') {
      noteType = 'notesexternal'
      note = data.note.notes_external
    } else {
      noteType = 'notesinternal'
      note = data.note.notes_internal
    }
    const response = await axiosSecure.post(`/v1/${type}/${id}/${noteType}`, { note })
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'updateQuote', type, id, data } })
    throw error
  }
}

export const requestQuoteVehicleInfo = async (params) => {
  try {
    const { vin } = params
    return await axiosSecure.get(`/v1/quotes/vin/${vin}`)
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'requestQuoteVehicleInfo', params } })
    throw error
  }
}

export const getQuote = async (params) => {
  try {
    const response = await axiosSecure.get(`/v1/quotes/${params.quoteId}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getQuote', params } })
    throw error
  }
}

export const getQuoteByReferenceNumber = async ({ referenceNumber }) => {
  try {
    const response = await axiosSecure.get(`/v1/quotes/by-reference/${referenceNumber}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getQuoteByReferenceNumber', referenceNumber } })
    throw error
  }
}

export const getQuotelist = async (params) => {
  try {
    const { pageNo = 0, pageSize = 25, status = 'OPEN', ccid, sort = 'DESC', sortBy = 'createdAt', search = '' } = params
    const response = await axiosSecure.get(
      `/v1/quotes?pageNo=${pageNo}&pageSize=${pageSize}&status=${status}&ccid=${ccid}&sort=${sort}&sortBy=${sortBy}&search=${search}`
    )
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getQuotelist', params } })
    throw error
  }
}

export const closeQuote = async (params) => {
  try {
    const response = await axiosSecure.post(`/v1/quotes/${params.quoteId}/close`, {})
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'closeQuote', params } })
    throw error
  }
}

export const getQuoteEmails = async (params) => {
  try {
    const response = await axiosSecure.get(`/v1/quotes/${params.quoteId}/email`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getQuoteEmails', params } })
    throw error
  }
}

export const getQuotePdf = async (params) => {
  try {
    const config = {
      responseType: 'blob'
    }

    const response = await axiosSecure.get(`/v1/quotes/${params.quoteId}/pdf`, config)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getQuotePdf', params } })
    throw error
  }
}

export const sendQuoteEmail = async (params) => {
  try {
    const { data } = params

    const response = await axiosSecure.post(`/v1/quotes/${params.id}/email`, data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'sendQuoteEmail', params } })
    throw error
  }
}

export const transformToWO = async (params) => {
  try {
    const response = await axiosSecure.post(`/v1/quotes/${params.quoteId}/transform/workorder`, {})
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'transformToWO', params } })
    throw error
  }
}

export const getInvoicelist = async (params) => {
  try {
    const { pageNo = 0, pageSize = 25, status = 'OPEN', ccid, sort = 'DESC', sortBy = 'createdAt', search = '' } = params
    const response = await axiosSecure.get(
      `/v1/invoices?pageNo=${pageNo}&pageSize=${pageSize}&status=${status}&ccid=${ccid}&sort=${sort}&sortBy=${sortBy}&search=${search}`
    )
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getInvoicelist', params } })
    throw error
  }
}

export const updateInvoiceNotes = async (params) => {
  try {
    const response = await axiosSecure.post(`/v1/invoices/${params.invoiceId}/notes`, params)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'updateInvoiceNotes', params } })
    throw error
  }
}

export const reOpenInvoices = async (params) => {
  try {
    const { invoiceIds } = params
    const requestParams = invoiceIds.join(',')
    const response = await axiosSecure.post(`${baseUrl}/v1/invoices/reopen?idList=${requestParams}`, {})
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'reOpenInvoices', params } })
    throw error
  }
}

export const revertToWorkOrder = async (params) => {
  try {
    const { invoiceId } = params
    const response = await axiosSecure.post(`${baseUrl}/v1/invoices/revertToWorkOrder?invoiceId=${invoiceId}`, {})
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'revertToWorkOrder', params } })
    throw error
  }
}

export const convertWorkOrderToInvoice = async (params) => {
  try {
    const response = await axiosSecure.post(`/v1/invoices/${params.workOrderId}`, params.data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'convertWorkOrderToInvoice', params } })
    throw error
  }
}

export const getInvoicePdf = async (params) => {
  try {
    const config = {
      responseType: 'blob'
    }

    const response = await axiosSecure.get(`/v1/invoices/${params.invoiceId}/pdf`, config)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getInvoicePdf', params } })
    throw error
  }
}

export const getInvoiceCertificateOfCalibration = async (params) => {
  try {
    const config = {
      responseType: 'blob'
    }

    const response = await axiosSecure.get(`/v1/invoices/${params.invoiceId}/certificateofcalibration/pdf`, config)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getInvoiceCertificateOfCalibration', params } })
    throw error
  }
}

export const getClientInvoiceCOCPdf = async (params) => {
  try {
    const config = {
      responseType: 'blob'
    }

    const response = await axiosPublic.get(`/v1/invoices/${params.invoiceId}/client/summary/coc/pdf/${params.token}`, config)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getClientInvoiceCOCPdf', params } })
    throw error
  }
}

export const getInvoiceEmails = async (params) => {
  try {
    const response = await axiosSecure.get(`/v1/invoices/${params.invoiceId}/email`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getInvoiceEmails', params } })
    throw error
  }
}

export const getCalibrationCenter = async (params) => {
  try {
    const response = await axiosSecure.get(`/v1/calibrationcenters/${params.ccid}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getCalibrationCenter', params } })
    throw error
  }
}

export const sendInvoiceEmail = async (params) => {
  try {
    const { data } = params
    const response = await axiosSecure.post(`/v1/invoices/${params.id}/email`, data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'sendInvoiceEmail', params } })
    throw error
  }
}

export const getInvoice = async (params) => {
  try {
    const response = await axiosSecure.get(`/v1/invoices/${params.invoiceId}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getInvoice', params } })
    throw error
  }
}

export const getInvoiceByReferenceNumber = async ({ referenceNumber }) => {
  try {
    const response = await axiosSecure.get(`/v1/invoices/by-reference/${referenceNumber}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getInvoiceByReferenceNumber', referenceNumber } })
    throw error
  }
}

export const getInvoiceCsvByDateRange = async ({ startDate, endDate, ccId, status }) => {
  try {
    const response = await axiosSecure.get(
      `/v1/invoices/csv/download/date-range?fromDate=${encodeURIComponent(startDate)}&toDate=${encodeURIComponent(endDate)}&ccid=${ccId}&status=${status}`
    )
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getInvoiceCsvByDateRange', startDate, endDate } })
    throw error
  }
}

export const getClientInvoice = async (params) => {
  try {
    const response = await axiosPublic.get(`/v1/invoices/${params.invoiceId}/client/summary/${params.token}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getClientInvoice', params } })
    throw error
  }
}

export const getClientInvoicePhoto = async (params) => {
  try {
    const response = await axiosPublic.get(`/v1/invoices/${params.invoiceId}/client/summary/photos/${params.token}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getClientInvoicePhoto', params } })
    throw error
  }
}

export const getClientInvoiceDoc = async (params) => {
  try {
    const response = await axiosPublic.get(`/v1/invoices/${params.invoiceId}/client/summary/documents/${params.token}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getClientInvoiceDoc', params } })
    throw error
  }
}

export const getClientInvoicePdf = async (params) => {
  try {
    const config = {
      responseType: 'blob'
    }

    const response = await axiosPublic.get(`/v1/invoices/${params.invoiceId}/client/summary/pdf/${params.token}`, config)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getClientInvoicePdf', params } })
    throw error
  }
}

export const getClientInvoiceMedia = async (params) => {
  try {
    const config = {
      headers: {
        'Content-Type': 'application/zip'
      },
      responseType: 'blob'
    }

    const response = await axiosPublic.get(`/v1/invoices/${params.invoiceId}/client/summary/download/${params.token}`, config)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getClientInvoiceMedia', params } })
    throw error
  }
}

export const getClientInvoiceFile = async (params) => {
  try {
    const config = {
      responseType: 'blob'
    }

    const response = await axiosPublic.get(
      `/v1/invoices/${params.invoiceId}/client/summary/attachment/${params.attachmentId}/${params.token}`,
      config
    )
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getClientInvoiceFile', params } })
    throw error
  }
}

export const sendToQuickBook = async (params) => {
  try {
    const { invoiceIds } = params
    const requestParams = invoiceIds.join(',')

    const response = await axiosSecure.post(`/v1/integration/financial?id=${requestParams}`, {})
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'sendToQuickBook', params } })
    throw error
  }
}

export const quickbooksConnect = async (params) => {
  try {
    const { id } = params
    return await axiosSecure.get(`/v1/integration/financial/connect?ccid=${id}`)
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'quickbooksConnect', params } })
    throw error
  }
}

export const closeInvoiceManual = async (params) => {
  try {
    const { invoiceIds } = params
    const requestParams = invoiceIds.join(',')
    const response = await axiosSecure.post(`${baseUrl}/v1/invoices/close?idList=${requestParams}`, {})
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'closeInvoiceManual', params } })
    throw error
  }
}

export const getCalibrationCenterList = async ({ pageNo = 0, pageSize = 25, search, sort, sortBy }) => {
  try {
    const response = await axiosSecure.get(`/v1/calibrationcenters?pageNo=${pageNo}&pageSize=${pageSize}`)

    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getCalibrationCenterList', pageNo, pageSize, search, sort, sortBy } })
    throw error
  }
}

export const submitCustomItem = async ({ type, id, data }) => {
  try {
    const response = await axiosSecure.post(`/v1/${type}/${id}/customitems`, data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'submitCustomItem', type, id, data } })
    throw error
  }
}

export const getCustomItemList = async ({ type, id }) => {
  try {
    const response = await axiosSecure.get(`/v1/${type}/${id}/customitems`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getCustomItemList', type, id } })
    throw error
  }
}

export const removeCustomItem = async ({ type, id, customItemId }) => {
  try {
    const response = await axiosSecure.delete(`/v1/${type}/${id}/customitems/${customItemId}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'removeCustomItem', type, id, customItemId } })
    throw error
  }
}

export const createNewUser = async (params) => {
  try {
    const { data } = params
    const response = await axiosSecure.post(`/v1/users/request`, data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'createNewUser', params } })
    throw error
  }
}

export const getFinanciallyConnected = async ({ ccid }) => {
  try {
    const response = await axiosSecure.get(`/v1/calibrationcenters/${ccid}/integration/financial/connected`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getFinanciallyConnected', ccid } })
    throw error
  }
}

export const postFinancialDisconnect = async ({ ccid }) => {
  try {
    const response = await axiosSecure.post(`/v1/calibrationcenters/${ccid}/integration/financial/disconnect`, null)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'postFinancialDisconnect', ccid } })
    throw error
  }
}

export const createCalibrationCenter = async (params) => {
  try {
    const response = await axiosSecure.post(`${baseUrl}/v1/calibrationcenters`, params.data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'createCalibrationCenter', params } })
    throw error
  }
}

export const getAllUsers = async (params) => {
  try {
    const { pageNo = 0, pageSize = 100 } = params
    const response = await axiosSecure.get(`${baseUrl}/v1/users/all?pageNo=${pageNo}&pageSize=${pageSize}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getAllUsers', params } })
    throw error
  }
}

export const getOwnersList = async () => {
  try {
    const response = await axiosSecure.get(`${baseUrl}/v1/users/owners`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getOwnersList' } })
    throw error
  }
}

export const useUploadCalibrationCenterLogo = () => {
  const [isSuccess, setIsSuccess] = useState(false)
  const [progress, setProgress] = useState(0)
  const [newImageUrl, setNewImageUrl] = useState()

  const uploadData = async (params) => {
    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 50
          setProgress(progress)
        }
      }
      const response = await axiosSecure.post(`/v1/calibrationcenters/${params.ccId}/logo`, params.data, config)
      if (response.status === 200 || response.status === 201) {
        setNewImageUrl(response.data)
        setIsSuccess(true)
      }
    } catch (error) {
      setIsSuccess(false)
      Logger.error({ message: error, payload: { file: 'api', method: 'useUploadCalibrationCenterLogo/uploadData', params } })
      throw error
    }
  }

  const resetIsSuccess = () => {
    setIsSuccess(false)
  }

  return { progress, uploadData, isSuccess, newImageUrl, resetIsSuccess }
}

export const deleteCalibrationCenterLogo = async (params) => {
  try {
    const response = await axiosSecure.delete(`/v1/calibrationcenters/${params.ccId}/logo`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'deleteCalibrationCenterLogo', params } })
    throw error
  }
}

export const updatePhotoLabel = async (params) => {
  try {
    const response = await axiosSecure.post(`/v1/attachments/${params.attachmentId}/label?label=${params.label}`, {})
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'updatePhotoLabel', params } })
    throw error
  }
}

export const saveInvoiceScore = async (params) => {
  try {
    const response = await axiosSecure.post(`/v1/invoices/${params.invoiceId}/score`, params.data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'saveInvoiceScore', params } })
    throw error
  }
}

export const getInvoiceScore = async (params) => {
  try {
    return await axiosSecure.get(`/v1/invoices/${params.invoiceId}/score`)
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getInvoiceScore', params } })
    throw error
  }
}

export const getSupportTickets = async (params) => {
  try {
    const { pageNo = 0, pageSize = 25, status = 'OPEN', ccid, sort = 'DESC', sortBy = 'createdAt', search = '' } = params
    const response = await axiosSecure.get(
      `/v1/support-tickets?pageNo=${pageNo}&pageSize=${pageSize}&status=${status}&ccid=${ccid}&sort=${sort}&sortBy=${sortBy}&search=${search}`
    )
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getSupportTickets', params } })
    throw error
  }
}

export const getSupportTicketsConstants = async () => {
  try {
    const response = await axiosSecure.get(`/v1/support-tickets/constants`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getSupportTicketsConstants' } })
    throw error
  }
}

export const getSupportTicket = async (params) => {
  try {
    const response = await axiosSecure.get(`/v1/support-tickets/${params.id}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getSupportTicket' } })
    throw error
  }
}

export const getReferenceNumberBySearch = async ({ ccId, search }) => {
  try {
    const response = await axiosSecure.get(`/v1/qwi-mappings/reference-numbers?ccid=${ccId}&search=${search}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getReferenceNumberBySearch' } })
    throw error
  }
}

export const createSupportTicket = async (data) => {
  try {
    const response = await axiosSecure.post(`/v1/support-tickets`, data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'createSupportTicket' } })
    throw error
  }
}

export const updateSupportTicketResolution = async (params) => {
  try {
    const response = await axiosSecure.put(`/v1/support-tickets/${params.id}/update-resolution/${params.updateType}`, params.data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'updateSupportTicketResolution', params } })
    throw error
  }
}

export const uploadSupportPhoto = async ({ ticketId, attachmentNo, file }) => {
  try {
    const response = await axiosSecure.post(`/v1/support-tickets/${ticketId}/photo/${attachmentNo}`, file)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'uploadSupportPhoto', ticketId, attachmentNo, attachment: file } })
    throw error
  }
}

export const updateTicketAssignee = async ({ id, userId }) => {
  try {
    const response = await axiosSecure.put(`/v1/support-tickets/${id}/assign-to/${userId}`, {})
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'updateTicketAssignee', data: { id, userId } } })
    throw error
  }
}

export const updateTicketStatus = async ({ id, status }) => {
  try {
    const response = await axiosSecure.put(`/v1/support-tickets/${id}/update-status/${status}`, {})
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'updateTicketStatus', data: { id, status } } })
    throw error
  }
}

export const getSupportTicketStatusCount = async (params) => {
  try {
    const response = await axiosSecure.get(`/v1/support-tickets/status-count?ccid=${params.ccId}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getSupportTicketStatusCount' } })
    throw error
  }
}

export const getSupportPhoto = async ({ ticketId, photoId }) => {
  const config = {
    responseType: 'blob'
  }
  try {
    const response = await axiosSecure.get(`/v1/support-tickets/${ticketId}/photo/${photoId}`, config)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'uploadSupportPhoto' } })
    throw error
  }
}

export const updateSupportTicketDetails = async ({ ticketId, data }) => {
  try {
    const response = await axiosSecure.put(`/v1/support-tickets/${ticketId}/update`, data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'updateSupportTicketDetails', ticketId, data } })
    throw error
  }
}

export const getEmailHistory = async ({ qwi_id, qwi_stage }) => {
  try {
    const response = await axiosSecure.get(`/v1/qwi-email-history?qwi_id=${qwi_id}&qwi_stage=${qwi_stage}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getEmailHistory', qwi_id, qwi_stage } })
    throw error
  }
}

export const convertToStandardWO = async (workOrderId) => {
  try {
    return await axiosSecure.put(`/v1/workorders/${workOrderId}/convert-to-standard`)
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'convertToStandardWO', workOrderId } })
    throw error
  }
}

export const sendBroadcastNotification = async (data) => {
  try {
    return await axiosSecure.post(`/v1/broadcasts`, data)
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'sendBroadcastNotification', data } })
    throw error
  }
}

export const updateBroadcastNotification = async ({ broadcastId, data }) => {
  try {
    const response = await axiosSecure.put(`/v1/broadcasts/${broadcastId}`, data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'updateBroadcastNotification', broadcastId, data } })
    throw error
  }
}

export const getBroadcastById = async (params) => {
  try {
    const response = await axiosSecure.get(`/v1/broadcasts/${params.broadcastId}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getBroadcastById' } })
    throw error
  }
}

export const getActiveBroadcast = async () => {
  try {
    const response = await axiosSecure.get('/v1/broadcasts/active-broadcast')
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getActiveBroadcast' } })
    throw error
  }
}

export const getAllBroadcast = async () => {
  try {
    return await axiosSecure.get('/v1/broadcasts') // add additional params if this is being used else can remove the function itself
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getAllBroadcast' } })
    throw error
  }
}

export const endBroadcast = async (broadcastId) => {
  try {
    return await axiosSecure.put(`/v1/broadcasts/${broadcastId}/deactivate`)
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'endBroadcast' } })
  }
}

export const getInsuranceCompanies = async () => {
  try {
    const response = await axiosSecure.get(`/v1/insurance-company/all`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getInsuranceCompanies' } })
    throw error
  }
}

export const getOemPositions = async () => {
  try {
    const response = await axiosSecure.get(`/v1/oem-positions`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getOemPosition' } })
    throw error
  }
}

export const getOemPositionStatementsById = async (oemPositionId) => {
  try {
    const response = await axiosSecure.get(`v1/oem-position-statements/by-oem-position/${oemPositionId}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getOemPositionStatementsById' } })
    throw error
  }
}

export const deleteUser = async ({ userId, role, ccId }) => {
  try {
    const response = await axiosSecure.delete(`/v1/users/${userId}/${role}/${ccId}`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'deleteUser' } })
    throw error
  }
}

export const updateQBOItemizedForCalibrationCenter = async (ccId, qbo_itemized_invoice) => {
  try {
    const response = await axiosSecure.put(`${baseUrl}/v1/calibrationcenters/${ccId}/qbo-itemized-invoice/${qbo_itemized_invoice}`)
    return response
  } catch (error) {
    Logger.error({
      message: error,
      payload: { file: 'api', method: 'updateQBOItemizedForCalibrationCanter', params: { qbo_itemized_invoice } }
    })
  }
}

export const updateQBOClassNameForCalibrationCenter = async (ccId, qbo_class_name) => {
  try {
    const response = await axiosSecure.put(`${baseUrl}/v1/calibrationcenters/${ccId}/qbo-class-name`, null, {
      params: {
        class_name: qbo_class_name
      }
    })
    return response
  } catch (error) {
    Logger.error({
      message: error,
      payload: { file: 'api', method: 'updateQBOClassNameForCalibrationCenter', params: { qbo_class_name } }
    })
  }
}

export const downloadOEMStatementPDF = async (id) => {
  try {
    const config = {
      responseType: 'blob'
    }

    const response = await axiosSecure.get(`/v1/oem-position-statements/file/${id}`, config)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'downloadOEMStatementPDF' } })
    throw error
  }
}

export const downloadClientOEMStatementPDF = async ({ invoiceId, oemPositionStatementsId, token }) => {
  try {
    const config = {
      responseType: 'blob'
    }

    const response = await axiosSecure.get(
      `v1/invoices/${invoiceId}/client/summary/oem-position-statements/${oemPositionStatementsId}/${token}`,
      config
    )
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'downloadOEMStatementPDF' } })
    throw error
  }
}

export const getWorkorderPdf = async (workorderId) => {
  try {
    const config = {
      responseType: 'blob'
    }

    const response = await axiosSecure.get(`v1/workorders/${workorderId}/pdf`, config)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getWorkorderPdf', workorderId } })
    throw error
  }
}

export const getWorkorderEmailPreview = async (workorderId) => {
  try {
    const response = await axiosSecure.get(`/v1/workorders/${workorderId}/email-preview`)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'getWorkorderEmailPreview', workorderId } })
    throw error
  }
}

export const sendWorkorderEmail = async (params) => {
  try {
    const { data } = params
    const response = await axiosSecure.post(`/v1/workorders/${params.id}/email`, data)
    return response
  } catch (error) {
    Logger.error({ message: error, payload: { file: 'api', method: 'sendWorkorderEmail', params } })
    throw error
  }
}
