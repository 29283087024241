import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined'
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined'
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined'
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import HistoryEduIcon from '@mui/icons-material/HistoryEdu'
import HomeRepairServiceOutlinedIcon from '@mui/icons-material/HomeRepairServiceOutlined'
import HouseOutlinedIcon from '@mui/icons-material/HouseOutlined'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft'
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight'
import PublicIcon from '@mui/icons-material/Public'
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined'
import SearchIcon from '@mui/icons-material/Search'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined'
import { useCallback, useEffect, useState } from 'react'
import { IconButton, Drawer } from '@mui/material'
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined'
import { useCenterStore } from '@caradasstore/CenterStore'
import { useRevertedInvoice } from '@caradasstore/InvoiceEditStore'
import { useUserStore } from '@caradasstore/UserStore'
import { CalibrationCenterBar } from '@components/navigation-bar'
import { CaradasAppBar } from '@components/layout'
import { AdasBox, AdasImage, AdasListItemIcon, AdasMenuList, AdasPaper, AdasTypography } from '@components/wrapper-components'
import { convertWorkOrderToInvoice } from 'api/api'
import { useHistory, useLocation } from 'react-router-dom'
import CarADAS from '../../../assets/images/logo-carADASplus.svg'
import MenuIcon from '@mui/icons-material/Menu'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { AdminPanelSettingsOutlined } from '@mui/icons-material'
import AddAlertOutlinedIcon from '@mui/icons-material/AddAlertOutlined'
import { LABEL_DISPLAY } from '../../../constants'

import './NavigationBar.css'

const MobileHamburgerMenu = ({ navigationItems, pathName, handleMenuItemClick, title, user, centerDetail, setCenter }) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDrawer = () => {
    setIsOpen(!isOpen)
  }

  const handleItemClick = (path) => {
    handleMenuItemClick(path)
    setIsOpen(false)
  }

  return (
    <AdasBox
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '5%'
      }}
    >
      <IconButton edge='start' color='inherit' aria-label='menu' onClick={toggleDrawer} sx={{ justifyContent: 'flex-start' }}>
        <MenuIcon />
      </IconButton>
      <CaradasAppBar title={title} />
      <Drawer
        anchor='left'
        open={isOpen}
        onClose={toggleDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            width: '85%',
            overflowY: 'auto'
          }
        }}
      >
        <AdasBox className='menu-list-container' sx={{ height: '100%', overflowY: 'auto' }}>
          {' '}
          <IconButton onClick={toggleDrawer} className='back-button' sx={{ width: '15%' }}>
            <ArrowBackIcon />
          </IconButton>
          <AdasBox
            sx={{
              backgroundColor: '#E1E9F0',
              borderRadius: '4px',
              marginTop: '.75rem',
              alignItems: 'center',
              paddingLeft: '15px',
              height: '75px',
              justifyContent: 'center'
            }}
          >
            <CalibrationCenterBar currentUser={user} centerDetail={centerDetail} setCenterDetail={setCenter} isSideBarOpen={true} />
          </AdasBox>
          <AdasMenuList variant='withoutClickAway' onItemClick={handleItemClick} pathName={pathName} open={true} items={navigationItems} />
          <AdasBox className='footer-mobile'>
            <AdasTypography className='footer-text'>Powered By</AdasTypography>
            <AdasImage src={CarADAS} alt='Caradas Plus Logo' className='adas-logo' />
          </AdasBox>
          <AdasBox className='mobile-version-section'>
            <AdasTypography>{LABEL_DISPLAY.VERSION_NUMBER}</AdasTypography>
          </AdasBox>
        </AdasBox>
      </Drawer>
    </AdasBox>
  )
}

export const NavigationBar = ({ title }) => {
  const location = useLocation()
  const history = useHistory()
  const user = useUserStore((store) => store.currentUser)
  const centerDetail = useCenterStore((store) => store.centerDetail)
  const setCenterDetail = useCenterStore((store) => store.setCenterDetail)
  const invoicePendingToSubmit = useRevertedInvoice((store) => store.revertedInvoice)
  const resetRevertedInvoice = useRevertedInvoice((store) => store.setRevertedInvoice)
  const [open, setOpen] = useState(true)

  const navigationItems = [
    { label: 'Home', path: '/', icon: HouseOutlinedIcon, childPathPrefix: '/' },
    { label: 'VIN Search', path: '/vinsearch', icon: SearchIcon, childPathPrefix: '/vinsearch' },
    { label: 'Quotes', path: '/quotes', icon: CalculateOutlinedIcon, childPathPrefix: '/quote' },
    { label: 'Work Orders', path: '/workorders', icon: ReceiptLongOutlinedIcon, childPathPrefix: '/workorder' },
    { label: 'Invoices', path: '/invoices', icon: AttachMoneyOutlinedIcon, childPathPrefix: '/invoice' },
    { label: 'Clients', path: '/clients', icon: BusinessOutlinedIcon, childPathPrefix: '/client' },
    { label: 'Products', path: '/products', icon: HomeRepairServiceOutlinedIcon, childPathPrefix: '/product' },
    { label: 'Help', path: '/help', icon: HelpOutlineIcon, childPathPrefix: '/help' },
    { label: 'Support', path: '/support', icon: BuildOutlinedIcon, childPathPrefix: '/support' }
  ]

  const submitPendingInvoiceAndNavigate = async (path) => {
    await convertWorkOrderToInvoice({
      workOrderId: invoicePendingToSubmit.id
    })
    resetRevertedInvoice()
    if (path) history.push(path)
  }

  useEffect(() => {
    if (invoicePendingToSubmit && !location.pathname.startsWith('/workorder')) {
      submitPendingInvoiceAndNavigate(null)
    }
  }, [location])

  const setCenter = useCallback(
    (center) => {
      setCenterDetail(center)
    },
    [setCenterDetail]
  )

  const pathName = location.pathname

  if (user && (user.is_admin || user.is_manager || user.is_owner)) {
    navigationItems.push(
      { label: 'Settings', path: '/settings', icon: SettingsOutlinedIcon, childPathPrefix: '/settings' },
      { label: 'Reporting', path: '/reporting', icon: AssessmentOutlinedIcon, childPathPrefix: '/reporting' }
    )
  }
  navigationItems.push({ label: 'Training', path: '/training', icon: HistoryEduIcon, childPathPrefix: '/Training' })

  if (user && user.is_admin) {
    navigationItems.push({
      label: 'Admin Tools',
      path: '',
      icon: AdminPanelSettingsOutlined,
      childPathPrefix: '',
      children: [
        { label: 'Manage Locations', path: '/manage-location', icon: ViewListOutlinedIcon, childPathPrefix: '/manage-location' },
        { label: 'Global Directory', path: '/manage-users', icon: PublicIcon, childPathPrefix: '/manage-users' },
        { label: 'Broadcast Messages', path: '/broadcast-message', icon: AddAlertOutlinedIcon, childPathPrefix: '/broadcast-message' },
        { label: 'Health', path: '/health-check', icon: HealthAndSafetyOutlinedIcon, childPathPrefix: '/health-check' }
      ]
    })
  }

  const handleMenuItemClick = (path) => {
    if (path.includes('reporting')) {
      window.open(
        'https://app.powerbi.com/groups/me/apps/03f683aa-953d-461e-ad7a-b722f01d598a/reports/8811bdf4-ca4c-44df-8841-c96aa011d130/ReportSection24fa4ab7a430addb4a49?experience=power-bi',
        '_blank'
      )
    }
    if (path.includes('training')) {
      window.open('https://caradas.myabsorb.com/#/login', '_blank')
    } else {
      if (invoicePendingToSubmit) {
        submitPendingInvoiceAndNavigate(path)
      } else {
        history.push(path)
      }
    }
  }

  return (
    <AdasPaper>
      <AdasBox className='mobile-menu-container'>
        <MobileHamburgerMenu
          navigationItems={navigationItems}
          handleMenuItemClick={handleMenuItemClick}
          pathName={pathName}
          title={title}
          user={user}
          centerDetail={centerDetail}
          setCenter={setCenter}
        />
      </AdasBox>
      <AdasBox className='desktop-menu-container'>
        <AdasPaper className={`navigation-bar ${open ? '' : 'collapsed'}`}>
          <AdasBox className='top-section'>
            <CalibrationCenterBar currentUser={user} centerDetail={centerDetail} setCenterDetail={setCenter} isSideBarOpen={open} />
          </AdasBox>
          <AdasBox className='menu-list-container'>
            <AdasMenuList
              variant='withoutClickAway'
              onItemClick={handleMenuItemClick}
              pathName={pathName}
              open={open}
              items={navigationItems}
            />
            {!open && (
              <AdasBox onClick={() => setOpen(!open)}>
                <AdasListItemIcon>
                  <KeyboardDoubleArrowRightIcon className='menu-arrow-closed' color='primary' />
                </AdasListItemIcon>
              </AdasBox>
            )}
            {open && (
              <AdasBox className='toggle-button' onClick={() => setOpen(!open)}>
                <AdasListItemIcon className={'menu-arrow'}>
                  <KeyboardDoubleArrowLeftIcon color='primary' />
                </AdasListItemIcon>
              </AdasBox>
            )}
          </AdasBox>
          {open && (
            <AdasBox className='footer'>
              <AdasTypography className='footer-text'>Powered By</AdasTypography>
              <AdasImage src={CarADAS} alt='Caradas Plus Logo' className='adas-logo' />
            </AdasBox>
          )}
          <AdasBox className='footer-version-box'>
            <AdasTypography className='footer-version'>{LABEL_DISPLAY.VERSION_NUMBER}</AdasTypography>
          </AdasBox>
        </AdasPaper>
      </AdasBox>
    </AdasPaper>
  )
}
