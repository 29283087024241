import Paper from '@mui/material/Paper'
import PropTypes from 'prop-types'
import React from 'react'
import theme from '../../theme/theme'

const defaultStyles = {
  variant: 'elevation',
  sx: {
    backgroundColor: theme.palette.background.default
  }
}

// eslint-disable-next-line react/display-name
export const AdasPaper = React.forwardRef(({ children, sx = { ...defaultStyles.sx }, variant = defaultStyles.variant, ...rest }, ref) => {
  return (
    <Paper ref={ref} sx={sx} variant={variant} {...rest}>
      {children}
    </Paper>
  )
})

AdasPaper.propTypes = {
  children: PropTypes.node.isRequired,
  sx: PropTypes.object,
  variant: PropTypes.oneOf(['elevation', 'outlined'])
}
