import { BarcodeScanner, EnumBarcodeFormat } from 'dynamsoft-javascript-barcode'
import PropTypes from 'prop-types'
import React from 'react'

import { AdasBox, AdasButton } from '@components/wrapper-components'
import CloseIcon from '@mui/icons-material/Close'
import '../shared/dbr'
import './VideoDecode.css'

export class VideoDecode extends React.Component {
  constructor(props) {
    super(props)
    this.pScanner = null
    this.elRef = React.createRef()
    this.changeToManual = this.changeToManual.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  async changeToManual() {
    if (this.pScanner) {
      ;(await this.pScanner).destroyContext()
      console.log('BarcodeScanner Component Unmount')
      this.props.switchToManual()
    }
  }

  async closeModal() {
    if (this.pScanner) {
      ;(await this.pScanner).destroyContext()
      console.log('BarcodeScanner Component Unmount')
      this.props.closeModalFunc()
    }
  }

  async componentDidMount() {
    try {
      //const scanner = await (this.pScanner = BarcodeScanner.createInstance())
      this.pScanner = await BarcodeScanner.createInstance()
      const scanner = this.pScanner

      await scanner.setResolution(1920, 1080)
      await scanner.updateRuntimeSettings('speed')

      let settings = await scanner.getRuntimeSettings()
      settings.barcodeFormatIds =
        EnumBarcodeFormat.BF_CODE_39 |
        EnumBarcodeFormat.BF_CODE_39_EXTENDED |
        EnumBarcodeFormat.BF_CODE_128 |
        EnumBarcodeFormat.BF_CODE_93 |
        EnumBarcodeFormat.BF_CODABAR |
        EnumBarcodeFormat.BF_ITF |
        EnumBarcodeFormat.BF_INDUSTRIAL_25 |
        EnumBarcodeFormat.BF_QR_CODE |
        EnumBarcodeFormat.BF_DATAMATRIX
      settings.barcodeFormatIds_2 = []
      settings.localizationModes = [32, 8, 2, 0, 0, 0, 0, 0]
      settings.deblurLevel = 9
      settings.scaleDownThreshold = 100000
      settings.furtherModes.barcodeColourModes = [1, 2, 32, 0, 0, 0, 0, 0]
      settings.expectedBarcodesCount = 1
      settings.region.regionMeasuredByPercentage = 1
      settings.region.regionLeft = 0
      settings.region.regionTop = 30
      settings.region.regionRight = 100
      settings.region.regionBottom = 60
      await scanner.updateRuntimeSettings(settings)

      if (scanner.isContextDestroyed()) return
      await scanner.setUIElement(this.elRef.current)

      if (scanner.isContextDestroyed()) return
      scanner.onFrameRead = (results) => {
        for (let result of results) {
          console.log(result.barcodeText)
        }
      }
      scanner.onUniqueRead = (txt) => {
        this.props.setVinNumber(txt.length === 18 ? txt.substring(1) : txt)
        this.closeModal()
      }
      await scanner.open()
    } catch (ex) {
      let errMsg
      if (ex.message.includes('network connection error')) {
        errMsg =
          'Failed to connect to Dynamsoft License Server: network connection error. Check your Internet connection or contact Dynamsoft Support (support@dynamsoft.com) to acquire an offline license.'
      } else {
        errMsg = ex.message || ex
      }
      console.error(errMsg)
      alert(errMsg)
    }
  }

  async componentWillUnmount() {
    if (this.pScanner) {
      ;(await this.pScanner).destroyContext()
      console.log('BarcodeScanner Component Unmount')
    }
  }

  shouldComponentUpdate() {
    return false
  }

  render() {
    return (
      <AdasBox ref={this.elRef} className='component-barcode-scanner'>
        <AdasBox>
          <CloseIcon
            onClick={this.closeModal}
            sx={{
              color: 'white',
              position: 'absolute',
              top: '20px',
              left: '20px',
              zIndex: '1'
            }}
          ></CloseIcon>
        </AdasBox>
        <AdasBox className='dce-video-container'></AdasBox>
        <AdasBox className='dce-scanarea'>
          <AdasBox className='dce-scanlight'></AdasBox>
        </AdasBox>
        <AdasBox>
          <AdasButton
            variant='contained'
            aria-label='enter manually'
            color='primary'
            onClick={this.changeToManual}
            sx={{
              textTransform: 'none',
              borderRadius: '50px',
              position: 'absolute',
              bottom: '40px',
              right: '0',
              left: '0',
              width: 'max-content',
              margin: 'auto'
            }}
          >
            Enter VIN Manually
          </AdasButton>
        </AdasBox>
      </AdasBox>
    )
  }
}

VideoDecode.propTypes = {
  setVinNumber: PropTypes.func.isRequired,
  closeModalFunc: PropTypes.func.isRequired,
  switchToManual: PropTypes.func.isRequired
}
