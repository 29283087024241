import { useCenterStore } from '@caradasstore/CenterStore'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { ItemPerPageSelector, SearchOrAddData } from '@components/common'
import { AdasBox, AdasButton, AdasContainer, AdasPagination, AdasPaper, AdasStack, AdasTable } from '@components/wrapper-components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getCalibrationCenter, getCalibrationCenterList } from '../../../api/api'
import { CC_TABLE_HEADERS, DATE_FORMATS, TABLE_HEADER_STYLE } from '../../../constants'
import { scrollPageTop } from '../../../utils/useScrollTop'
import { ccidFormatter, downloadAsFile, formatDate, roundToGreatestInteger } from '../../../utils/utils'
import { Logger } from '../../../logger'

export const CalibrationCenterListPage = () => {
  const [searchInput, setSearchInput] = useState('')
  const [calibrationCenters, setCalibrationCenters] = useState([])
  const [pageNo, setPageNo] = useState(0)
  // const [totalPages, setTotalPages] = useState(0)
  const [totalCount, setTotalCount] = useState(0)
  const [pageSize, setPageSize] = useState(200)
  const [sort, setSort] = useState('ASC')
  const [sortField, setSortField] = useState('name')
  const setCenterDetail = useCenterStore((store) => store.setCenterDetail)
  const setLoading = useLoadingStore((store) => store.setLoading)

  const paginationHandler = (_event, page) => {
    setPageNo(page - 1)
    scrollPageTop()
  }

  const pageSizeHandler = (event) => {
    setPageNo(0)
    setPageSize(event.target.value)
    scrollPageTop()
  }

  useEffect(() => {
    setLoading(true)

    const fetchData = async () => {
      try {
        const ccListResponse = await getCalibrationCenterList({
          pageNo: 0,
          pageSize: 500
        })
        setLoading(false)

        if (ccListResponse.status === 200) {
          setCalibrationCenters(ccListResponse.data.calibrationCenters)
          setTotalCount(ccListResponse.data.totalCount)
          scrollPageTop()
        }
      } catch (error) {
        setLoading(false)
        Logger.error({ message: error, payload: { file: 'CalibrationCenterListPage', method: 'getCalibrationCenterList' } })
      }
    }

    fetchData()
  }, [])

  const searchInputHandler = (e) => {
    setSearchInput(e.target.value)
  }

  const clickSortHandler = useCallback(
    (field) => {
      if (sortField === field) {
        setSort(sort === 'ASC' ? 'DESC' : 'ASC')
      } else {
        setSort('ASC')
        setSortField(field)
      }
    },
    [sort, sortField]
  )

  const handleCCSwitch = async (data) => {
    const ccResponse = await getCalibrationCenter({
      ccid: data.id
    })
    if (ccResponse.status === 200) {
      setCenterDetail(ccResponse.data)
      history.push('/')
    }
  }

  function getValueByPath(obj, sortField) {
    const value = obj[sortField]

    if (sortField === 'owner') {
      return (value?.owners?.[0]?.name ?? '') + ''
    }
    if (typeof value === 'number') {
      return value
    }
    return value ?? ''
  }

  const sortedCalibrationCenterList = useMemo(() => {
    let filteredSortedList = calibrationCenters
    if (searchInput) {
      const search = searchInput.toLowerCase()
      filteredSortedList = calibrationCenters.filter(
        (cc) =>
          cc.name?.toLowerCase().includes(search) ||
          `cc-${String(cc.id).padStart(4, '0')}`.includes(search) ||
          String(cc.group_number)?.includes(search) ||
          cc.group_name?.toLowerCase().includes(search) ||
          cc.support_lead?.toLowerCase().includes(search) ||
          cc.owners[0]?.name?.toLowerCase().includes(search) ||
          cc.pbi_account_id?.toLowerCase().includes(search) ||
          new Date(cc.opening_date).toLocaleDateString().includes(search) ||
          cc.pbi_account_id?.toLowerCase().includes(search) ||
          (cc.is_active ? 'active' : 'inactive').startsWith(search)
      )
    }

    filteredSortedList = filteredSortedList.sort((a, b) => {
      const valueA = getValueByPath(a, sortField)
      const valueB = getValueByPath(b, sortField)
      const typeA = typeof valueA
      const typeB = typeof valueB

      if (typeA !== typeB) {
        const stringA = String(valueA)
        const stringB = String(valueB)
        return stringA.localeCompare(stringB, 'en', { sensitivity: 'base' }) * (sort === 'ASC' ? 1 : -1)
      }

      if (typeA === 'string') {
        return valueA.localeCompare(valueB, 'en', { sensitivity: 'base' }) * (sort === 'ASC' ? 1 : -1)
      }

      if (valueA < valueB) return sort === 'ASC' ? -1 : 1
      if (valueA > valueB) return sort === 'ASC' ? 1 : -1
      return 0
    })

    const startIndex = pageNo * pageSize
    const endIndex = startIndex + pageSize
    return filteredSortedList.slice(startIndex, endIndex)
  }, [calibrationCenters, searchInput, sort, sortField, pageNo, pageSize])

  const downloadCCList = useCallback(async () => {
    const {
      data: { calibrationCenters: ccList }
    } = await getCalibrationCenterList({
      pageNo: 0,
      pageSize: totalCount
    })

    let ccExport = ccList.map((cc, index) => [
      index,
      '"' + cc.name + '"',
      ccidFormatter(cc.id),
      cc.group_number,
      cc.group_name,
      cc.owners[0]?.name,
      cc.support_lead,
      cc.pbi_account_id,
      formatDate(cc.opening_date, DATE_FORMATS.ISO_DATE_STRING),
      cc.is_active ? 'Active' : 'Inactive',
      '"' + cc.shop_address + '"',
      cc.shop_city,
      cc.shop_state,
      cc.shop_zip,
      `${cc.phone}\n`
    ])
    ccExport = [
      [
        'Index',
        'CC Name',
        'CC #',
        'Group #',
        'Group Name',
        'Owner',
        'Support Lead',
        'PBI Account',
        'Open Date',
        'Status',
        'Address',
        'City',
        'State',
        'Zip',
        'Phone\n'
      ],
      ...ccExport
    ]
    const fileName = 'calibration_center_list.csv'
    const value = new Blob(ccExport, { type: 'text/plain' })
    downloadAsFile(value, fileName)
  }, [totalCount])

  const history = useHistory()
  return (
    <AdasContainer maxWidth='lg'>
      <AdasPaper
        sx={{
          padding: '20px 20px',
          backgroundColor: 'white',
          marginTop: '20px'
        }}
      >
        <SearchOrAddData
          searchInput={searchInput}
          searchInputHandler={searchInputHandler}
          onAddClick={() => history.push('/manage-location/new-location')}
          sx={{ marginBottom: '2rem' }}
        />
        {calibrationCenters.length > 0 && (
          <>
            <AdasTable
              containerProps={{ component: AdasPaper }}
              tableProps={{
                sx: {
                  marginTop: '20px',
                  '.MuiTableCell-root': { border: 'none', padding: '2px 10px' },
                  overflowX: 'scroll'
                },
                'aria-label': 'location table'
              }}
              columns={CC_TABLE_HEADERS}
              headerStyle={TABLE_HEADER_STYLE}
              sortField={sortField}
              sortDirection={sort}
              onSort={clickSortHandler}
              data={sortedCalibrationCenterList}
              onSelect={handleCCSwitch}
            />
            <AdasBox sx={{ margin: '20px 0px', textAlign: 'end' }} key='buttonArea'>
              <AdasButton key='exportAll' variant='contained' aria-label='Export List' color='primary' onClick={() => downloadCCList()}>
                Export List
              </AdasButton>
            </AdasBox>
          </>
        )}
      </AdasPaper>
      <AdasPaper
        elevation={0}
        sx={{
          padding: '20px',
          marginTop: '20px',
          marginBottom: '20px',
          display: 'flex'
        }}
      >
        <AdasBox sx={{ width: '20%' }}>
          <ItemPerPageSelector pageSizeHandler={pageSizeHandler} pageSize={pageSize} />
        </AdasBox>
        <AdasBox sx={{ width: '80%', display: 'flex', justifyContent: 'end' }}>
          <AdasStack spacing={2}>
            <AdasPagination onChange={paginationHandler} count={roundToGreatestInteger(totalCount / pageSize)} shape='rounded' />
          </AdasStack>
        </AdasBox>
      </AdasPaper>
    </AdasContainer>
  )
}
