import DoneAllIcon from '@mui/icons-material/DoneAll'
import { useTheme } from '@mui/material'
import PropTypes from 'prop-types'

import { AssignedUser } from '@components/assigned-user'
import { IdDisplay } from '@components/id-display'
import { InfoPopover } from '@components/popover'
import { StatusInfo } from '@components/status-info'
import { AdasBox, AdasCheckbox, AdasRadio, AdasTypography } from '@components/wrapper-components'
import { DATE_FORMATS, GENERIC_MESSAGES, LABEL_DISPLAY } from '../../constants'
import { StatusEnum, WorkFlowTypesEnum } from '../../enums/enums'
import { currencyFormatter, formatDate } from '../../utils/utils'
import './queueItem.css'

export const QueueItem = ({ item, isOptionEnabled, isSelected, radioChanged, onClickItem, itemType = 'Quote' }) => {
  const theme = useTheme()
  const detailString = `VIN: ${item.vehicle?.vin}`

  const showAgedInvoice = (date) => {
    const formattedDate = formatDate(date, DATE_FORMATS.ISO_DATE_STRING)
    if (!formattedDate) {
      return false
    }
    const currentDate = new Date()
    const twoDaysLater = new Date(formattedDate)
    twoDaysLater.setDate(twoDaysLater.getDate() + 2)

    return currentDate > twoDaysLater
  }

  return (
    <li key={item.id} className='queue-item'>
      {itemType !== 'Invoice'
        ? isOptionEnabled && (
            <AdasRadio
              size='small'
              className='queue-item-radio'
              checked={isSelected ? isSelected : false}
              onChange={() => radioChanged(item.id)}
              value='a'
              name='radio-buttons'
              inputProps={{ 'aria-label': 'A' }}
            />
          )
        : isOptionEnabled && (
            <AdasCheckbox checked={isSelected ? isSelected : false} onChange={(e) => radioChanged(e, item)} name='radio-buttons' />
          )}
      <AdasBox className={`queue-item-content ${isOptionEnabled ? 'is-option-enabled' : ''}`}>
        <AdasBox onClick={() => onClickItem(item)} className='queue-item-header'>
          <AdasBox className='queue-item-id-display'>
            <IdDisplay itemId={item.reference_number} roNumber={item.repair_order_num} itemType={itemType} serviceOnly={item.type} />
          </AdasBox>

          <AdasBox className='queue-item-status'>
            {item.quality_review?.is_completed && (
              <AdasBox component='span' className='queue-item-status-icon display-none-for-mobile'>
                <DoneAllIcon className='scorecard-icon' /> Scorecard
              </AdasBox>
            )}
          </AdasBox>

          <AdasBox className='queue-item-info'>
            <StatusInfo
              label={StatusEnum[item.status]}
              color={StatusEnum[item.status] === StatusEnum.OPEN ? 'primary' : 'secondary'}
              className='status-info'
            />
          </AdasBox>
        </AdasBox>
        <AdasBox onClick={() => onClickItem(item)} className='queue-item-body'>
          <AdasBox className='queue-item-body-left'>
            <AdasTypography className='vehicle-info'>
              <b>{`${item.vehicle?.year} ${item.vehicle?.make} ${item.vehicle?.model}`}</b>
            </AdasTypography>
            <AdasTypography className='typography-body2' color={theme.palette.secondary.dark}>
              {item.client?.name}
            </AdasTypography>
          </AdasBox>
          <AdasBox className='queue-item-body-right'>
            <AdasTypography className='vehicle-client'>
              <b>{currencyFormatter(item.total_cost)}</b>
            </AdasTypography>
            <AdasTypography variant='typography-body2' color={theme.palette.secondary.dark}>
              {detailString}
            </AdasTypography>
          </AdasBox>
        </AdasBox>
        <AdasBox className='queue-item-footer'>
          <AssignedUser
            type={itemType === WorkFlowTypesEnum.QUOTE ? 'quotes' : itemType === WorkFlowTypesEnum.WORKORDER ? 'workorders' : 'invoices'}
            workOrderId={item.id}
            initialAssignee={item.assigned_to}
            closedStatus={StatusEnum[item.status] === StatusEnum.CLOSED || itemType === WorkFlowTypesEnum.INVOICE}
          />
          {itemType === WorkFlowTypesEnum.INVOICE && StatusEnum[item.status] === StatusEnum.OPEN && showAgedInvoice(item.created_at) && (
            <AdasTypography component='span' className='aged-invoice-label'>
              <AdasTypography className='invoice-expired typography-body2 display-none-for-mobile'>
                {LABEL_DISPLAY.AGED_INVOICE_LABEL}
                <InfoPopover
                  text={GENERIC_MESSAGES.INVOICE_CLOSING_TIMELINE}
                  popoverWidth={'220px'}
                  popoverHeight={'100px'}
                  iconColor={'#f24848'}
                  ariaLabel='Information on default'
                />
              </AdasTypography>
            </AdasTypography>
          )}
          <AdasTypography variant='body2' color={theme.palette.secondary.dark} className='date-info'>
            {itemType === WorkFlowTypesEnum.WORKORDER
              ? 'Created: ' + new Date(item.created_at).toLocaleDateString()
              : itemType === WorkFlowTypesEnum.INVOICE
                ? !item.is_exported
                  ? 'Invoice Created: ' + new Date(item.created_at).toLocaleDateString()
                  : 'Invoice Closed: ' + new Date(item.closedAt).toLocaleDateString()
                : 'Created: ' + new Date(item.created_at).toLocaleDateString()}
          </AdasTypography>
        </AdasBox>
        {/* mobileSection start*/}
        <AdasBox className='queue-item-header display-none-for-bigger-devices'>
          <AdasBox className='queue-item-id-display'>
            {item.quality_review?.is_completed && (
              <AdasBox component='span' className='queue-item-status-icon'>
                <DoneAllIcon className='scorecard-icon' /> Scorecard
              </AdasBox>
            )}
          </AdasBox>
          <AdasBox className='queue-item-status'></AdasBox>
          {itemType === WorkFlowTypesEnum.INVOICE && StatusEnum[item.status] === StatusEnum.OPEN && showAgedInvoice(item.created_at) && (
            <AdasTypography component='span' className='queue-item-info'>
              <AdasTypography className='invoice-expired typography-body2'>
                {LABEL_DISPLAY.AGED_INVOICE_LABEL}
                <InfoPopover
                  text={GENERIC_MESSAGES.INVOICE_CLOSING_TIMELINE}
                  popoverWidth={'220px'}
                  popoverHeight={'100px'}
                  iconColor={'#f24848'}
                  ariaLabel='Information on default'
                />
              </AdasTypography>
            </AdasTypography>
          )}
        </AdasBox>
        {/* MobileSection end*/}
      </AdasBox>
    </li>
  )
}

QueueItem.propTypes = {
  item: PropTypes.object,
  isOptionEnabled: PropTypes.bool,
  isSelected: PropTypes.bool,
  radioChanged: PropTypes.func,
  onClickItem: PropTypes.func,
  itemType: PropTypes.string
}
