import { useCenterStore } from '@caradasstore/CenterStore'
import { SearchOrAddData } from '@components/common'
import { ItemPerPageSelector } from '@components/common'
import { useUserStore } from '@caradasstore/UserStore'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import {
  AdasBox,
  AdasButton,
  AdasStack,
  AdasButtonGroup,
  AdasContainer,
  AdasPagination,
  AdasPaper,
  AdasTable
} from '@components/wrapper-components'
import { SUPPORT_TICKETS_ADMIN_VIEW_HEADERS, SUPPORT_TICKETS_CC_VIEW_HEADERS, TABLE_HEADER_STYLE } from 'constants'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Logger } from '../../../logger'
import { getSupportTickets, getSupportTicketsConstants, getSupportTicketStatusCount } from '../../../api/api'
import { scrollPageTop } from '../../../utils/useScrollTop'
import { SupportRequestEnums } from 'enums/enums'

export const SupportQueuePage = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const statusFilter = queryParams.get('status') || 'NEW'
  const [pageParams, setPageParams] = useState({
    pageNo: 0,
    totalPages: 0,
    pageSize: 25,
    status: statusFilter,
    search: ''
  })
  const [searchInput, setSearchInput] = useState('')
  const [sort, setSort] = useState('DESC')
  const [sortField, setSortField] = useState('supportTicketId')
  const [supportTickets, setSupportTickets] = useState([])
  const [supportTicketsStatusCounts, setSupportTicketsStatusCounts] = useState(null)
  const [supportRequestConstants, setSupportRequestConstants] = useState({})
  const ccId = useCenterStore((store) => store.centerDetail?.id)
  const history = useHistory()
  const user = useUserStore((store) => store.currentUser)
  const setLoading = useLoadingStore((store) => store.setLoading)

  const onSearched = useCallback((e) => {
    setSearchInput(e.target.value)
  }, [])

  useEffect(() => {
    const fetchSupportTicketCountData = async () => {
      try {
        const supportTicketCount = await getSupportTicketStatusCount({ ccId: ccId })
        setSupportTicketsStatusCounts(supportTicketCount.data)
      } catch (error) {
        Logger.error({ message: error, payload: { file: 'SupportQueuePage', method: 'useEffect/getSupportTicketStatusCount' } })
      }
    }
    if (user.is_admin) {
      fetchSupportTicketCountData()
    }
  }, [ccId, user.is_admin])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const constants = await getSupportTicketsConstants()
        setSupportRequestConstants(constants.data)
      } catch (error) {
        Logger.error({ message: error, payload: { file: 'SupportQueuePage', method: 'useEffect/getSupportTicketsConstants' } })
      }
    }

    fetchData()
  }, [])

  useEffect(() => {
    const performSearch = () => {
      setPageParams((prev) => {
        const updated = {
          ...prev,
          search: searchInput
        }
        return updated
      })
    }

    // Set a delay to perform the search 500ms after typing stops
    const timeoutId = setTimeout(performSearch, 1000)
    return () => clearTimeout(timeoutId)
  }, [searchInput])

  const clickSortHandler = useCallback(
    (field) => {
      if (sortField === field) {
        setSort(sort === 'ASC' ? 'DESC' : 'ASC')
      } else {
        setSort('ASC')
        setSortField(field)
      }
    },
    [sort, sortField]
  )

  const pageSizeHandler = useCallback((event) => {
    setPageParams((prev) => {
      const updated = {
        ...prev,
        pageSize: event.target.value,
        pageNo: 0
      }
      return updated
    })
  }, [])

  const paginationHandler = useCallback((event, page) => {
    setPageParams((prev) => {
      const updated = {
        ...prev,
        pageNo: page - 1
      }
      return updated
    })
  }, [])

  useEffect(() => {
    const fetchSupportTickets = async () => {
      setLoading(true)
      try {
        const response = await getSupportTickets({
          pageNo: pageParams.pageNo,
          pageSize: pageParams.pageSize,
          status: pageParams.status === 'ALL' ? 'OPEN,CLOSED,NEW' : pageParams.status,
          ccid: ccId,
          search: pageParams.search,
          sortBy: pageParams.sortBy,
          sort: sort
        })
        setLoading(false)
        if (response.status === 200) {
          setSupportTickets(response.data.supportTickets)

          setPageParams((prev) => ({
            ...prev,
            totalPages: response.data.totalPages
          }))
          scrollPageTop()
        }
      } catch (error) {
        Logger.error({ message: error, payload: { pageParams, ccId } })
      } finally {
        setLoading(false)
      }
    }
    fetchSupportTickets()
  }, [ccId, pageParams.pageNo, pageParams.pageSize, pageParams.status, pageParams.search, sort, setLoading])

  const supportTicketsQueueData = useMemo(() => {
    return supportTickets.map((ticket) => {
      return {
        ...ticket,
        assignedTo: ticket.assigned_to?.name,
        calibration_name: ticket.calibrationCenter?.name,
        contact_name: ticket.contact_user_id?.name,
        support_type: supportRequestConstants?.supportTypes?.find((area) => area.slug === ticket.type)?.type,
        problem_area:
          SupportRequestEnums.CALIBRATION_DIAGNOSTIC === ticket.type
            ? supportRequestConstants?.supportProblemAreas?.find((area) => area.slug === ticket.problem_area)?.problemArea
            : supportRequestConstants?.supportItRequestTypes?.find((type) => type.slug === ticket.it_request_type)?.itRequestType
      }
    })
  }, [supportTickets, supportRequestConstants])

  const onSupportTicketSelected = (ticketDetails) => {
    history.push(`/support/support-request/${ticketDetails.id}`)
  }

  const onStatusFilterSelected = useCallback(
    (e) => {
      history.replace({
        pathname: window.location.pathname,
        search: `?status=${e.target.value}`
      })

      setPageParams((prev) => ({
        ...prev,
        status: e.target.value,
        pageNo: 0
      }))
    },
    [history]
  )

  const getStatusButtonContent = (status) => {
    if (user.is_admin && (status === 'NEW' || status === 'OPEN') && supportTicketsStatusCounts) {
      return ` (${supportTicketsStatusCounts[status.toLowerCase() + 'SupportTicketsCounts']})`
    } else {
      return ''
    }
  }

  return (
    <AdasContainer maxWidth='lg' sx={{ paddingBottom: '10px' }}>
      <AdasPaper
        sx={{
          padding: '20px 20px',
          backgroundColor: 'white',
          marginTop: '20px'
        }}
      >
        <AdasBox
          sx={{
            overflow: 'auto',
            fontSize: '14px',
            margin: '.5rem 0px',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <AdasButtonGroup>
            {['ALL', 'NEW', 'OPEN', 'CLOSED'].map((status) => (
              <AdasButton
                key={status}
                value={status}
                onClick={onStatusFilterSelected}
                variant={pageParams.status === status ? 'contained' : 'outlined'}
              >
                {status} {getStatusButtonContent(status)}
              </AdasButton>
            ))}
          </AdasButtonGroup>
        </AdasBox>
        <SearchOrAddData
          searchInput={searchInput}
          searchInputHandler={onSearched}
          variant={user?.is_admin ? 'withoutAddButton' : 'withAddButton'}
          onAddClick={() => history.push('/support/support-request')}
        />
        {supportTickets.length > 0 && (
          <>
            <AdasTable
              containerProps={{ component: AdasPaper }}
              tableProps={{
                sx: {
                  marginTop: '20px',
                  marginBottom: '20px',
                  '.MuiTableCell-root': { border: 'none', padding: '2px 20px' },
                  overflowX: 'scroll'
                },
                'aria-label': 'support queue table'
              }}
              tableRowProps={{
                sx: {
                  '.MuiTableCell-root': { paddingTop: '10px', paddingBottom: '10px' }
                }
              }}
              columns={
                user?.is_admin
                  ? SUPPORT_TICKETS_ADMIN_VIEW_HEADERS(pageParams.status !== 'ALL' && supportTickets[0].status)
                  : SUPPORT_TICKETS_CC_VIEW_HEADERS
              }
              sortField={sortField}
              sortDirection={sort}
              onSort={clickSortHandler}
              headerStyle={TABLE_HEADER_STYLE}
              data={supportTicketsQueueData}
              onSelect={onSupportTicketSelected}
            />
            <AdasPaper
              elevation={0}
              sx={{
                padding: '20px',
                marginTop: '20px',
                marginBottom: '20px',
                display: 'flex'
              }}
            >
              <AdasBox sx={{ width: '20%' }}>
                <ItemPerPageSelector
                  itemPerPageList={[25, 50, 100, 200]}
                  pageSizeHandler={pageSizeHandler}
                  pageSize={pageParams.pageSize}
                />
              </AdasBox>
              <AdasBox sx={{ width: '80%', display: 'flex', justifyContent: 'end' }}>
                <AdasStack spacing={2}>
                  <AdasPagination onChange={paginationHandler} page={pageParams.pageNo + 1} count={pageParams.totalPages} shape='rounded' />
                </AdasStack>
              </AdasBox>
            </AdasPaper>
          </>
        )}
      </AdasPaper>
    </AdasContainer>
  )
}
