import { AuthPage, ResetPasswordPage } from '@pages/auth'
import { BroadcastMessagePage } from '@pages/broadcast-message-page'
import { CalibrationPage, PostCalibrationPage, PreCalibrationPage } from '@pages/calibration'
import { CalibrationCenterListPage, NewCalibrationCenterPage } from '@pages/calibration-center'
import { ClientInvoiceDocumentPage, ClientInvoicePhotoPage, ClientInvoiceSummaryPage } from '@pages/client-invoice'
import { ClientListPage } from '@pages/client-list-page'
import { DocumentPage } from '@pages/document-page'
import { HomePage } from '@pages/home-page'
import { InvoiceQueuePage, InvoiceSummaryPage } from '@pages/invoice'
import { PhotoPage } from '@pages/photo-page'
import { ProductsPage } from '@pages/products-page'
import { NewQuote, QuoteDocument, QuotePage, QuoteSummaryPage } from '@pages/quotes'
import { SettingsPage } from '@pages/settings-page'
import { SupportPage } from '@pages/support-page'
import { SupportQueuePage, SupportRequestPage, CreateSupportRequestPage } from '@pages/support'
import { ManageUsersListPage } from '@pages/user/manage-user-list-page'
import { VehicleInfoPage } from '@pages/vehicle-info-page'
import { VinSearchPage } from '@pages/vin-search-page'
import { NewWorkOrderPage, WorkOrderPage, WorkOrderSummaryPage } from '@pages/work-order'
import { AuthRoute } from '@routes/app-route/auth-route'
import { PrivateRoute } from '@routes/app-route/private-route'
import { Redirect, Route, Switch } from 'react-router-dom'
import { HealthCheckPage } from '@pages/health-check'
import { REDIRECT_URLS } from '../../constants'
import { ForbiddenRoute } from './forbidden-route'

export const AppRoutes = () => {
  return (
    <>
      <Switch>
        <ForbiddenRoute path={REDIRECT_URLS.FORBIDDEN_PAGE} exact />
        <AuthRoute path='/authenticate' exact component={AuthPage} />
        <PrivateRoute path='/' exact component={HomePage} usePageLayout={true} />
        <PrivateRoute path='/vinsearch' exact component={VinSearchPage} usePageLayout={true} layoutProps={{ title: 'VIN Search' }} />
        <PrivateRoute path='/clients' exact component={ClientListPage} usePageLayout={true} layoutProps={{ title: 'Clients' }} />
        <PrivateRoute path='/products' exact component={ProductsPage} usePageLayout={true} layoutProps={{ title: 'Products' }} />
        <PrivateRoute
          path={REDIRECT_URLS.NEW_QUOTE}
          exact
          component={NewQuote}
          componentProps={{ isNewWorkOrder: true }}
          usePageLayout={true}
          layoutProps={{ title: 'Quote' }}
        />
        <PrivateRoute path='/quotes' exact component={QuotePage} usePageLayout={true} layoutProps={{ title: 'Quotes' }} />
        <PrivateRoute
          path={REDIRECT_URLS.QUOTE_SUMMARY}
          exact
          component={QuoteSummaryPage}
          usePageLayout={true}
          layoutProps={{ title: 'Quote Summary' }}
        />
        <PrivateRoute
          path={REDIRECT_URLS.QUOTE_DOCUMENT}
          exact
          component={QuoteDocument}
          usePageLayout={true}
          layoutProps={{ title: 'Documents' }}
        />
        <PrivateRoute
          path={REDIRECT_URLS.QUOTE_VEHICLE_INFO}
          exact
          component={VehicleInfoPage}
          componentProps={{ fromQuote: true, isNewWorkOrder: false, title: 'Vehicle Info' }}
          usePageLayout={true}
        />
        <PrivateRoute path='/workorders' exact component={WorkOrderPage} usePageLayout={true} layoutProps={{ title: 'Work Orders' }} />
        <PrivateRoute
          path={REDIRECT_URLS.NEW_WORK_ORDER}
          exact
          component={NewWorkOrderPage}
          usePageLayout={true}
          layoutProps={{ title: 'New Work Order' }}
        />
        <PrivateRoute
          path={REDIRECT_URLS.WORK_ORDER_SUMMARY}
          exact
          component={WorkOrderSummaryPage}
          usePageLayout={true}
          layoutProps={{ title: 'Work Order Summary' }}
        />
        <PrivateRoute
          path={REDIRECT_URLS.WORK_ORDER_DOCUMENT}
          exact
          component={DocumentPage}
          usePageLayout={true}
          layoutProps={{ title: 'Documents' }}
        />
        <PrivateRoute
          path={REDIRECT_URLS.WORK_ORDER_CALIBRATION}
          exact
          component={CalibrationPage}
          usePageLayout={true}
          layoutProps={{ title: 'Calibration' }}
        />
        <PrivateRoute path={REDIRECT_URLS.WORK_ORDER_PRE_CALIBRATION} exact component={PreCalibrationPage} usePageLayout={true} />
        <PrivateRoute path={REDIRECT_URLS.WORK_ORDER_POST_CALIBRATION} exact component={PostCalibrationPage} usePageLayout={true} />
        <PrivateRoute path={REDIRECT_URLS.WORK_ORDER_PHOTO} exact component={PhotoPage} usePageLayout={true} />
        <PrivateRoute
          path={REDIRECT_URLS.WORK_ORDER_VEHICLE_INFO}
          exact
          component={VehicleInfoPage}
          componentProps={{ fromWorkOrder: true, isNewWorkOrder: false, title: 'Vehicle Info' }}
          usePageLayout={true}
        />
        <PrivateRoute path='/invoices' exact component={InvoiceQueuePage} usePageLayout={true} layoutProps={{ title: 'Invoices' }} />
        <PrivateRoute
          path={REDIRECT_URLS.INVOICE_SUMMARY}
          exact
          component={InvoiceSummaryPage}
          componentProps={{ fromInvoice: true }}
          usePageLayout={true}
          layoutProps={{ title: 'Invoice Summary' }}
        />
        <PrivateRoute
          path={REDIRECT_URLS.INVOICE_DOCUMENT}
          exact
          component={DocumentPage}
          componentProps={{ fromInvoice: true }}
          usePageLayout={true}
          layoutProps={{ title: 'Documents' }}
        />
        <PrivateRoute
          path={REDIRECT_URLS.INVOICE_CALIBRATION}
          exact
          componentProps={{ fromInvoice: true }}
          component={CalibrationPage}
          usePageLayout={true}
          layoutProps={{ title: 'Calibration' }}
        />
        <PrivateRoute
          path={REDIRECT_URLS.INVOICE_PRE_CALIBRATION}
          exact
          component={PreCalibrationPage}
          componentProps={{ fromInvoice: true }}
          usePageLayout={true}
          layoutProps={{ title: 'Pre Calibration' }}
        />
        <PrivateRoute
          path={REDIRECT_URLS.INVOICE_POST_CALIBRATION}
          exact
          component={PostCalibrationPage}
          componentProps={{ fromInvoice: true }}
          layoutProps={{ title: 'Post Calibration' }}
          usePageLayout={true}
        />
        <PrivateRoute
          path={REDIRECT_URLS.INVOICE_PHOTO}
          exact
          component={PhotoPage}
          componentProps={{ fromInvoice: true }}
          usePageLayout={true}
        />
        <PrivateRoute
          path={REDIRECT_URLS.INVOICE_VEHICLE_INFO}
          exact
          component={VehicleInfoPage}
          componentProps={{ fromInvoice: true, isNewWorkOrder: false, title: 'Vehicle Info' }}
          usePageLayout={true}
        />
        <PrivateRoute
          path='/manage-location'
          exact
          component={CalibrationCenterListPage}
          usePageLayout={true}
          layoutProps={{ title: 'Manage Locations' }}
        />
        <PrivateRoute
          path='/manage-users'
          exact
          component={ManageUsersListPage}
          usePageLayout={true}
          layoutProps={{ title: 'Global Directory' }}
        />
        <PrivateRoute
          path='/manage-location/new-location'
          exact
          component={NewCalibrationCenterPage}
          usePageLayout={true}
          layoutProps={{ title: 'New Location' }}
        />
        <PrivateRoute path='/support' exact component={SupportQueuePage} usePageLayout={true} layoutProps={{ title: 'Support' }} />
        <PrivateRoute
          path='/support/support-request'
          exact
          component={SupportRequestPage}
          usePageLayout={true}
          layoutProps={{ title: 'Create Support Request' }}
        />
        <PrivateRoute
          path='/support/support-request/:ticketRequestId'
          exact
          component={CreateSupportRequestPage}
          usePageLayout={true}
          layoutProps={{ title: 'View Support Request' }}
        />
        <PrivateRoute
          path='/support/create-support-request'
          exact
          component={CreateSupportRequestPage}
          usePageLayout={true}
          layoutProps={{ title: 'Create Support Request' }}
        />
        <PrivateRoute
          path='/broadcast-message'
          exact
          component={BroadcastMessagePage}
          usePageLayout={true}
          layoutProps={{ title: 'Broadcast Messages' }}
        />
        <PrivateRoute path='/settings' exact component={SettingsPage} usePageLayout={true} layoutProps={{ title: 'Settings' }} />
        <PrivateRoute path='/help' exact component={SupportPage} usePageLayout={true} layoutProps={{ title: 'Support Page' }} />
        <PrivateRoute path='/health-check' exact component={HealthCheckPage} usePageLayout={true} layoutProps={{ title: '' }} />
        <Route path='/reset' component={ResetPasswordPage} />
        <Route path={REDIRECT_URLS.CLIENT_INVOICE_SUMMARY_PHOTO} exact component={ClientInvoicePhotoPage} />
        <Route path={REDIRECT_URLS.CLIENT_INVOICE_SUMMARY_DOCUMENT} exact component={ClientInvoiceDocumentPage} />
        <Route path={REDIRECT_URLS.CLIENT_INVOICE_SUMMARY} component={ClientInvoiceSummaryPage} />
        <Route path='*'>
          <Redirect to='/authenticate' />
        </Route>
      </Switch>
    </>
  )
}
