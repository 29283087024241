import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'

import { AdasBox, AdasButton, AdasModal, AdasPaper, AdasTextField, AdasTypography } from '@components/wrapper-components'
import { BUTTONS_DISPLAY_LABEL, GENERIC_MESSAGES } from '../../../constants'
import { useTheme } from '@mui/material'

export const ConfirmModal = ({
  open,
  handleClose,
  handleContinue,
  title,
  message,
  cancelLabel = BUTTONS_DISPLAY_LABEL.CANCEL,
  confirmLabel,
  typeCheck,
  warningMessage,
  adminCheck,
  adminCheckFailureMessage,
  notifyError
}) => {
  const [code, setCode] = useState('')
  const theme = useTheme()
  const handleModalClose = useCallback(() => {
    setCode('')
    handleClose()
  }, [handleClose])

  const handleSubmit = useCallback(() => {
    handleContinue(code)
    setCode('')
  }, [handleContinue, code])

  return (
    <AdasModal
      open={open}
      onClose={handleModalClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <AdasPaper
        variant='outlined'
        sx={{
          position: 'relative',
          padding: '20px',
          minHeight: '200px',
          minWidth: { xs: '0px', sm: '450px' },
          maxWidth: '600px',
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <AdasButton
          buttonType='iconButton'
          sx={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            '@media (max-width: 600px)': {
              fontSize: '16px'
            }
          }}
          onClick={handleModalClose}
        >
          <CloseIcon sx={{ color: theme.palette.info.main }} />
        </AdasButton>
        <AdasTypography
          variant='h2'
          sx={{
            marginTop: '0',
            fontSize: '18px',
            fontWeight: '700',
            lineHeight: '28px',
            textAlign: 'left',
            marginBottom: '20px'
          }}
        >
          {title}
        </AdasTypography>
        {warningMessage && (
          <AdasTypography
            variant='body1'
            sx={{
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '20px',
              letterSpacing: '0.15000000596046448px',
              textAlign: 'left',
              mb: 0,
              mt: '20px'
            }}
          >
            {warningMessage}
          </AdasTypography>
        )}
        {typeof message === 'string' ? (
          <AdasTypography
            variant='body1'
            sx={{
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '20px',
              letterSpacing: '0.15000000596046448px',
              textAlign: 'left',
              margin: '20px 0'
            }}
          >
            {message}
          </AdasTypography>
        ) : (
          <>{message}</>
        )}
        {!notifyError && typeCheck && (
          <AdasTextField
            onChange={(e) => setCode(e.target.value.toUpperCase())}
            name='type check'
            id='typeCheck'
            label={`Type ‘${typeCheck}’`}
            variant='outlined'
            fullWidth={true}
            InputLabelProps={{
              shrink: true
            }}
          />
        )}
        {adminCheck && (
          <>
            <AdasTextField
              onChange={(e) => setCode(e.target.value)}
              name='Admin Code'
              id='adminCodeInput'
              label={GENERIC_MESSAGES.SYSTEM_ADMIN_CODE}
              variant='outlined'
              fullWidth={true}
              InputLabelProps={{
                shrink: true
              }}
            />
            {adminCheckFailureMessage && (
              <AdasTypography
                sx={{
                  fontSize: '12px',
                  my: '2px',
                  mx: '5px',
                  color: theme.palette.error.main
                }}
              >
                {adminCheckFailureMessage}
              </AdasTypography>
            )}
          </>
        )}
        {!notifyError && (
          <AdasBox sx={{ display: 'flex', marginTop: '20px', justifyContent: 'flex-end' }}>
            <AdasButton sx={{ marginRight: '5px' }} variant='outlined' onClick={handleModalClose}>
              {cancelLabel}
            </AdasButton>
            <AdasButton disabled={typeCheck ? !(typeCheck === code) : adminCheck && !code} onClick={handleSubmit} variant='contained'>
              {confirmLabel}
            </AdasButton>
          </AdasBox>
        )}
      </AdasPaper>
    </AdasModal>
  )
}

ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  confirmLabel: PropTypes.string.isRequired,
  typeCheck: PropTypes.string
}
