import { InfoOutlined } from '@mui/icons-material'
import { useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

import { AdasButton, AdasPopover, AdasTypography } from '@components/wrapper-components'

export function InfoPopover({ text, ariaLabel, popoverWidth, popoverHeight, iconColor, enableClick = true, sx = {}, variant }) {
  const theme = useTheme()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event) => {
    if (enableClick) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const classes = {
    iconButton: {
      marginRight: theme.spacing(1),
      color: iconColor || theme.palette.primary.light
    },
    infoPopover: {
      padding: theme.spacing(2),
      maxWidth: popoverWidth || theme.breakpoints.values.sm,
      height: popoverHeight || 'auto',
      ...sx
    }
  }

  return (
    <>
      <AdasButton buttonType='iconButton' aria-label={ariaLabel} sx={classes.iconButton} onClick={handleClick} size='large'>
        <InfoOutlined sx={{ marginRight: { xs: '-18px' } }} />
      </AdasButton>
      <AdasPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: variant === 'top-right' ? 'right' : 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: variant === 'top-right' ? 'left' : 'center'
        }}
      >
        <AdasTypography sx={classes.infoPopover}>{text}</AdasTypography>
      </AdasPopover>
    </>
  )
}

InfoPopover.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  text: PropTypes.string,
  popoverWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  popoverHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  iconColor: PropTypes.string,
  enableClick: PropTypes.bool,
  sx: PropTypes.object,
  variant: PropTypes.string
}
