import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import PropTypes from 'prop-types'

export const AdasSelect = ({ onChange, options = [], disabled = false, ...rest }) => {
  const convertToIdValueArray = (data) => {
    return data.map((option) => {
      if (typeof option === 'object') {
        return { key: option.id, value: option.name || option.value }
      } else {
        return { key: option, value: option }
      }
    })
  }
  let keyValuePairs = convertToIdValueArray(options)
  return (
    <Select onChange={onChange} disabled={disabled} {...rest}>
      {keyValuePairs.map((option) => (
        <MenuItem key={option.key} value={option.key}>
          {option.value}
        </MenuItem>
      ))}
    </Select>
  )
}

AdasSelect.propTypes = {
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}
