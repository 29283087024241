import PropTypes from 'prop-types'
import { useState } from 'react'
import { LoadingBackdrop } from '@components/loader'
import { NavigationBar } from '@components/navigation-bar'
import { BroadcastBar } from '@components/broadcastBar-bar'
import { AdasBox } from '@components/wrapper-components'
import { AuthFailureInterceptor } from '../../api/AuthFailureInterceptor'
import { CaradasAppBar } from './CaradasAppBar'

export const PageLayout = ({ title, children }) => {
  const [open, setOpen] = useState(true)

  return (
    <AdasBox
      sx={{
        display: 'flex',
        overflow: 'hidden',
        flexDirection: {
          xs: 'column',
          sm: 'row'
        }
      }}
    >
      <AdasBox
        sx={{
          display: {
            xs: 'flex',
            sm: 'none',
            md: 'none'
          },
          height: '40px',
          backgroundColor: '#E1E9F0',
          flexGrow: 1
        }}
      ></AdasBox>{' '}
      {/* To Provide Gap Only for mobile */}
      <LoadingBackdrop />
      <NavigationBar onClick={() => setOpen(!open)} sx={{ height: '100dvh' }} title={title} />
      <AdasBox
        sx={{
          display: {
            xs: 'flex',
            sm: 'none',
            md: 'none'
          },
          height: '20px',
          backgroundColor: '#E1E9F0',
          flexGrow: 1
        }}
      ></AdasBox>{' '}
      {/* To Provide Gap Only for mobile */}
      <AuthFailureInterceptor />
      <AdasBox
        sx={{
          backgroundColor: '#E1E9F0',
          flexGrow: 1,
          overflow: 'auto',
          height: '100dvh'
        }}
      >
        <CaradasAppBar
          title={title}
          sx={{
            '@media (max-width: 600px)': {
              display: 'none'
            }
          }}
        />
        <BroadcastBar />
        {children}
      </AdasBox>
    </AdasBox>
  )
}

PageLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
}
