export const colHead = {
  fontSize: '12px',
  fontWeight: '500',
  lineHeight: '12px',
  letterSpacing: '0.10000000149011612px',
  textAlign: 'center'
}
export const groupLabel = {
  fontSize: '12px',
  fontWeight: '700',
  lineHeight: '18px;',
  letterSpacing: '1px;',
  textAlign: 'left',
  width: '100%',
  paddingTop: '.5rem',
  paddingBottom: '.5rem'
}
export const rowLabel = {
  fontSize: '14px',
  fontWeight: '400',
  lineHeight: '22px;',
  letterSpacing: '0.10000000149011612px',
  textAlign: 'left',
  flexGrow: '1',
  width: '232px',
  paddingLeft: '1rem'
}

export const fieldLabel = {
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '22px;',
  letterSpacing: '0.1px',
  width: '6rem'
}
export const fieldText = {
  fontSize: '14px',
  fontWeight: '500',
  lineHeight: '22px;',
  letterSpacing: '0.1px'
}
