import { useEffect, useRef, useState } from 'react'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import PhotoCameraOutlinedIcon from '@mui/icons-material/PhotoCameraOutlined'
import { useTheme } from '@mui/material'
import { red } from '@mui/material/colors'
import PropTypes from 'prop-types'

import { getImageThumbnail, removeAttachment, updatePhotoLabel, uploadImageAttachment } from '../../../api/api'
import { PhotoViewer } from '@components/photo'
import { OperationType } from '../../../enums/enums'
import { ConfirmModal } from '@components/common'
import { getClientInvoiceFile } from '../../../api/api'
import { GENERIC_MESSAGES, BUTTONS_DISPLAY_LABEL } from '../../../constants'
import { ATTACHMENT_TYPE_MAP, PHOTO_DISPLAY_LABEL_MAP } from '../../../variable-maps'
import {
  AdasBox,
  AdasButton,
  AdasFormControl,
  AdasInputLabel,
  AdasSelect,
  AdasTooltip,
  AdasTypography,
  AdasImage
} from '@components/wrapper-components'
import { AdasLoader } from '@components/adas-loader'
import { Logger } from '../../../logger'
import { useMessageStore } from '@caradasstore/MessageStore'

const DeleteMe = ({ whenDelete }) => {
  return (
    <AdasTooltip title='Delete'>
      <AdasButton
        buttonType='iconButton'
        size='medium'
        sx={{
          position: 'absolute',
          top: '.5rem',
          right: '.5rem'
        }}
        onClick={whenDelete}
      >
        <DeleteForeverIcon fontSize='inherit' sx={{ color: red[400] }} />
      </AdasButton>
    </AdasTooltip>
  )
}

export const PhotoInput = (props) => {
  const theme = useTheme()
  const {
    labelText,
    imageLocation,
    workorderId,
    isClientInvoice = false,
    clientAccessData,
    deleteOnRetake = false,
    onUpdate,
    allowDelete = false,
    imageId,
    label,
    labelList,
    disabled = false,
    img_rotation,
    title,
    isModalAlreadyOpened = false,
    isMultiUpload = false,
    maxPhotos
  } = props

  const fileRef = useRef(null)
  const [customLabel, setCustomLabel] = useState(label ? label : '')

  const [isModalOpen, setModalOpen] = useState(isModalAlreadyOpened)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [deleteAttachmentId, setDeleteAttachment] = useState(null)
  const [imageUrl, setImageUrl] = useState(null)
  const [loading, setLoading] = useState(false)
  const addMessage = useMessageStore((store) => store.addMessage)

  useEffect(() => {
    const fetchThumbnailData = async () => {
      if (isClientInvoice) {
        return getClientInvoiceFile({
          token: clientAccessData.token,
          invoiceId: clientAccessData.invoiceId,
          attachmentId: imageId
        })
      } else {
        return getImageThumbnail({
          attachmentId: imageId
        })
      }
    }

    const fetchImageThumbnail = async () => {
      if (!imageId) {
        return
      }

      try {
        setLoading(true)
        const thumbnailData = await fetchThumbnailData()
        const url = URL.createObjectURL(thumbnailData.data)
        setImageUrl(url)
      } catch (error) {
        Logger.error({ message: error, payload: { path: 'PhotoInput/FetchImageThumbnail', imageId } })
      } finally {
        setLoading(false)
      }
    }

    fetchImageThumbnail()
  }, [imageId, isClientInvoice, clientAccessData?.token, clientAccessData?.invoiceId])

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => {
    setModalOpen(false)
  }

  const handleUpload = async (ref) => {
    let files = isMultiUpload ? Array.from(ref.current.files) : [ref.current.files[0]]
    if (files.length > maxPhotos) {
      files = files.slice(0, maxPhotos)
      addMessage({ text: `Exceeded max upload limit! Uploading only first ${maxPhotos} ${maxPhotos > 1 ? 'images' : 'image'}` })
    }
    for (const file of files) {
      setLoading(true)
      const formData = new FormData()
      formData.append('file', file, file.name)
      let attachmentType = ATTACHMENT_TYPE_MAP[imageLocation]
      const params = {
        data: formData,
        workorderId: workorderId,
        type: attachmentType
      }
      try {
        if (deleteOnRetake && imageId) {
          await removeAttachment({ attachmentId: imageId })
        }

        const response = await uploadImageAttachment(params)

        if (response?.data) {
          const updatedData = {
            operation: OperationType.RETAKE,
            attachmentId: response.data?.id,
            img_rotation: 0,
            imageLocation: imageLocation,
            attachmentDetail: response.data
          }
          onUpdate(updatedData)
        }
      } catch (error) {
        console.error('Error uploading image:', error)
      } finally {
        setLoading(false)
      }
    }
  }

  const deleteAttachment = async (attachmentId) => {
    await removeAttachment({ attachmentId: attachmentId })

    const updatedData = {
      operation: OperationType.DELETE,
      imageLocation: imageLocation,
      attachmentId: attachmentId
    }
    onUpdate(updatedData)
  }

  const handleDelete = async () => {
    if (deleteAttachmentId) {
      setShowConfirmModal(false)
      deleteAttachment(deleteAttachmentId)
    }
  }

  const deleteWithConfirmation = (deleteImageId) => {
    setDeleteAttachment(deleteImageId)
    setShowConfirmModal(true)
  }

  const photoLabelHandler = async (value) => {
    const params = {
      attachmentId: imageId,
      label: value ? value : ''
    }

    const updateLabelResponse = await updatePhotoLabel(params)
    if (updateLabelResponse.status === 200) {
      setCustomLabel(value ? value : '')
    }
  }

  const classes = {
    subHeading: {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '21.98px',
      letterSpacing: '0.1px',
      marginTop: '8px',
      color: 'rgba(26, 27, 31, 0.87)'
    }
  }

  return !loading && !imageId ? (
    <AdasBox
      sx={{
        height: '200px',
        border: `1px dashed ${theme.palette.divider}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '20px',
        borderRadius: '4px'
      }}
    >
      <AdasButton
        component='label'
        sx={{
          fontSize: 12,
          margin: 'auto',
          display: 'flex',
          alignItems: 'center'
        }}
        startIcon={<PhotoCameraOutlinedIcon />}
        disabled={disabled}
      >
        <AdasTypography sx={{ fontSize: 12 }}>{labelText}</AdasTypography>
        <input ref={fileRef} type='file' hidden multiple={isMultiUpload} accept='image/*' onChange={() => handleUpload(fileRef)} />
      </AdasButton>
    </AdasBox>
  ) : (
    <AdasBox
      sx={{
        marginBottom: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <AdasBox id='image' sx={{ position: 'relative' }}>
        {loading ? (
          <AdasLoader
            sx={{
              height: '200px',
              minWidth: { xs: '100%', sm: '300px' },
              borderRadius: '4px'
            }}
          />
        ) : (
          <AdasImage
            src={imageUrl}
            alt={PHOTO_DISPLAY_LABEL_MAP[imageLocation]}
            sx={{
              height: '200px',
              objectFit: 'contain',
              borderRadius: '4px',
              width: `${img_rotation === 90 || img_rotation === 270 ? '200px' : '100%'}`,
              transform: `rotate(${img_rotation}deg)`
            }}
          />
        )}
        {allowDelete && !disabled && imageId && <DeleteMe whenDelete={() => deleteWithConfirmation(imageId)} />}
        {!isClientInvoice && (
          <>
            <AdasButton
              component='label'
              sx={{
                color: 'white',
                position: 'absolute',
                bottom: '0px',
                left: '0px',
                right: '0px',
                fontSize: '13px',
                backgroundColor: theme.palette.background.shaded,
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '20px',
                borderRadius: '0px',
                borderBottomLeftRadius: '4px',
                borderBottomRightRadius: '4px'
              }}
              disabled={loading}
              onClick={openModal}
              variant='button-small'
              startIcon={<PhotoCameraOutlinedIcon />}
            >
              <AdasTypography variant='button-small'>View Photo</AdasTypography>
            </AdasButton>
            <PhotoViewer
              isOpen={isModalOpen}
              closeModal={closeModal}
              imageUrl={imageUrl}
              workorderId={workorderId}
              imageId={imageId}
              imageLocation={imageLocation}
              img_rotation={img_rotation}
              onUpdate={onUpdate}
              allowDelete={allowDelete}
              disabled={disabled}
              title={PHOTO_DISPLAY_LABEL_MAP[imageLocation] || title}
            />
          </>
        )}
      </AdasBox>
      {showConfirmModal && (
        <ConfirmModal
          cancelLabel={BUTTONS_DISPLAY_LABEL.CANCEL}
          confirmLabel={BUTTONS_DISPLAY_LABEL.DELETE}
          open={showConfirmModal}
          title={GENERIC_MESSAGES.DELETE_PHOTO}
          message={GENERIC_MESSAGES.CONFIRM_DELETE_PHOTO}
          handleClose={() => setShowConfirmModal(false)}
          handleContinue={handleDelete}
        />
      )}
      {imageLocation !== 'additionalPhotos' ? (
        <AdasTypography variant='subtitle2' sx={classes.subHeading}>
          {PHOTO_DISPLAY_LABEL_MAP[imageLocation]}
        </AdasTypography>
      ) : !isClientInvoice ? (
        <AdasFormControl fullWidth sx={{ marginTop: '15px', width: '90%' }}>
          <AdasInputLabel id='Select Label'>Select Label</AdasInputLabel>
          <AdasSelect
            label='Select Label'
            sx={{
              maxHeight: '100px',
              overflowY: 'auto',
              width: '100%',
              borderTopLeftRadius: '4px',
              borderBottomLeftRadius: '4px',
              '.MuiOutlinedInput-root': {
                borderTopRightRadius: '0px',
                borderBottomRightRadius: '0px'
              }
            }}
            onChange={(e) => photoLabelHandler(e.target.value)}
            value={customLabel}
            name='labels'
            disabled={loading || disabled}
            options={labelList || []}
          />
        </AdasFormControl>
      ) : (
        <AdasTypography variant='subtitle2' sx={classes.subHeading}>
          {label}
        </AdasTypography>
      )}
    </AdasBox>
  )
}

PhotoInput.propTypes = {
  imageUrl: PropTypes.string,
  labelText: PropTypes.string,
  imageLocation: PropTypes.string,
  workorderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isClientInvoice: PropTypes.bool,
  deleteOnRetake: PropTypes.bool,
  onUpdate: PropTypes.func,
  allowDelete: PropTypes.bool,
  imageId: PropTypes.string,
  label: PropTypes.string,
  labelList: PropTypes.array,
  disabled: PropTypes.bool,
  img_rotation: PropTypes.number,
  title: PropTypes.string,
  isModalAlreadyOpened: PropTypes.bool
}
