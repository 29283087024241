import { useCenterStore } from '@caradasstore/CenterStore'
import { ClientForm, ClientInfo } from '@components/client'
import { SearchOrAddData } from '@components/common'
import { AdasBox, AdasContainer, AdasDrawer, AdasPaper, AdasTable, AdasTypography } from '@components/wrapper-components'
import CloseIcon from '@mui/icons-material/Close'
import { CLIENT_LIST_HEADERS, TABLE_HEADER_STYLE, CLIENT_TYPE_ENUM } from 'constants'
import { useCallback, useEffect, useState } from 'react'
import { getClients } from '../../api/api'

export const ClientListPage = () => {
  const [searchInput, setSearchInput] = useState('')
  const [fullClientList, setFullClientList] = useState([])
  const [existingClientNames, setExistingClientNames] = useState([])
  const [clients, setClients] = useState([])
  const [isClientInfo, setIsClientInfo] = useState(false)
  const [isAddClient, setIsAddClient] = useState(false)
  const [clientData, setClientData] = useState({})
  const [sort, setSort] = useState('ASC')
  const ccid = useCenterStore((store) => store.centerDetail?.id)

  const searchInputHandler = (e) => {
    setSearchInput(e.target.value)
  }

  const clickSortHandler = useCallback(
    (id) => {
      if (id === 'name') setSort(sort === 'ASC' ? 'DESC' : 'ASC')
    },
    [sort]
  )

  const fetchClient = useCallback(async () => {
    const clientListResponse = await getClients({
      ccid: ccid,
      isActiveOnly: false
    })
    if (clientListResponse.status === 200) {
      setFullClientList(clientListResponse.data)
      setExistingClientNames(clientListResponse.data.map((x) => x.name))
    }
  }, [ccid])

  // Initial Load
  useEffect(() => {
    fetchClient()
  }, [fetchClient])

  const sortByName = useCallback((a, b, sort) => {
    const name1 = sort === 'ASC' ? a.name.toUpperCase() : b.name.toUpperCase()
    const name2 = sort === 'ASC' ? b.name.toUpperCase() : a.name.toUpperCase()

    let comparison = 0

    if (name1 > name2) {
      comparison = 1
    } else if (name1 < name2) {
      comparison = -1
    }
    return comparison
  }, [])

  useEffect(() => {
    let list = [...fullClientList]
    if (searchInput) {
      list = list.filter((client) => client.name.toLowerCase().includes(searchInput))
    }
    list = list.map((client) => {
      const typeDescription = CLIENT_TYPE_ENUM[client.type] || ''
      return { ...client, typeDescription }
    })
    list.sort((a, b) => sortByName(a, b, sort))

    setClients(list)
  }, [sort, fullClientList, sortByName, searchInput])

  const clientInfoHandler = (props) => {
    setClientData(props)
    setIsClientInfo(true)
  }

  return (
    <AdasContainer maxWidth='lg' sx={{ paddingBottom: '10px' }}>
      {isClientInfo && (
        <AdasDrawer anchor={'right'} open={isClientInfo} onClose={() => setIsClientInfo(false)}>
          <ClientInfo
            updateClientListHandler={fetchClient}
            clientInfo={clientData}
            closeDrawer={() => setIsClientInfo(false)}
            existingClients={existingClientNames}
          />
        </AdasDrawer>
      )}
      {isAddClient && (
        <AdasDrawer anchor={'right'} open={isAddClient} onClose={() => setIsAddClient(false)}>
          <AdasBox sx={{ width: '400px' }}>
            <AdasBox sx={{ margin: '25px 24px' }}>
              <AdasTypography component='span'>Client Info</AdasTypography>
              <CloseIcon onClick={() => setIsAddClient(false)} sx={{ float: 'right' }} fontSize='small'></CloseIcon>
            </AdasBox>
            <ClientForm
              onSaveSuccess={fetchClient}
              clibrationCenterId={ccid}
              close={() => setIsAddClient(false)}
              existingClients={existingClientNames}
            />
          </AdasBox>
        </AdasDrawer>
      )}
      <AdasPaper
        sx={{
          padding: '20px 20px',
          backgroundColor: 'white',
          marginTop: '20px'
        }}
      >
        <SearchOrAddData searchInput={searchInput} searchInputHandler={searchInputHandler} onAddClick={() => setIsAddClient(true)} />
        {clients.length > 0 && (
          <AdasTable
            containerProps={{ component: AdasPaper }}
            tableProps={{
              sx: {
                marginTop: '20px',
                marginBottom: '20px',
                '.MuiTableCell-root': { border: 'none', padding: '2px 20px' },
                overflowX: 'scroll'
              },
              'aria-label': 'clients table'
            }}
            columns={CLIENT_LIST_HEADERS}
            sortField='name'
            sortDirection={sort}
            onSort={clickSortHandler}
            headerStyle={TABLE_HEADER_STYLE}
            data={clients}
            onSelect={clientInfoHandler}
          />
        )}
      </AdasPaper>
    </AdasContainer>
  )
}
