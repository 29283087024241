import { Drawer } from '@mui/material'
import PropTypes from 'prop-types'

export const AdasDrawer = ({ anchor = 'right', open, onClose, children, ...props }) => {
  return (
    <Drawer open={open} anchor={anchor} onClose={onClose} {...props}>
      {children}
    </Drawer>
  )
}

AdasDrawer.propTypes = {
  anchor: PropTypes.oneOf(['top', 'left', 'bottom', 'right']),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node
}
