import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { AdasLocalizationProvider } from './AdasLocalizationProvider'

const defaultStyles = {
  format: 'MM/dd/yyyy'
}

export const AdasDatePicker = ({ disabled, sx = {}, format = defaultStyles.format, disableFuture, label, value, onChange, ...rest }) => {
  return (
    <AdasLocalizationProvider>
      <DatePicker
        disabled={disabled}
        label={label}
        sx={sx}
        format={format}
        disableFuture={disableFuture}
        value={value ? dayjs(value, format) : null}
        onChange={onChange}
        {...rest}
      />
    </AdasLocalizationProvider>
  )
}

AdasDatePicker.propTypes = {
  disabled: PropTypes.bool,
  sx: PropTypes.object,
  format: PropTypes.string,
  disableFuture: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  onChange: PropTypes.func
}
