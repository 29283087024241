import { OwnerForm } from '@components/owner-form'
import {
  AdasBox,
  AdasButton,
  AdasCard,
  AdasContainer,
  AdasPaper,
  AdasSearchMenu,
  AdasStep,
  AdasStepLabel,
  AdasStepper,
  AdasTextField,
  AdasTypography
} from '@components/wrapper-components'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { UsaStates } from 'usa-states'
import { phoneNumberFormat, validations, zipCodeInput } from '../../../utils/utils'

export const NewCalibrationCenterPage = () => {
  const history = useHistory()
  const [calibrationCenter, setCalibrationCenter] = useState({
    name: '',
    website: '',
    email: '',
    phone: '',
    shop_address: '',
    shop_city: '',
    shop_state: '',
    shop_zip: '',
    shop_country: 'USA',
    active: true
  })
  const [locationComplete, setLocationComplete] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [touched, setTouched] = useState({
    name: false,
    email: false,
    phone: false,
    city: false
  })
  const [valid, setValid] = useState({
    name: validations.name(calibrationCenter.name),
    email: validations.email(calibrationCenter.email),
    phone: validations.phone(calibrationCenter.phone),
    city: validations.city(calibrationCenter.shop_city)
  })
  const usStates = new UsaStates()
  const steps = ['Set Up New Location', 'Invite an Owner']
  const isDisabled = () => {
    const filterData = Object.keys(calibrationCenter).filter((key) => !calibrationCenter[key])
    if (filterData.length < 1) {
      return false
    } else if (filterData.length === 1 && filterData.includes('website')) {
      return false
    } else {
      return true
    }
  }

  const handleInputChange = (e) => {
    const name = e.target.name === 'shop_city' ? 'city' : e.target.name
    const value = name === 'phone' ? phoneNumberFormat(e.target.value) : name === 'zipCode' ? zipCodeInput(e.target.value) : e.target.value
    setCalibrationCenter((prev) => {
      const calibrationCenter = {
        ...prev,
        [e.target.name]: value
      }
      return calibrationCenter
    })
    setTouched((prev) => {
      return {
        ...prev,
        [name]: true
      }
    })
    setValid((prev) => {
      const valid = {
        ...prev
      }
      if (validations[name]) {
        valid[name] = validations[name](value)
      }
      return valid
    })
  }

  const handleSetCalibrationInfo = () => {
    setActiveStep(1)
    setLocationComplete(true)
  }

  const handleCancel = () => {
    setActiveStep(0)
    setLocationComplete(false)
  }

  return (
    <AdasContainer maxWidth='lg'>
      <AdasCard
        sx={{
          margin: '1.25rem 1.5rem',
          padding: '1rem 1rem'
        }}
      >
        <AdasStepper activeStep={activeStep} sx={{ width: '60%' }}>
          {steps.map((label) => {
            return (
              <AdasStep key={label}>
                <AdasStepLabel>{label}</AdasStepLabel>
              </AdasStep>
            )
          })}
        </AdasStepper>
        {!locationComplete ? (
          [
            <AdasPaper
              key={'formGroup'}
              variant='outlined'
              sx={{
                backgroundColor: '#FDFBFF',
                padding: '16px',
                marginTop: '20px'
              }}
            >
              <AdasTypography
                variant='overline'
                sx={{
                  fontSize: '12px',
                  display: 'inline-block',
                  marginBottom: '20px'
                }}
              >
                CALIBRATION CENTER CONTACT INFO
              </AdasTypography>
              <AdasTextField
                onChange={handleInputChange}
                name='name'
                sx={{ marginBottom: '20px' }}
                id='name'
                label='Name *'
                variant='outlined'
                value={calibrationCenter.name}
                error={touched.name && !valid.name}
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <AdasTextField
                onChange={handleInputChange}
                name='website'
                sx={{ marginBottom: '20px' }}
                id='name'
                label='Website (Optional)'
                variant='outlined'
                value={calibrationCenter.website}
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <AdasTextField
                onChange={handleInputChange}
                name='email'
                sx={{ marginBottom: '20px' }}
                id='email'
                label='Email *'
                variant='outlined'
                value={calibrationCenter.email}
                error={touched.email && !valid.email}
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <AdasTextField
                onChange={handleInputChange}
                name='phone'
                sx={{ marginBottom: '20px' }}
                id='phone'
                label='Phone *'
                variant='outlined'
                value={calibrationCenter.phone ? phoneNumberFormat(calibrationCenter.phone) : ''}
                error={touched.phone && !valid.phone}
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
              />
              <AdasTextField
                onChange={handleInputChange}
                name='shop_address'
                sx={{ marginBottom: '20px' }}
                id='shop_address'
                label='Address *'
                variant='outlined'
                value={calibrationCenter.shop_address}
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 40
                }}
              />
              <AdasTextField
                onChange={handleInputChange}
                name='shop_city'
                sx={{ marginBottom: '20px' }}
                id='shop_city'
                label='City *'
                variant='outlined'
                value={calibrationCenter.shop_city}
                error={touched.city && !valid.city}
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 28
                }}
              />
              <AdasSearchMenu
                fullWidth
                id='select-state'
                select
                label='State *'
                variant='outlined'
                onChange={handleInputChange}
                sx={{ marginBottom: '20px' }}
                name='shop_state'
                value={calibrationCenter.shop_state}
                options={usStates?.states?.map((state) => ({
                  value: state.abbreviation,
                  name: state.name
                }))}
              />
              <AdasTextField
                onChange={handleInputChange}
                name='shop_zip'
                sx={{ marginBottom: '20px' }}
                id='shop_zip'
                label='Zip *'
                variant='outlined'
                value={calibrationCenter.shop_zip}
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 5
                }}
              />
              <AdasSearchMenu
                onChange={handleInputChange}
                name='shop_country'
                select
                sx={{ marginBottom: '20px' }}
                id='country'
                label='Country *'
                variant='outlined'
                value={calibrationCenter.shop_country}
                fullWidth={true}
                InputLabelProps={{
                  shrink: true
                }}
                inputProps={{
                  maxLength: 25
                }}
                options={[{ value: 'USA', name: 'USA' }]}
              />
            </AdasPaper>,
            <AdasBox key={'buttonGroup'} sx={{ margin: '20px 0px', overflow: 'auto' }}>
              <AdasBox sx={{ float: 'right' }}>
                <AdasButton
                  variant='outlined'
                  aria-label='cancel'
                  color='primary'
                  onClick={() => history.goBack()}
                  sx={{ marginRight: '10px' }}
                >
                  Cancel
                </AdasButton>
                <AdasButton
                  disabled={isDisabled()}
                  variant='contained'
                  aria-label='save'
                  color='primary'
                  onClick={handleSetCalibrationInfo}
                >
                  Set Calibration Center Info
                </AdasButton>
              </AdasBox>
            </AdasBox>
          ]
        ) : (
          <OwnerForm cancel={handleCancel} ccInfo={calibrationCenter} />
        )}
      </AdasCard>
    </AdasContainer>
  )
}
