import CloseIcon from '@mui/icons-material/Close'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import PropTypes from 'prop-types'
import { useRef, useState } from 'react'

import { ClientForm, ContactForm, ContactInfo } from '@components/client'
import { AdasBox, AdasButton, AdasCard, AdasTypography } from '@components/wrapper-components'
import { CaradasSnackbar } from '@components/snackbar'
import { updateContact } from '../../../api/api'
import { phoneNumberFormat } from '../../../utils/utils'
import { GENERIC_MESSAGES, LABEL_DISPLAY, CLIENT_TYPE_ENUM } from '../../../constants'
import { Logger } from '../../../logger'

export const ClientInfo = (props) => {
  const { clientInfo, closeDrawer, updateClientListHandler, existingClients = [] } = props
  const [openSnackBar, setOpenSnackBar] = useState(false)
  const [updatedClientInfo, setUpdatedClientInfo] = useState(clientInfo)
  const [isClientEdit, setIsClientEdit] = useState(false)
  const [isAddContact, setIsAddContact] = useState(false)
  const deletedContactRef = useRef()
  const labelStyle = {
    margin: '8px 0px',
    fontWeight: '600'
  }
  const tableCellStyle = {
    textAlign: 'left'
  }

  const onSaveClientSuccessHandler = (props) => {
    setUpdatedClientInfo(props)
    updateClientListHandler()
  }

  const onSaveContactSuccessHandler = (props) => {
    if (isAddContact) {
      setUpdatedClientInfo((prev) => {
        prev.contacts.push(props)
        const updated = {
          ...prev
        }
        return updated
      })
      setIsAddContact(false)
    }
    updateClientListHandler()
  }

  const onDeleteContactHandler = (props) => {
    deletedContactRef.current = props
    setUpdatedClientInfo((prev) => {
      prev.contacts.splice(props.index, 1)
      const updated = {
        ...prev
      }
      return updated
    })
    setOpenSnackBar(true)
    updateClientListHandler()
  }

  const undoDeleteContactHandler = async () => {
    try {
      const updateContactResponse = await updateContact({
        data: deletedContactRef.current.data
      })

      if (updateContactResponse.status === 200) {
        updateClientListHandler()
        setUpdatedClientInfo((prev) => {
          prev.contacts.splice(deletedContactRef.current.index, 0, updateContactResponse.data)
          const updated = { ...prev }
          return updated
        })
      }

      setOpenSnackBar(false)
    } catch (error) {
      Logger.error({ message: error, payload: { file: 'ClientInfo', method: 'undoDeleteContactHandler' } })
    }
  }

  return (
    <AdasBox sx={{ width: '400px' }}>
      <AdasBox sx={{ margin: '25px 24px' }}>
        <AdasTypography component='span'>Client Info</AdasTypography>
        <CloseIcon onClick={closeDrawer} sx={{ float: 'right' }} fontSize='small'></CloseIcon>
      </AdasBox>
      {!isClientEdit ? (
        <AdasCard
          sx={{
            margin: '17px 24px',
            padding: '16px 16px',
            backgroundColor: '#FDFBFF'
          }}
        >
          <AdasTypography sx={{ fontWeight: '600' }} variant='h6'>
            {updatedClientInfo.name}
          </AdasTypography>
          <AdasTypography sx={labelStyle} variant='subtitle2'>
            Type
          </AdasTypography>
          <AdasTypography>{CLIENT_TYPE_ENUM[updatedClientInfo.type] || ''}</AdasTypography>
          <AdasTypography sx={labelStyle} variant='subtitle2'>
            {LABEL_DISPLAY.MONTHLY_VEHICLECOUNT_LABEL}
          </AdasTypography>
          <AdasTypography variant='body1'>{updatedClientInfo.vehicle_count}</AdasTypography>
          <AdasTypography sx={labelStyle} variant='subtitle2'>
            Phone
          </AdasTypography>
          <AdasTypography>
            <a href={`tel:${updatedClientInfo.phone}`}>{phoneNumberFormat(updatedClientInfo.phone)}</a>
          </AdasTypography>
          <AdasTypography sx={labelStyle} variant='subtitle2'>
            Email
          </AdasTypography>
          <AdasTypography>
            <a href={`mailto:${updatedClientInfo.email}`}>{updatedClientInfo.email}</a>
          </AdasTypography>
          <AdasTypography sx={labelStyle} variant='subtitle2'>
            Address
          </AdasTypography>
          <AdasTypography>{updatedClientInfo.address1}</AdasTypography>
          <table style={{ width: '100%', marginTop: '20px' }}>
            <tbody>
              <tr>
                <th style={tableCellStyle}>City</th>
                <th style={tableCellStyle}>State</th>
                <th style={tableCellStyle}>Zip</th>
              </tr>
              <tr>
                <td style={tableCellStyle}>{updatedClientInfo.city}</td>
                <td style={tableCellStyle}>{updatedClientInfo.state}</td>
                <td style={tableCellStyle}>{updatedClientInfo.zip}</td>
              </tr>
            </tbody>
          </table>
          <AdasTypography sx={labelStyle} variant='subtitle2'>
            Country
          </AdasTypography>
          <AdasTypography>{updatedClientInfo.country}</AdasTypography>
          <AdasBox sx={{ textAlign: 'end' }}>
            <AdasButton
              startIcon={<ModeEditOutlineOutlinedIcon />}
              key='2'
              aria-label='Cancel'
              color='primary'
              onClick={() => setIsClientEdit(true)}
            >
              Edit
            </AdasButton>
          </AdasBox>
        </AdasCard>
      ) : (
        <ClientForm
          clientData={updatedClientInfo}
          close={() => setIsClientEdit(false)}
          onSaveSuccess={onSaveClientSuccessHandler}
          existingClients={existingClients.filter((name) => name !== updatedClientInfo.name)}
        />
      )}
      {updatedClientInfo.contacts.length > 0 && (
        <AdasBox sx={{ margin: '25px 24px' }}>
          <AdasTypography variant='subtitle2'>CONTACTS</AdasTypography>
        </AdasBox>
      )}
      {updatedClientInfo.contacts.length > 0 &&
        updatedClientInfo.contacts.map((contact, index) => {
          return (
            <ContactInfo
              key={contact.id}
              index={index}
              contact={contact}
              closeDrawer={closeDrawer}
              onSaveSuccess={onSaveContactSuccessHandler}
              deleteContactHandler={onDeleteContactHandler}
            />
          )
        })}
      {isAddContact && (
        <ContactForm
          isAddContact={isAddContact}
          deleteContactHandler={onDeleteContactHandler}
          contactData={{ client_id: clientInfo.id }}
          key={1}
          cancel={() => setIsAddContact(false)}
          onSaveSuccess={onSaveContactSuccessHandler}
        />
      )}
      <AdasBox sx={{ textAlign: 'end', paddingBottom: '20px', marginRight: '24px' }}>
        <AdasButton
          key='2'
          sx={{ marginTop: '20px' }}
          variant='outlined'
          aria-label='Cancel'
          color='primary'
          onClick={() => setIsAddContact(true)}
        >
          Add Contact
        </AdasButton>
      </AdasBox>
      <AdasBox>
        <CaradasSnackbar
          open={openSnackBar}
          message={GENERIC_MESSAGES.CONTACT_DELETED}
          onClose={setOpenSnackBar}
          onUndo={undoDeleteContactHandler}
        />
      </AdasBox>
    </AdasBox>
  )
}

ClientInfo.propTypes = {
  clientInfo: PropTypes.object,
  closeDrawer: PropTypes.func,
  updateClientListHandler: PropTypes.func,
  existingClients: PropTypes.array
}
