import PropTypes from 'prop-types'
import { useCallback, useState } from 'react'

import { ToggleActive } from '@components/buttons'
import { CurrencyInput } from '@components/currency-input'
import { InfoPopover } from '@components/popover'
import {
  AdasBox,
  AdasButton,
  AdasButtonGroup,
  AdasCard,
  AdasFormControl,
  AdasInputLabel,
  AdasSelect,
  AdasTextField,
  AdasTypography
} from '@components/wrapper-components'
import { saveCalibration, saveService } from '../../../api/api'
import { useUserStore } from '../../../store'
import { validations } from '../../../utils/utils'
import { categoryKeys, productCategories } from '../product-categories/productCategories'
import { Logger } from '../../../logger'

const prettyTypeName = {
  calibrations: 'Calibration',
  services: 'Service'
}

export const ProductForm = ({
  productData,
  close,
  onSaveSuccess,
  productTypeName,
  edit,
  multiline = true //This is to allow snapshot testing to work.
}) => {
  const [product, setProduct] = useState({
    is_active: true,
    ...productData,
    category: productData.category || ''
  })
  const [touched, setTouched] = useState({ name: false, price: false })
  const [valid, setValid] = useState({
    name: validations.name(product.name),
    price: validations.price(product.price),
    category: productTypeName === 'calibrations' ? validations.category(product.category) : true
  })
  const [infoLabel] = useState(`A Default ${prettyTypeName[productTypeName]} is automatically included in all new Quotes & Work Orders`)
  const currentUser = useUserStore((store) => store.currentUser)
  const isAdmin = currentUser.is_admin
  const isOwner = currentUser.is_owner

  const handleInputChange = useCallback(
    (e) => {
      const value = e.target.value
      const name = e.target.name
      setProduct((prev) => {
        const product = {
          ...prev,
          [name]: value
        }
        return product
      })
      setTouched((prev) => {
        const touched = {
          ...prev
        }
        if (validations[name]) {
          touched[name] = true
        }
        return touched
      })
      setValid((prev) => {
        const valid = {
          ...prev
        }
        if (validations[name]) {
          valid[name] = validations[name](value)
        }
        return valid
      })
    },
    [setValid, setProduct, setTouched]
  )

  const handleToggleActive = (e) => {
    const value = e.target.value
    setProduct((prev) => {
      const product = {
        ...prev,
        is_active: value === 'active'
      }
      return product
    })
  }

  const handleToggleDefault = (e) => {
    const value = e.target.value
    setProduct((prev) => {
      const product = {
        ...prev,
        is_defaulted: value === 'Yes'
      }
      return product
    })
  }

  const handleSaveCalibration = async () => {
    try {
      const data = { ...product }
      const response = await saveCalibration({
        data: data
      })

      if (response.status === 200) {
        close()
        onSaveSuccess(data)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { file: 'ProductForm/handleSaveCalibration', product } })
    }
  }

  const handleSaveService = async () => {
    try {
      const data = { ...product }
      const response = await saveService({
        data: data
      })

      if (response.status === 200) {
        close()
        onSaveSuccess(data)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { file: 'ProductForm/handleSaveService', product } })
    }
  }

  const productCategoriesKeyValuePair = []

  categoryKeys.forEach((key) => {
    productCategoriesKeyValuePair.push({
      id: key,
      value: productCategories[key]
    })
  })
  return (
    <AdasCard
      sx={{
        margin: '17px 24px',
        padding: '16px 16px',
        backgroundColor: '#FDFBFF'
      }}
    >
      <AdasBox sx={{ paddingBottom: '16px' }}>
        <AdasTypography variant='overline'>
          {productTypeName} ID #{product.itemNum}
        </AdasTypography>
      </AdasBox>
      {productTypeName === 'calibrations' && (
        <AdasFormControl fullWidth sx={{ paddingBottom: '1.5rem' }}>
          <AdasInputLabel id='category_label'>Category</AdasInputLabel>
          <AdasSelect
            labelId='Category'
            id='category'
            name='category'
            value={product.category}
            label='Category'
            onChange={handleInputChange}
            disabled={edit && !isAdmin}
            options={productCategoriesKeyValuePair}
          />
        </AdasFormControl>
      )}
      <AdasTextField
        onChange={handleInputChange}
        disabled={edit && !isAdmin && !isOwner}
        name='name'
        sx={{ marginBottom: '20px' }}
        id='name'
        label='Name *'
        variant='outlined'
        value={product && product.name ? product.name : ''}
        error={!valid.name && touched.name}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          maxLength: 80
        }}
      />
      <CurrencyInput
        value={product && product.price ? product.price : ''}
        error={touched.price && !valid.price}
        label='Price *'
        id='price'
        name='price'
        onChange={handleInputChange}
        fullWidth={true}
        variant='outlined'
        InputLabelProps={{
          shrink: true
        }}
        sx={{ marginBottom: '20px' }}
      />
      <AdasTextField
        onChange={handleInputChange}
        name='description'
        sx={{ marginBottom: '20px' }}
        id='description'
        label='Description'
        variant='outlined'
        multiline={multiline}
        value={product && product.description ? product.description : ''}
        fullWidth={true}
        inputProps={{ maxLength: 128 }}
        InputLabelProps={{
          shrink: true
        }}
      />
      <ToggleActive toggle={product.is_active} handleToggleActive={handleToggleActive} />
      <AdasInputLabel htmlFor='addToAll'>Default {prettyTypeName[productTypeName]}</AdasInputLabel>
      <AdasButtonGroup
        type='toggleButton'
        value={product.is_defaulted ? 'Yes' : 'No'}
        exclusive
        onChange={handleToggleDefault}
        aria-label='Add To all Toggle'
        name='addToAll'
        sx={{ marginBottom: '20px' }}
      >
        <AdasButton buttonType='toggleButton' value='Yes' color='primary' aria-label='Yes' sx={{ padding: '5px 10px' }}>
          Yes
        </AdasButton>
        <AdasButton buttonType='toggleButton' value='No' color='primary' aria-label='No' sx={{ padding: '5px 10px' }}>
          No
        </AdasButton>
      </AdasButtonGroup>
      <InfoPopover text={infoLabel} ariaLabel='Information on default' />
      <AdasBox sx={{ margin: '20px 0px' }}>
        <AdasButton variant='outlined' aria-label='cancel' color='primary' onClick={close}>
          Cancel
        </AdasButton>
        <AdasButton
          type='submit'
          disabled={!valid.name || !valid.price || !valid.category}
          sx={{ float: 'right' }}
          variant='contained'
          aria-label='save'
          color='primary'
          isDebounceEnabled={true}
          onClick={productTypeName === 'calibrations' ? handleSaveCalibration : handleSaveService}
        >
          Save
        </AdasButton>
      </AdasBox>
    </AdasCard>
  )
}

ProductForm.propTypes = {
  productData: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  onSaveSuccess: PropTypes.func.isRequired,
  productTypeName: PropTypes.string.isRequired,
  edit: PropTypes.bool,
  multiline: PropTypes.bool
}
