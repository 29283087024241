import PropTypes from 'prop-types'
import { useCallback, useMemo, useState } from 'react'

import { useCenterStore } from '@caradasstore/CenterStore'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { useUserStore } from '@caradasstore/UserStore'
import { ToggleActive } from '@components/buttons'
import {
  AdasBox,
  AdasButton,
  AdasButtonGroup,
  AdasCard,
  AdasInputLabel,
  AdasModal,
  AdasPaper,
  AdasTextField
} from '@components/wrapper-components'
import { createNewUser, deleteUser, updateUser } from '../../../api/api'
import { checkPermission, getRole, phoneNumberFormat, validations } from '../../../utils/utils'
import { BUTTONS_DISPLAY_LABEL, GENERIC_MESSAGES } from '../../../constants'
import { ConfirmModal } from '@components/common'

const ChallengeModal = ({ open, handleClose, handleContinue }) => {
  const [code, setCode] = useState('')

  return (
    <AdasModal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <AdasPaper
        variant='outlined'
        sx={{
          paddingLeft: '20px',
          paddingRight: '20px',
          paddingBottom: '20px'
        }}
      >
        <h2>System Admin Code Required</h2>
        <p>A system admin code is required to grant privileged access to all user accounts.</p>
        <AdasTextField
          onChange={(e) => setCode(e.target.value.toUpperCase())}
          name='Admin Code'
          sx={{ marginBottom: '20px' }}
          id='adminCodeInput'
          label='Admin Code'
          variant='outlined'
          fullWidth={true}
          InputLabelProps={{
            shrink: true
          }}
        />
        <AdasBox sx={{ display: 'flex' }}>
          <AdasButton sx={{ marginLeft: 'auto' }} onClick={handleClose}>
            Cancel
          </AdasButton>
          <AdasButton sx={{ color: 'red' }} onClick={() => handleContinue(code)}>
            Continue
          </AdasButton>
        </AdasBox>
      </AdasPaper>
    </AdasModal>
  )
}

export const UserForm = (props) => {
  const { userData, close, onSaveSuccess, isEdit = false, global = false, isAddUser = false, allowDelete = false, onDeleteSuccess } = props
  const centerDetail = useCenterStore((store) => store.centerDetail)
  const currentUser = useUserStore((store) => store.currentUser)
  const setLoading = useLoadingStore((store) => store.setLoading)
  const [user, setUser] = useState(userData)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [touched, setTouched] = useState({
    name: false,
    email: false,
    phone: false,
    role: false
  })
  const [valid, setValid] = useState({
    name: validations.name(userData.name),
    email: validations.email(userData.email),
    phone: validations.phone(userData.phone)
  })

  const handleInputChange = useCallback((e) => {
    const name = e.target.name
    const value = name === 'phone' ? phoneNumberFormat(e.target.value) : e.target.value
    setUser((prev) => {
      const user = {
        ...prev,
        [name]: value
      }
      return user
    })
    setTouched((prev) => {
      return {
        ...prev,
        [name]: true
      }
    })
    setValid((prev) => {
      const valid = {
        ...prev
      }
      if (validations[name]) {
        valid[name] = validations[name](value)
      }
      return valid
    })
  }, [])

  const phoneNumberDisplay = useCallback(() => {
    if (user.phone) {
      return phoneNumberFormat(user.phone)
    }
  }, [user?.phone])

  const handleToggleActive = useCallback((e) => {
    const value = e.target.value
    setUser((prev) => {
      return {
        ...prev,
        is_active: value === 'active'
      }
    })
  }, [])

  const handleToggleRole = useCallback((e) => {
    const value = e.target.value
    setTouched((prev) => {
      return {
        ...prev,
        role: true
      }
    })
    setUser((prev) => {
      const initial = {
        ...prev,
        is_admin: false,
        is_manager: false,
        is_owner: false
      }

      if (value === 'Admin') {
        return {
          ...initial,
          is_admin: true
        }
      } else if (value === 'Owner') {
        return {
          ...initial,
          is_owner: true
        }
      } else if (value === 'Manager') {
        return {
          ...initial,
          is_manager: true
        }
      } else {
        return initial
      }
    })
  }, [])

  const handleSaveUser = useCallback(
    (override) => {
      if (user.is_admin && touched.role && !override) {
        setShowModal(true)
        return
      }
      setLoading(true)
      const updatedUserDetails = {}
      for (const key in user) {
        if (Object.prototype.hasOwnProperty.call(user, key)) {
          updatedUserDetails[key] = typeof user[key] === 'string' ? user[key].trim() : user[key]
        }
      }
      setUser(updatedUserDetails)
      const data = {
        calibration_center_id: centerDetail.id,
        ...updatedUserDetails
      }
      const saveUserResponse = isEdit
        ? updateUser({
            data: data
          })
        : createNewUser({
            data: data
          })
      saveUserResponse.then((response) => {
        setLoading(false)
        if (response.status === 200 || response.status === 201) {
          close()
          onSaveSuccess(data)
        }
      })
    },
    [centerDetail?.id, close, isEdit, onSaveSuccess, setLoading, touched.role, user]
  )

  const handleDeleteUser = useCallback(async () => {
    const role = userData.is_manager ? 'manage' : userData.is_owner ? 'owns' : 'tech'
    const response = await deleteUser({ userId: user.id, role, ccId: centerDetail.id })
    if (response?.status === 200) {
      close()
      onDeleteSuccess()
    }
  }, [user, userData, centerDetail.id])

  const [currentUserRole, targetUserRole] = useMemo(() => {
    const targetUserRole = userData.is_admin ? 'admin' : userData.is_manager ? 'manager' : userData.is_owner ? 'owner' : 'tech'
    const currentUserRole = currentUser.is_admin ? 'admin' : currentUser.is_manager ? 'manager' : currentUser.is_owner ? 'owner' : 'tech'
    return [currentUserRole, targetUserRole]
  }, [currentUser, user])

  const modalContinue = useCallback(
    (code) => {
      if (code === '2180') {
        setShowModal(false)
        handleSaveUser(true)
      } else {
        setShowModal(true)
      }
    },
    [handleSaveUser]
  )

  const isDisable = !valid.name || !valid.email || !valid.phone
  return (
    <AdasCard
      sx={{
        margin: '17px 24px',
        padding: '16px 16px',
        backgroundColor: '#FDFBFF'
      }}
    >
      <AdasTextField
        onChange={handleInputChange}
        name='name'
        sx={{ marginBottom: '20px' }}
        id='name'
        label='Name *'
        variant='outlined'
        value={user && user.name ? user.name : ''}
        error={touched.name && !valid.name}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          maxLength: 40
        }}
        disabled={global}
      />
      <AdasTextField
        type='email'
        onChange={handleInputChange}
        name='email'
        sx={{ marginBottom: '20px' }}
        id='email'
        label='Email *'
        variant='outlined'
        value={user && user.email ? user.email : ''}
        error={touched.email && !valid.email}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          maxLength: 50
        }}
        disabled={global}
      />
      <AdasTextField
        onChange={handleInputChange}
        name='phone'
        sx={{ marginBottom: '20px' }}
        id='phone'
        label='Phone *'
        variant='outlined'
        value={user && user.phone ? phoneNumberDisplay(user.phone) : ''}
        error={touched.phone && !valid.phone}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          maxLength: 14
        }}
        disabled={global}
      />
      <ToggleActive toggle={user && user.is_active} handleToggleActive={handleToggleActive} />
      <AdasInputLabel htmlFor='role'>Role</AdasInputLabel>
      <AdasButtonGroup
        type='toggleButton'
        value={user ? getRole(user) : 'Tech'}
        exclusive
        onChange={handleToggleRole}
        aria-label='Role'
        name='role'
        sx={{ marginBottom: '20px' }}
        disabled={global}
      >
        {currentUser.is_admin && (
          <AdasButton
            buttonType='toggleButton'
            value='Admin'
            color='primary'
            aria-label='Admin'
            sx={{ padding: '5px 10px' }}
            disabled={global}
          >
            Admin
          </AdasButton>
        )}
        {(currentUser.is_admin || currentUser.is_owner) && (
          <AdasButton
            buttonType='toggleButton'
            value='Owner'
            color='primary'
            aria-label='Owner'
            sx={{ padding: '5px 10px' }}
            disabled={global}
          >
            Owner
          </AdasButton>
        )}
        <AdasButton
          buttonType='toggleButton'
          value='Manager'
          color='primary'
          aria-label='Manager'
          sx={{ padding: '5px 10px' }}
          disabled={global}
        >
          Manager
        </AdasButton>
        <AdasButton buttonType='toggleButton' value='Tech' color='primary' aria-label='Tech' sx={{ padding: '5px 10px' }} disabled={global}>
          Tech
        </AdasButton>
      </AdasButtonGroup>
      <AdasBox sx={{ margin: '20px 0px' }}>
        {allowDelete && !isAddUser && checkPermission({ currentUserRole, targetUserRole }) && (
          <AdasButton
            type='submit'
            variant='contained'
            aria-label='save'
            color='error'
            sx={{ float: 'left' }}
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </AdasButton>
        )}
        <AdasButton
          type='submit'
          disabled={isDisable}
          sx={{ float: 'right' }}
          variant='contained'
          aria-label='save'
          color='primary'
          onClick={() => handleSaveUser(false)}
        >
          Save
        </AdasButton>
        <AdasButton sx={{ float: 'right', mr: '14px' }} variant='outlined' aria-label='cancel' color='primary' onClick={close}>
          Cancel
        </AdasButton>
      </AdasBox>
      <ChallengeModal open={showModal} handleClose={() => setShowModal(false)} handleContinue={modalContinue} />
      <ConfirmModal
        cancelLabel={BUTTONS_DISPLAY_LABEL.CANCEL}
        confirmLabel={BUTTONS_DISPLAY_LABEL.CONFIRM}
        open={showDeleteModal}
        title={GENERIC_MESSAGES.DELETE_USER}
        message={GENERIC_MESSAGES.DELETE_USER_MESSAGE}
        handleClose={() => setShowDeleteModal(false)}
        handleContinue={handleDeleteUser}
      />
    </AdasCard>
  )
}

UserForm.propTypes = {
  userData: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  onSaveSuccess: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  global: PropTypes.bool,
  allowDelete: PropTypes.bool,
  isAddUser: PropTypes.bool,
  onDeleteSuccess: PropTypes.func
}

ChallengeModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired
}
