import CloseIcon from '@mui/icons-material/Close'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import PropTypes from 'prop-types'
import { useState } from 'react'

import { StatusInfo } from '@components/status-info'
import { UserForm } from '@components/user'
import { AdasBox, AdasButton, AdasCard, AdasTypography } from '@components/wrapper-components'
import { getRole, phoneNumberFormat } from '../../../utils/utils'

export const UserInfo = (props) => {
  const { userInfo, closeDrawer, updateClientListHandler, global = false, userCenters = [], allowDelete = false } = props
  const [updatedUserInfo, setUpdatedUserInfo] = useState(userInfo)
  const [isUserEdit, setIsUserEdit] = useState(false)
  const labelStyle = {
    margin: '8px 0px',
    fontWeight: '600'
  }

  const onSaveClientSuccessHandler = (props) => {
    setUpdatedUserInfo(props)
    updateClientListHandler()
    setIsUserEdit(false)
    closeDrawer()
  }

  const onDeleteClientSuccessHandler = () => {
    updateClientListHandler()
    closeDrawer()
  }

  return (
    <AdasBox sx={{ width: '400px' }}>
      <AdasBox sx={{ margin: '25px 24px' }}>
        <span>User Info</span>
        <CloseIcon onClick={closeDrawer} sx={{ float: 'right' }} fontSize='small'></CloseIcon>
      </AdasBox>
      {!isUserEdit ? (
        <AdasCard
          sx={{
            margin: '17px 24px',
            padding: '16px 16px',
            backgroundColor: '#FDFBFF'
          }}
        >
          <span>
            <AdasTypography sx={{ fontWeight: '600', display: 'inline' }} variant='h6'>
              {updatedUserInfo.name}
            </AdasTypography>
            <StatusInfo
              color={updatedUserInfo.is_active ? 'primary' : 'default'}
              label={updatedUserInfo?.is_active ? 'Active' : 'Inactive'}
              sx={{ float: 'right' }}
            />
          </span>
          <AdasTypography sx={labelStyle} variant='subtitle2'>
            Role
          </AdasTypography>
          <AdasTypography>{getRole(updatedUserInfo)}</AdasTypography>
          <AdasTypography sx={labelStyle} variant='subtitle2'>
            Email
          </AdasTypography>
          <AdasTypography>
            <a href={`mailto:${updatedUserInfo.email}`}>{updatedUserInfo.email}</a>
          </AdasTypography>
          <AdasTypography sx={labelStyle} variant='subtitle2'>
            Phone
          </AdasTypography>
          <AdasTypography>
            <a href={`tel:${updatedUserInfo.phone}`}>{phoneNumberFormat(updatedUserInfo.phone)}</a>
          </AdasTypography>
          {userCenters.length > 0 && (
            <AdasBox>
              <AdasTypography sx={labelStyle} variant='subtitle2'>
                Center(s)
              </AdasTypography>
              <ul>
                {userCenters.map((center) => (
                  <li key={center.id}>{center.name}</li>
                ))}
              </ul>
            </AdasBox>
          )}
          <AdasBox sx={{ textAlign: 'end' }}>
            <AdasButton
              startIcon={<ModeEditOutlineOutlinedIcon />}
              key='2'
              aria-label='Cancel'
              color='primary'
              onClick={() => setIsUserEdit(true)}
            >
              Edit
            </AdasButton>
          </AdasBox>
        </AdasCard>
      ) : (
        <UserForm
          allowDelete={allowDelete}
          isAddUser={false}
          isEdit={isUserEdit}
          userData={userInfo}
          close={() => setIsUserEdit(false)}
          onSaveSuccess={onSaveClientSuccessHandler}
          onDeleteSuccess={onDeleteClientSuccessHandler}
          global={global}
        />
      )}
    </AdasBox>
  )
}

UserInfo.propTypes = {
  userInfo: PropTypes.object,
  closeDrawer: PropTypes.func.isRequired,
  updateClientListHandler: PropTypes.func.isRequired,
  global: PropTypes.bool,
  userCenters: PropTypes.array,
  allowDelete: PropTypes.bool
}
