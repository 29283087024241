import { AdasContainer, AdasPaper } from '@components/wrapper-components'
import Iframe from 'react-iframe'
export const HealthCheckPage = () => {
  return (
    <AdasContainer maxWidth='lg' styles={{ height: '100vh' }}>
      <AdasPaper elevation={0} sx={{ padding: '1.25rem', marginTop: '1.25rem', height: '100vh' }}>
        <Iframe url='https://health.caradasplus.com/' position='static' width='100%' id='myId' className='myClassname' height='100%' />
      </AdasPaper>
    </AdasContainer>
  )
}
