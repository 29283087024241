import { FilePresentOutlined } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import { useTheme } from '@mui/material'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'

import { AdasBox, AdasLinearProgress, AdasLink, AdasButton, AdasTypography } from '@components/wrapper-components'
import { useSubmitFileAttachment, removeAttachment, getFile, getClientInvoiceFile } from '../../../api/api'
import { formatTime } from '../../../utils/utils'
import { MIME_TYPES_MAP, ATTACHMENT_TYPE_MAP } from '../../../variable-maps'
import { useMessageStore } from '../../../store'
import { Logger } from '../../../logger'
import { AdasLoader } from '@components/adas-loader'

const BYTES_IN_MB = 1000000

export const FileUpload = (props) => {
  const theme = useTheme()
  const {
    typeLabel,
    title,
    workOrderData,
    existingData,
    removeFile,
    additionalDocument,
    isClientInvoice = false,
    disabled = false,
    type = 'quotes'
  } = props
  const [data, setData] = useState(null)
  const [fileURL, setFileURL] = useState('')
  const displayData = data ? data : existingData
  const fileRef = useRef(null)
  const { timeLeft, progress, uploadData, isSuccess, attachmentId, resetIsSuccess } = useSubmitFileAttachment()
  const addMessage = useMessageStore((store) => store.addMessage)
  const [loading, setLoading] = useState(false)

  const handleUpload = () => {
    const file = fileRef.current.files[0]
    if (!file) {
      return
    }
    const suppoprtedFileTypes = Object.values(MIME_TYPES_MAP)
    if (!suppoprtedFileTypes.includes(file.type)) {
      addMessage({ text: 'File format not supported!' })
      return
    }
    setData(file)
  }

  const truncateFileName = useCallback((fileName) => {
    if (fileName.length <= 80) return fileName
    const extStart = fileName.lastIndexOf('.')
    let ext = fileName.slice(extStart, fileName.length)
    let name = fileName.slice(0, 80 - ext.length)
    return name + ext
  }, [])

  const uploadFile = async (file, docType) => {
    const formData = new FormData()
    formData.append('file', file, truncateFileName(file.name))
    let attachmentType = ATTACHMENT_TYPE_MAP[docType]
    const params = {
      data: formData,
      workorderId: workOrderData.id,
      attachmentType,
      type,
      onFailed: () => setData(null)
    }
    const response = uploadData(params)
    response.then(() => {}).catch(() => setData(null))
  }

  const handleClose = async () => {
    if (!disabled) {
      const params = {
        workorderId: workOrderData.id,
        attachmentId: existingData ? existingData.id : attachmentId
      }
      const removeResponse = await removeAttachment(params)
      if (removeResponse.status === 200) {
        if (existingData || additionalDocument) {
          removeFile()
        }
        setData(null)
      }
    }
  }

  const createUrl = (data) => {
    const extIndex = data.name.lastIndexOf('.')
    const fileExt = data.name.slice(extIndex, data.name.length)
    const fileBlob = new Blob([data], {
      type: MIME_TYPES_MAP[fileExt.toLowerCase()] || 'application/octet-stream'
    })
    return URL.createObjectURL(fileBlob)
  }

  useEffect(() => {
    const fetchData = async () => {
      if (existingData) {
        try {
          setLoading(true)
          let response = null
          if (isClientInvoice) {
            response = await getClientInvoiceFile({
              token: workOrderData.tempToken,
              invoiceId: workOrderData.id,
              attachmentId: existingData.id
            })
          } else {
            response = await getFile({
              attachmentId: existingData.id
            })
          }

          const fileValue = new File([response.data], existingData.name)
          fileValue.id = existingData.id
          displayData.size = fileValue.size
          const url = createUrl(fileValue)
          setFileURL(url)
        } catch (error) {
          Logger.error({ message: error, payload: { existingData } })
        } finally {
          setLoading(false)
        }
      }
    }

    fetchData()

    return () => {
      URL.revokeObjectURL(fileURL)
    }
    /* eslint-disable */
  }, [existingData])

  useEffect(() => {
    if (data) {
      uploadFile(data, typeLabel)
      setFileURL(createUrl(data))

      return () => {
        URL.revokeObjectURL(fileURL)
      }
    } else {
      resetIsSuccess()
    }
    /* eslint-disable */
  }, [data])

  useEffect(() => {
    if (additionalDocument && !additionalDocument.removed) {
      setData(additionalDocument)
    } else {
      resetIsSuccess()
    }
    /* eslint-disable */
  }, [additionalDocument])

  return !displayData ? (
    !isClientInvoice && (
      <AdasBox
        sx={{
          flexDirection: 'column',
          height: '222px',
          width: '203px',
          border: `1px dashed ${theme.palette.divider}`,
          borderRadius: '4px',
          display: 'flex',
          margin: '16px auto'
        }}
      >
        {' '}
        <AdasButton
          component='label'
          sx={{
            textTransform: 'none',
            fontSize: 12,
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column'
          }}
          startIcon={<FileUploadOutlinedIcon sx={{ width: '30px', height: '30px' }} />}
          disabled={disabled}
        >
          <AdasBox sx={{ fontSize: '12px', textAlign: 'center', marginTop: '16px' }}>{title}</AdasBox>
          <AdasBox sx={{ fontSize: '10px', color: theme.palette.text.secondary, textAlign: 'center', marginTop: '10px' }}>
            (.pdf, .docx, .doc, .png, .jpeg, .txt)
          </AdasBox>
          <input ref={fileRef} type='file' hidden onChange={handleUpload} accept='.jpg, .jpeg, .png, .pdf, .doc, .docx, .txt' />
        </AdasButton>
      </AdasBox>
    )
  ) : loading ? (
    <AdasLoader
      sx={{
        height: '222px',
        width: '203px',
        borderRadius: '4px',
        margin: '16px auto',
        paddingBottom: '16px'
      }}
    />
  ) : (
    <AdasBox
      sx={{
        height: '222px',
        width: '203px',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '4px',
        background: theme.palette.background.default,
        margin: '16px auto',
        flexDirection: 'column',
        paddingBottom: '16px'
      }}
    >
      <AdasBox
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          borderBottom: `1px solid ${theme.palette.divider}`,
          paddingBottom: '27px'
        }}
      >
        <AdasBox
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: 'auto',
            marginBottom: '2px',
            marginTop: '6px',
            marginRight: '5px'
          }}
        >
          {!isClientInvoice && (
            <CloseIcon
              sx={{
                width: '16px',
                height: '16px',
                color: disabled ? 'grey' : theme.palette.action.active.light,
                cursor: disabled ? 'not-allowed' : 'pointer'
              }}
              onClick={handleClose}
            ></CloseIcon>
          )}
        </AdasBox>
        <FilePresentOutlined
          sx={{
            color: '#CC3838',
            width: '59px',
            height: '59px'
          }}
        ></FilePresentOutlined>
        <AdasTypography
          component='span'
          sx={{
            margin: 'auto',
            marginTop: '13px',
            display: 'block',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '100%',
            paddingLeft: '10px',
            paddingRight: '8px'
          }}
        >
          <AdasLink
            sx={{
              fontSize: '14px',
              textDecoration: 'none',
              color: '#0077cc',
              display: 'block',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap'
            }}
            target='_blank'
            rel='noopener noreferrer'
            href={fileURL}
          >
            {existingData?.name || data?.name}
          </AdasLink>
        </AdasTypography>
      </AdasBox>
      <AdasBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '10px 10px'
        }}
      >
        {!existingData && progress && !isSuccess && (
          <AdasBox sx={{ marginBottom: '2px' }}>
            <AdasLinearProgress value={progress} />
          </AdasBox>
        )}
        <AdasTypography sx={{ fontSize: '12px', marginTop: '6px' }} color={theme.palette.text.primary}>
          {props.label}
        </AdasTypography>
        <AdasBox sx={{ display: 'flex', alignItems: 'center', marginTop: '8px', justifyContent: 'space-between' }}>
          {' '}
          {/* Flexbox container */}
          <AdasTypography sx={{ alignSelf: 'flex-start', fontSize: '12px' }} color={theme.palette.text.secondary}>
            {(displayData.size / BYTES_IN_MB).toFixed(2)} MB
          </AdasTypography>
          {!!progress && !isSuccess && (
            <AdasBox sx={{ display: 'flex' }}>
              <AdasTypography sx={{ fontSize: '12px' }} color={theme.palette.text.secondary}>
                {formatTime(timeLeft)}
              </AdasTypography>
              <AdasTypography sx={{ fontSize: '12px', marginLeft: '10px' }} color={theme.palette.text.secondary}>
                {progress}%
              </AdasTypography>
            </AdasBox>
          )}
        </AdasBox>
      </AdasBox>
    </AdasBox>
  )
}

FileUpload.propTypes = {
  typeLabel: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  workOrderData: PropTypes.object,
  existingData: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  removeFile: PropTypes.func,
  additionalDocument: PropTypes.object,
  isClientInvoice: PropTypes.bool,
  disabled: PropTypes.bool
}
