import { useAuthStore } from '@caradasstore/AuthStore'
import { useCenterStore } from '@caradasstore/CenterStore'
import { useLoadingStore } from '@caradasstore/LoadingStore'
import { useUserStore } from '@caradasstore/UserStore'
import { CaradasPlusLogoWrapper } from '@components/logo-wrapper'
import {
  AdasBox,
  AdasButton,
  AdasContainer,
  AdasInputAdornment,
  AdasLink,
  AdasPaper,
  AdasTextField,
  AdasTypography
} from '@components/wrapper-components'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useTheme } from '@mui/material'
import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { getUserList, requestAuth, requestPasswordReset } from '../../api/api'
import { Logger } from '../../logger'
import { useLocation } from 'react-router-dom'

export const AuthForm = () => {
  const theme = useTheme()
  const history = useHistory()
  const emailInputRef = useRef()
  const passwordInputRef = useRef()
  const location = useLocation()
  const { from } = location.state || { from: { pathname: '/' } }
  const setCcId = useCenterStore((store) => store.setCcId)
  const currentUser = useUserStore((store) => store.currentUser)
  const setCurrentUser = useUserStore((store) => store.setCurrentUser)
  const setLoading = useLoadingStore((store) => store.setLoading)

  const authCtx = useAuthStore()
  const [isLogin, setIsLogin] = useState(true)
  const [isEmailError, setIsEmailError] = useState(false)
  const [isPasswordError, setIsPasswordError] = useState(false)
  const [isLoginFailed, setIsLoginFailed] = useState(false)
  const [isPasswordResetSuccess, setIsPasswordResetSuccess] = useState(false)
  const [isPasswordResetFailed, setIsPasswordResetFailed] = useState(false)
  const [showPassword, setShowPassword] = React.useState(false)

  const setInitialState = () => {
    setIsEmailError(() => false)
    setIsPasswordResetSuccess(() => false)
    setIsPasswordResetFailed(() => false)
    setIsLoginFailed(() => false)
  }

  const switchAuthModeHandler = () => {
    setIsLogin((prevState) => !prevState)
    setInitialState()
    emailInputRef.current.value = ''
  }

  ///Start
  //Remove below method after BackEnd Deployment / enhancement its an extra implementation to fetch userlist data.
  const fetchUserList = async (ccId, userEmail) => {
    try {
      const response = await getUserList({ ccid: ccId })
      if (response?.status === 200) {
        const user = response?.data?.find((item) => item.email?.toLowerCase() === userEmail?.toLowerCase())
        if (user) {
          setCurrentUser(user)
        }
      }
    } catch (error) {
      Logger.error({ message: error, payload: { ccId, path: 'AuthForm/fetchUserList' } })
    }
  }
  ///End

  const submitHandler = (event) => {
    event.preventDefault()
    setInitialState()

    if (isLogin) {
      const enteredEmail = emailInputRef.current.value
      const enteredPassword = passwordInputRef.current.value

      if (!emailInputRef.current.validity.valid) {
        setIsEmailError(() => true)
      } else {
        setIsEmailError(() => false)
        if (!passwordInputRef.current.validity.valid) {
          setIsPasswordError(() => true)
        }
      }

      if (emailInputRef.current.validity.valid && passwordInputRef.current.validity.valid) {
        setIsPasswordError(() => false)
        const params = {
          email: enteredEmail,
          password: enteredPassword
        }

        const authResponse = requestAuth(params)
        authResponse
          .then((response) => {
            if (response && response?.status === 200) {
              setLoading(false)
              setIsLoginFailed(() => false)
              setCcId(response.data?.ccid)
              authCtx.login(response.data?.token, response.data?.refreshToken)
              fetchUserList(response.data?.ccid, emailInputRef.current.value)
                .then(() => {
                  history.replace(from)
                })
                .catch(() => {
                  setIsLoginFailed(() => true)
                })
            } else {
              setIsLoginFailed(() => true)
            }
          })
          .catch(() => {
            setIsLoginFailed(() => true)
          })
      }
    } else {
      if (!emailInputRef.current.validity.valid) {
        setIsEmailError(() => true)
      } else {
        setIsEmailError(() => false)
        const enteredEmail = emailInputRef.current.value
        const requestPasswordResetResponse = requestPasswordReset({
          email: enteredEmail
        })
        requestPasswordResetResponse
          .then((response) => {
            if (response.status === 200) {
              setIsPasswordResetSuccess(() => true)
              setIsPasswordResetFailed(() => false)
            } else {
              setIsPasswordResetSuccess(() => false)
              setIsPasswordResetFailed(() => true)
            }
          })
          .catch(() => {
            setIsPasswordResetSuccess(() => false)
            setIsPasswordResetFailed(() => true)
          })
      }
    }
  }
  return (
    <AdasBox
      sx={{
        display: 'flex',
        flexGrow: 1,
        overflow: 'auto',
        bgcolor: `${theme.palette.background.lightGray}`,
        height: `${theme.palette.fullScreenHeight}`
      }}
    >
      <CaradasPlusLogoWrapper>
        <AdasContainer maxWidth='lg' sx={{ padding: '16px' }}>
          <AdasPaper elevation={0} sx={{ padding: '20px' }}>
            <AdasBox>
              <AdasTypography variant='h5' paddingBottom='1rem'>
                {isLogin ? 'Sign In' : 'Forgot Password'}
              </AdasTypography>
              {!isPasswordResetSuccess ? (
                <form onSubmit={submitHandler}>
                  <AdasTextField
                    type='email'
                    id='email'
                    label='Email'
                    required
                    sx={{ width: '100%', paddingBottom: '1rem' }}
                    inputRef={emailInputRef}
                    error={isEmailError}
                    helperText={isEmailError ? 'Invalid email address' : ''}
                    placeholder='Email'
                  />
                  {isLogin ? (
                    <AdasTextField
                      placeholder='Password'
                      type={showPassword ? 'text' : 'password'}
                      id='password'
                      label='Password'
                      minLength={isLogin ? '0' : '6'}
                      required
                      inputRef={passwordInputRef}
                      error={isPasswordError}
                      helperText={isPasswordError ? 'Password required' : ''}
                      sx={{ width: '100%', paddingBottom: '1rem' }}
                      InputProps={{
                        endAdornment: (
                          <AdasInputAdornment position='end'>
                            <AdasButton
                              buttonType='iconButton'
                              aria-label='toggle password visibility'
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={(evt) => evt.preventDefault()}
                              edge='end'
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </AdasButton>
                          </AdasInputAdornment>
                        )
                      }}
                    />
                  ) : null}

                  <AdasBox sx={{ display: 'flex', flexDirection: 'column-reverse' }}>
                    <AdasBox>
                      <AdasButton
                        variant='contained'
                        aria-label='login'
                        color='primary'
                        type='submit'
                        sx={{ width: '100%', marginTop: '1rem' }}
                        onClick={submitHandler}
                      >
                        {isLogin ? 'Sign In' : 'Send Reset Link'}
                      </AdasButton>
                    </AdasBox>
                    <AdasBox>
                      <AdasLink
                        href='#'
                        onClick={switchAuthModeHandler}
                        variant='body2'
                        tabIndex={0}
                        underline='none'
                        sx={{ display: 'grid', justifyItems: 'start' }}
                      >
                        <AdasTypography>{isLogin ? 'Forgot password?' : 'Back to Sign In'}</AdasTypography>
                      </AdasLink>
                    </AdasBox>
                  </AdasBox>
                  <AdasBox
                    sx={{
                      color: theme.palette.error.main,
                      marginTop: '10px'
                    }}
                  >
                    {isLoginFailed && (
                      <AdasTypography align='center'>
                        Email and Password do not match. Please try again or select &quot;Forgot password?&quot;.
                      </AdasTypography>
                    )}
                    {isPasswordResetFailed && (
                      <AdasTypography align='center'>Password reset request failed. Please try again.</AdasTypography>
                    )}
                  </AdasBox>
                </form>
              ) : (
                <AdasBox sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <AdasTypography>
                    Your password reset request has been sent to{' '}
                    <AdasTypography component='span' sx={{ fontWeight: theme.typography.fontWeightBold }}>
                      {currentUser?.userEmail}
                    </AdasTypography>
                  </AdasTypography>
                  <AdasLink
                    href='#'
                    onClick={switchAuthModeHandler}
                    variant='body2'
                    tabIndex={0}
                    underline='none'
                    sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingTop: theme.padding.smallPadding }}
                  >
                    <AdasTypography>Back to Sign In</AdasTypography>
                  </AdasLink>
                </AdasBox>
              )}
            </AdasBox>
          </AdasPaper>
        </AdasContainer>
      </CaradasPlusLogoWrapper>
    </AdasBox>
  )
}
