import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Note } from '@components/note'
import { StickyFooter } from '@components/sticky-footer'
import { MultiPhotoSection } from '@components/photo'
import { FileUploadLongCard } from '@components/file-upload'
import { IdDisplay } from '@components/id-display'
import {
  AdasBox,
  AdasButton,
  AdasCheckbox,
  AdasContainer,
  AdasFormControlLabel,
  AdasFormGroup,
  AdasPaper,
  AdasTypography
} from '@components/wrapper-components'
import {
  getCalibrationChecklist,
  getPhotosSummary,
  getInvoiceByReferenceNumber,
  getWorkOrderByReferenceNumber,
  submitCalibrationChecklist,
  updateNote
} from '../../../api/api'
import { FILE_UPLOAD, GENERIC_MESSAGES, PARAM_NAMES, PHOTO_LABELS, REDIRECT_URLS } from '../../../constants'
import { FieldNames, OperationType, StatusEnum } from '../../../enums/enums'
import { useScrollTop } from '../../../utils/useScrollTop'
import { Logger } from './../../../logger'
import { useLoadingStore } from '@caradasstore/LoadingStore'

export const CalibrationPage = (props) => {
  useScrollTop()
  const { [PARAM_NAMES.REFERENCE_NUMBER]: referenceNumber } = useParams()
  const { fromInvoice = false } = props
  const [dataUrls, setDataUrls] = useState({
    calibrationReport: ''
  })
  const [labelList, setLabelList] = useState([])
  const [calInputValues, setCalInputValues] = useState({
    is_tools_autel: false,
    is_tools_airpro: false,
    is_tools_astech: false,
    is_tools_hunter: false,
    is_tools_oem: false,
    is_tools_opus: false,
    is_tools_snapon: false,
    is_tools_texa: false
  })
  const history = useHistory()
  const setLoading = useLoadingStore((store) => store.setLoading)
  const [workOrderData, setWorkOrderData] = useState({})
  const [additionalPhotos, setAdditionalPhotos] = useState([])
  const [frontCameraPhotos, setFrontCameraPhotos] = useState([])
  const [rearCameraPhotos, setRearCameraPhotos] = useState([])
  const [surroundViewPhotos, setSurroundViewPhotos] = useState([])
  const [blindSpotPhotos, setBlindSpotPhotos] = useState([])
  const [frontRadarCameraPhotos, setFrontRadarCameraPhotos] = useState([])
  const [vehicleName, setVehicleName] = useState(null)
  const [orderClosed, setOrderClosed] = useState(false)
  const [notes, setNotes] = useState({})

  const redirectToForbiddenPage = () => {
    history.replace(REDIRECT_URLS.FORBIDDEN_PAGE)
  }

  const requestPhotoSummary = useCallback(async (params) => {
    const response = await getPhotosSummary(params)
    if (response.status === 200) {
      const photoSummaryData = response.data
      setAdditionalPhotos(photoSummaryData.additionalPhotos)
      setFrontCameraPhotos(photoSummaryData.frontCameraPhotos)
      setBlindSpotPhotos(photoSummaryData.blindSpotMonitoringPhotos)
      setSurroundViewPhotos(photoSummaryData.surroundViewCameraPhotos)
      setRearCameraPhotos(photoSummaryData.rearCameraPhotos)
      setFrontRadarCameraPhotos(photoSummaryData.frontRadarCameraPhotos)
    }
  }, [])

  // at least one of these must be checked
  const isSubmitDisabled =
    fromInvoice ||
    orderClosed ||
    [
      calInputValues.is_tools_autel,
      calInputValues.is_tools_airpro,
      calInputValues.is_tools_astech,
      calInputValues.is_tools_hunter,
      calInputValues.is_tools_oem,
      calInputValues.is_tools_opus,
      calInputValues.is_tools_snapon,
      calInputValues.is_tools_texa
    ].every((value) => !value)

  const handleFormInputChange = (e) => {
    const value = e.target.checked
    setCalInputValues((prevState) => {
      const updatedState = {}

      Object.keys(prevState).forEach((property) => {
        if (property !== 'additionalCalPhoto' && property !== 'calibrationReport') {
          updatedState[property] = property === e.target.name ? value : false
        }
      })

      return updatedState
    })
  }

  const classes = {
    subHeading: {
      fontStyle: 'normal',
      fontSize: '14px',
      lineHeight: '266%',
      fontWeight: '700',
      letterSpacing: '1px',
      textTransform: 'uppercase',
      color: 'rgba(26, 27, 31, 0.87)',
      padding: '10px 0'
    }
  }

  const saveNote = useCallback(
    async (data) => {
      const response = await updateNote({ type: 'workorders', id: workOrderData.id, data })
      if (response.status === 200 || response.status === 201) {
        setNotes(data.note)
      }
    },
    [workOrderData.id]
  )

  const removeFileHandler = useCallback((fileName) => {
    if (fileName) {
      setDataUrls((prevState) => {
        const dataUrls = {
          ...prevState,
          [fileName]: null
        }
        return dataUrls
      })
    }
  }, [])

  const saveCalibration = useCallback(
    async (complete) => {
      const calDataSubmit = {
        ...calInputValues,
        is_calibration_step_complete: complete
      }

      const params = {
        data: calDataSubmit,
        workorderId: workOrderData.id
      }

      return submitCalibrationChecklist(params)
    },
    [calInputValues, workOrderData.id]
  )

  const returnToSummaryHandler = useCallback(async () => {
    if (!workOrderData.is_calibration_step_complete) {
      await saveCalibration(false)
    }

    history.goBack()
  }, [fromInvoice, history, saveCalibration, workOrderData])

  const submit = useCallback(async () => {
    const response = await saveCalibration(true)
    if (response.status === 200 || response.status === 201) {
      history.goBack()
    }
  }, [fromInvoice, history, saveCalibration, workOrderData])

  const requestCalibration = useCallback(async (params) => {
    const response = await getCalibrationChecklist(params)
    let existingCalibrationValues = {}
    if (response.status === 200) {
      const existingCalibration = response.data
      Object.keys(existingCalibration).forEach((key) => {
        if (existingCalibration[key]) {
          existingCalibrationValues[key] = existingCalibration[key]
        }
      })

      if (existingCalibrationValues) {
        setCalInputValues((preValues) => {
          const values = {
            ...preValues,
            ...existingCalibrationValues
          }
          return values
        })
      }
      if (existingCalibration.calibrationReport != null) {
        setDataUrls((prevState) => {
          const imageUrls = {
            ...prevState,
            calibrationReport: existingCalibration.calibrationReport
          }
          return imageUrls
        })
      }
    }
  }, [])

  const createDefaultLabelList = useCallback(() => {
    let labelList = []
    if (workOrderData.calibrations?.length > 0) {
      const calibrationLabels = workOrderData.calibrations.map((item) => item.calibration.name)
      labelList = [...calibrationLabels]
    }

    if (workOrderData.services?.length > 0) {
      const serviceLabels = workOrderData.services.map((item) => item.service.name)
      labelList = [...labelList, ...serviceLabels]
    }

    if (workOrderData.customItems?.length > 0) {
      const customItemsLabels = workOrderData.customItems.map((item) => item.name)
      labelList = [...labelList, ...customItemsLabels]
    }

    setLabelList((prev) => {
      const updated = Array.from(new Set([...prev, ...labelList]))
      return updated
    })
  }, [workOrderData.calibrations, workOrderData.services, workOrderData.customItems])

  const refresh = useCallback(
    (refreshedData) => {
      if (refreshedData) {
        const multiplePhotosFields = [
          FieldNames.ADDITIONAL_PHOTOS,
          FieldNames.FRONT_CAMERA_PHOTOS,
          FieldNames.REAR_CAMERA_PHOTOS,
          FieldNames.SURROUND_VIEW_PHOTOS,
          FieldNames.BLIND_SPOT_PHOTOS,
          'additionalCalPhoto'
        ]

        const propertyName = refreshedData.imageLocation
        if (!multiplePhotosFields.includes(propertyName)) {
          if (refreshedData.operation === OperationType.ROTATE) {
            setDataUrls((prevState) => ({
              ...prevState,
              [propertyName]: {
                ...prevState[propertyName],
                img_rotation: refreshedData.img_rotation
              }
            }))
          }
          if (refreshedData.operation === OperationType.DELETE) {
            setDataUrls((prevState) => ({
              ...prevState,
              [propertyName]: null
            }))
          }
          if (refreshedData.operation === OperationType.RETAKE) {
            const imageItemDetail = {
              img_rotation: refreshedData.img_rotation,
              id: refreshedData.attachmentId
            }

            setDataUrls((prevState) => ({
              ...prevState,
              [propertyName]: imageItemDetail
            }))
          }
        }
      } else {
        const params = {
          workorderId: workOrderData.id
        }
        requestPhotoSummary(params)
        requestCalibration(params)
        createDefaultLabelList()
      }
    },
    [requestCalibration, requestPhotoSummary, createDefaultLabelList, workOrderData.id]
  )

  useEffect(() => {
    workOrderData?.id && refresh()
  }, [refresh, requestPhotoSummary, workOrderData?.id])

  useEffect(() => {
    const fetchWO = async () => {
      try {
        setLoading(true)
        if (fromInvoice) {
          const invoiceResponse = await getInvoiceByReferenceNumber({ referenceNumber })
          if (invoiceResponse?.status === 200) {
            if (!invoiceResponse.data?.invoiceDto) {
              redirectToForbiddenPage()
              return
            }
            const data = invoiceResponse.data
            const wo = data.workOrderDto
            const invoiceDto = data.invoiceDto
            invoiceDto.safetySystemsItems = data.safetySystemsItems
            invoiceDto.calibrations = data.workOrderCalibrations
            invoiceDto.services = data.workOrderServices
            invoiceDto.customItems = data.customItems

            invoiceDto.id = wo.id //overridding invoice id with Workorder Id
            setWorkOrderData(invoiceDto)
            setNotes({ notes_external: wo.notes_external, notes_internal: wo.notes_internal })
            setVehicleName(`${wo.vehicle?.make} ${wo.vehicle.model} ${wo.vehicle.trim}`)
            setOrderClosed(StatusEnum[invoiceDto.status] === StatusEnum.CLOSED)
          } else {
            //Given referenceNumber doesnot exist or no permission granted
            redirectToForbiddenPage()
            return
          }
        } else {
          const response = await getWorkOrderByReferenceNumber({ referenceNumber })
          if (response?.status === 200) {
            //Url Tampared i.e invoice/quote record tried to open in workorder path
            if (StatusEnum[response.data?.workOrder?.status] === StatusEnum.MOVED) {
              redirectToForbiddenPage()
              return
            }
            const wo = response.data.workOrder
            wo.calibrations = response.data.workOrderCalibrations
            wo.services = response.data.workOrderServices
            wo.customItems = response.data.customItems
            setWorkOrderData(wo)
            setNotes({ notes_external: wo.notes_external, notes_internal: wo.notes_internal })
            setVehicleName(`${wo.vehicle.make} ${wo.vehicle.model} ${wo.vehicle.trim}`)
            setOrderClosed(StatusEnum[wo.status] === StatusEnum.CLOSED)
          } else {
            //Given referenceNumber doesnot exist or no permission granted
            redirectToForbiddenPage()
            return
          }
        }
      } catch (error) {
        Logger.error({ message: error, payload: { path: 'calibrationPage/fetchWo', workOrderId: workOrderData.id } })
      } finally {
        setLoading(false)
      }
    }
    if (referenceNumber) fetchWO()
  }, [referenceNumber])

  return (
    <AdasContainer maxWidth='lg'>
      {workOrderData.id && (
        <AdasPaper elevation={0} sx={{ padding: '20px' }}>
          <AdasBox sx={{ display: 'flow-root' }}>
            <AdasTypography component='span'>
              <AdasTypography component='span' sx={{ float: 'left' }}>
                <IdDisplay
                  itemId={workOrderData.reference_number}
                  roNumber={workOrderData.repair_order_num}
                  itemType={fromInvoice ? 'Invoice' : 'WO'}
                />
              </AdasTypography>
              <AdasTypography component='span' sx={{ float: 'right' }}>
                <AdasTypography component='span'>{vehicleName}</AdasTypography>
              </AdasTypography>
            </AdasTypography>
          </AdasBox>
          <AdasBox>
            <h2>Calibration Documentation</h2>
          </AdasBox>
          <MultiPhotoSection
            title={PHOTO_LABELS.FRONT_CAMERA}
            addText={PHOTO_LABELS.ADD_PHOTO}
            photoInfoArray={frontCameraPhotos}
            workOrderId={workOrderData.id}
            refresh={refresh}
            disabled={orderClosed || fromInvoice}
            imageLocation={FieldNames.FRONT_CAMERA_PHOTOS}
            labelList={labelList}
            accordionExpandedDefault={false}
            isMultiUpload={true}
            maxPhotos={16}
          />
          <MultiPhotoSection
            title={PHOTO_LABELS.REAR_CAMERA}
            addText={PHOTO_LABELS.ADD_PHOTO}
            photoInfoArray={rearCameraPhotos}
            workOrderId={workOrderData.id}
            refresh={refresh}
            disabled={orderClosed || fromInvoice}
            imageLocation={FieldNames.REAR_CAMERA_PHOTOS}
            labelList={labelList}
            accordionExpandedDefault={false}
            isMultiUpload={true}
            maxPhotos={16}
          />
          <MultiPhotoSection
            title={PHOTO_LABELS.SURROUND_VIEW_CAMERA}
            addText={PHOTO_LABELS.ADD_PHOTO}
            photoInfoArray={surroundViewPhotos}
            workOrderId={workOrderData.id}
            refresh={refresh}
            disabled={orderClosed || fromInvoice}
            imageLocation={FieldNames.SURROUND_VIEW_PHOTOS}
            labelList={labelList}
            accordionExpandedDefault={false}
            isMultiUpload={true}
            maxPhotos={24}
          />
          <MultiPhotoSection
            title={PHOTO_LABELS.BLIND_SPOT_MONITORING}
            addText={PHOTO_LABELS.ADD_PHOTO}
            photoInfoArray={blindSpotPhotos}
            workOrderId={workOrderData.id}
            refresh={refresh}
            disabled={orderClosed || fromInvoice}
            imageLocation={FieldNames.BLIND_SPOT_PHOTOS}
            labelList={labelList}
            accordionExpandedDefault={false}
            isMultiUpload={true}
            maxPhotos={16}
          />
          <MultiPhotoSection
            title={PHOTO_LABELS.FRONT_RADAR}
            addText={PHOTO_LABELS.ADD_PHOTO}
            photoInfoArray={frontRadarCameraPhotos}
            workOrderId={workOrderData.id}
            refresh={refresh}
            disabled={orderClosed || fromInvoice}
            imageLocation={FieldNames.FRONT_RADAR_CAMERA_PHOTOS}
            labelList={labelList}
            accordionExpandedDefault={false}
            isMultiUpload={true}
            maxPhotos={16}
          />
          <MultiPhotoSection
            title={PHOTO_LABELS.ADDITIONAL_PHOTOS}
            addText={PHOTO_LABELS.CALIBRATION_SERVICE_PHOTOS}
            photoInfoArray={additionalPhotos}
            workOrderId={workOrderData.id}
            refresh={refresh}
            disabled={orderClosed || fromInvoice}
            imageLocation={FieldNames.ADDITIONAL_PHOTOS}
            labelList={labelList}
            accordionExpandedDefault={false}
            isMultiUpload={true}
            maxPhotos={24}
          />

          <AdasBox sx={{ marginTop: '20px', marginBottom: '20px' }}>
            <Note
              existingExternalNote={notes.notes_external}
              existingInternalNote={notes.notes_internal}
              submit={(props) => saveNote(props)}
              allowEdit={!orderClosed}
              noBottomBorder={true}
            />
          </AdasBox>
          <AdasBox>
            <h4>Report and Tool Use</h4>
          </AdasBox>
          <AdasBox>
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                background: '#FDFBFF',
                marginBottom: '20px'
              }}
            >
              <AdasTypography sx={{ paddingBottom: '0px', ...classes.subHeading }}>UPLOAD CALIBRATION REPORT</AdasTypography>
              <FileUploadLongCard
                removeFile={() => removeFileHandler('calibrationReport')}
                existingData={dataUrls.calibrationReport}
                workOrderData={workOrderData}
                title={FILE_UPLOAD.TITLE.UPLOAD_CALIBRATION_REPORT}
                typeLabel={FILE_UPLOAD.TYPE_LABEL.CALIBRATION_REPORT}
                disabled={orderClosed || fromInvoice}
              />
            </AdasPaper>
          </AdasBox>
          <AdasBox>
            <AdasPaper
              variant='outlined'
              sx={{
                padding: '0px 20px',
                background: '#FDFBFF',
                marginBottom: '20px'
              }}
            >
              <AdasTypography sx={classes.subHeading}>{GENERIC_MESSAGES.PRIMARY_DIAGNOSTIC_TOOL} *</AdasTypography>
              <AdasFormGroup>
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={calInputValues.is_tools_autel}
                      onChange={handleFormInputChange}
                      name='is_tools_autel'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Autel'
                />
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={calInputValues.is_tools_airpro}
                      onChange={handleFormInputChange}
                      name='is_tools_airpro'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='AirPro'
                />
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={calInputValues.is_tools_astech}
                      onChange={handleFormInputChange}
                      name='is_tools_astech'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='asTech'
                />
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={calInputValues.is_tools_hunter}
                      onChange={handleFormInputChange}
                      name='is_tools_hunter'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Hunter'
                />
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={calInputValues.is_tools_oem}
                      onChange={handleFormInputChange}
                      name='is_tools_oem'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='OEM'
                />
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={calInputValues.is_tools_opus}
                      onChange={handleFormInputChange}
                      name='is_tools_opus'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Opus'
                />
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={calInputValues.is_tools_snapon}
                      onChange={handleFormInputChange}
                      name='is_tools_snapon'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='Snap-on'
                />
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={calInputValues.is_tools_texa}
                      onChange={handleFormInputChange}
                      name='is_tools_texa'
                      disabled={orderClosed || fromInvoice}
                    />
                  }
                  label='TEXA'
                />
              </AdasFormGroup>
            </AdasPaper>
          </AdasBox>
          <StickyFooter>
            <AdasBox>
              <AdasButton variant='outlined' aria-label='return to Summary' color='primary' onClick={returnToSummaryHandler}>
                {orderClosed || fromInvoice ? '' : 'SAVE & '}RETURN TO SUMMARY
              </AdasButton>
              <AdasButton
                disabled={isSubmitDisabled}
                sx={{ float: 'right' }}
                variant='contained'
                aria-label='Set up complete'
                color='primary'
                onClick={submit}
              >
                DOCUMENTATION COMPLETE
              </AdasButton>
            </AdasBox>
          </StickyFooter>
        </AdasPaper>
      )}
    </AdasContainer>
  )
}

CalibrationPage.propTypes = {
  fromInvoice: PropTypes.bool
}
