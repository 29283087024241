import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import PropTypes from 'prop-types'
import { useState } from 'react'

import { AdasBox, AdasButton, AdasCard, AdasTextField } from '@components/wrapper-components'
import { deleteContact, updateContact } from '../../../api/api'
import { phoneNumberFormat, validations } from '../../../utils/utils'
import { Logger } from '../../../logger'

export const ContactForm = (props) => {
  const { contactData, cancel, onSaveSuccess, index, deleteContactHandler, isAddContact } = props
  const [contact, setContact] = useState(contactData)
  const [touched, setTouched] = useState({
    name: false,
    email: false,
    phone: false
  })
  const [valid, setValid] = useState({
    name: validations.name(contact?.name),
    email: validations.email(contact?.email),
    phone: validations.phone(contact?.phone)
  })

  const isDisabled = !valid.name || !valid.email || !valid.phone

  const handleInputChange = (e) => {
    const value = e.target.value
    const name = e.target.name
    setContact((prev) => {
      const contact = {
        ...prev,
        [name]: value
      }
      return contact
    })
    setTouched((prev) => {
      return {
        ...prev,
        [name]: true
      }
    })
    setValid((prev) => {
      const valid = {
        ...prev
      }
      if (validations[name]) {
        valid[name] = validations[name](value)
      }
      return valid
    })
  }

  const handleSaveContact = async () => {
    try {
      const response = await updateContact({
        data: contact
      })

      if (response.status === 200) {
        onSaveSuccess(response.data)
      }
    } catch (error) {
      Logger.error({ message: error, payload: { file: 'ContactForm/handleSaveContact', contact } })
    }
  }

  const handleDelete = async () => {
    try {
      const response = await deleteContact({
        id: contact.id
      })

      if (response.status === 200) {
        deleteContactHandler({
          index,
          data: contact,
          isDelete: true
        })
      }
    } catch (error) {
      Logger.error({ message: error, payload: { file: 'ContactForm/handleDelete', contact } })
    }
  }

  return (
    <AdasCard
      sx={{
        margin: '17px 24px',
        padding: '16px 16px',
        backgroundColor: '#FDFBFF'
      }}
    >
      <AdasTextField
        onChange={handleInputChange}
        name='name'
        sx={{ marginBottom: '20px' }}
        id='name'
        label='Name *'
        variant='outlined'
        value={contact && contact.name ? contact.name : ''}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        error={touched.name && !valid.name}
      />
      <AdasTextField
        onChange={handleInputChange}
        name='jobTitle'
        sx={{ marginBottom: '20px' }}
        id='job'
        label='Job Title'
        variant='outlined'
        value={contact && contact.jobTitle ? contact.jobTitle : ''}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
      />
      <AdasTextField
        onChange={handleInputChange}
        name='phone'
        sx={{ marginBottom: '20px' }}
        id='phone'
        label='Phone *'
        variant='outlined'
        value={contact && contact.phone ? phoneNumberFormat(contact.phone) : ''}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        error={touched.phone && !valid.phone}
      />
      <AdasTextField
        onChange={handleInputChange}
        name='email'
        sx={{ marginBottom: '20px' }}
        id='email *'
        label='Email *'
        variant='outlined'
        value={contact && contact.email ? contact.email : ''}
        fullWidth={true}
        InputLabelProps={{
          shrink: true
        }}
        error={touched.email && !valid.email}
      />
      <AdasBox sx={{ margin: '20px 0px' }}>
        {!isAddContact ? (
          <AdasButton startIcon={<DeleteOutlinedIcon />} isDebounceEnabled={true} sx={{ color: 'red' }} onClick={handleDelete}>
            Delete
          </AdasButton>
        ) : null}
        <AdasBox sx={{ float: 'right' }}>
          <AdasButton variant='outlined' aria-label='cancel' color='primary' onClick={cancel} sx={{ marginRight: '10px' }}>
            Cancel
          </AdasButton>
          <AdasButton
            disabled={isDisabled}
            isDebounceEnabled={true}
            variant='contained'
            aria-label='save'
            color='primary'
            onClick={handleSaveContact}
          >
            Save
          </AdasButton>
        </AdasBox>
      </AdasBox>
    </AdasCard>
  )
}

ContactForm.propTypes = {
  contactData: PropTypes.object.isRequired,
  cancel: PropTypes.func.isRequired,
  onSaveSuccess: PropTypes.func.isRequired,
  index: PropTypes.number,
  deleteContactHandler: PropTypes.func.isRequired,
  isAddContact: PropTypes.bool
}
