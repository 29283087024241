import {
  AdasBox,
  AdasButton,
  AdasCheckbox,
  AdasFormGroup,
  AdasTypography,
  AdasModal,
  AdasImage,
  AdasFormControlLabel
} from '@components/wrapper-components'
import DiagnosticTree from '../../assets/images/diagnostic-tree.jpeg'
import CloseIcon from '@mui/icons-material/Close'
import './DiagnosticTreeViewer.css'
import { useCallback, useState } from 'react'

export const DiagnosticTreeViewer = ({ isOpen, closeModal, handleNextButtonClick }) => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(false)

  const handleCloseModal = useCallback(() => {
    setIsButtonEnabled(false)
    closeModal()
  }, [closeModal])

  return (
    <>
      <AdasModal
        open={isOpen}
        onClose={handleCloseModal}
        aria-labelledby='responsive-modal-title'
        aria-describedby='responsive-modal-description'
        className='modalContainer'
      >
        <AdasBox className='modalContent'>
          <AdasTypography id='responsive-modal-title' component='h2' className='title'>
            Failed Calibration Diagnostic Tree
            <AdasButton
              buttonType='iconButton'
              sx={{
                position: 'absolute',
                top: '5px',
                right: '8px',
                '@media (max-width: 600px)': {
                  fontSize: '16px'
                }
              }}
              onClick={handleCloseModal}
            >
              <CloseIcon />
            </AdasButton>
          </AdasTypography>
          <AdasBox className='imageContainer'>
            <AdasImage
              src={DiagnosticTree}
              alt='Modal'
              className='image'
              sx={{
                width: '100%'
              }}
            />
          </AdasBox>
          <AdasBox className='buttonSection'>
            <AdasBox className='buttonContainer'>
              <AdasFormGroup>
                <AdasFormControlLabel
                  control={
                    <AdasCheckbox
                      checked={isButtonEnabled}
                      onChange={() => setIsButtonEnabled(!isButtonEnabled)}
                      name='reviewDiagnosticTree'
                    />
                  }
                  label='Review the Failed Calibration Diagnostic Tree'
                />
              </AdasFormGroup>
            </AdasBox>
            <AdasBox className='doneButtonContainer'>
              <AdasButton variant='outlined' color='primary' aria-label='goback' sx={{ height: '35px' }} onClick={handleCloseModal}>
                GO BACK
              </AdasButton>
              <AdasButton
                variant='contained'
                color='primary'
                disabled={!isButtonEnabled}
                onClick={handleNextButtonClick}
                sx={{ height: '35px' }}
              >
                NEXT
              </AdasButton>
            </AdasBox>
          </AdasBox>
        </AdasBox>
      </AdasModal>
    </>
  )
}
