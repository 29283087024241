import CloseIcon from '@mui/icons-material/Close'
import LockResetIcon from '@mui/icons-material/LockReset'
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import PropTypes from 'prop-types'
import { useState } from 'react'

import { useUserStore } from '@caradasstore/UserStore'
import { CaradasAvatar, PasswordForm, PhoneNumberForm } from '@components/user'
import { AdasBox, AdasButton, AdasCard, AdasDivider, AdasTypography } from '@components/wrapper-components'
import { phoneNumberFormat } from '../../../../utils/utils'

const userPrivilegeStatus = (user) => {
  if (user.is_admin) return 'Admin'
  if (user.is_owner) return 'Owner'
  if (user.is_manager) return 'Manager'
  return 'Tech'
}

export const UserProfile = ({ user, closeDrawer }) => {
  const [updatedUser, setUpdatedUser] = useState(user)
  const [isEditPassword, setIsEditPassword] = useState(false)
  const [isEditPhone, setIsEditPhone] = useState(false)
  const setCurrentUser = useUserStore((store) => store.setCurrentUser)

  const labelStyle = {
    margin: '.5rem 0 0 0',
    fontWeight: '600'
  }

  const onSaveUserSuccessHandler = (props) => {
    setUpdatedUser(props)
    setCurrentUser(props)
    setIsEditPassword(false)
  }

  return (
    <AdasBox sx={{ width: '400px' }}>
      <AdasBox sx={{ margin: '25px 24px' }}>
        <AdasTypography component='span' sx={{ textTransform: 'capitalize' }}>
          User Profile
        </AdasTypography>
        <CloseIcon onClick={closeDrawer} sx={{ float: 'right' }} fontSize='small'></CloseIcon>
      </AdasBox>
      <AdasCard
        sx={{
          margin: '17px 24px',
          padding: '16px 16px',
          backgroundColor: '#FDFBFF'
        }}
      >
        <AdasBox sx={{ display: 'flex', alignItems: 'center' }}>
          <CaradasAvatar user={user} />
          <AdasBox sx={{ marginLeft: '1rem', marginTop: '-1rem' }}>
            <AdasTypography sx={{ fontWeight: '600', marginTop: '1rem' }} variant='h6'>
              {updatedUser?.name}
            </AdasTypography>
            <AdasTypography variant='body2'>{userPrivilegeStatus(user)}</AdasTypography>
          </AdasBox>
        </AdasBox>
        <AdasTypography sx={labelStyle} variant='subtitle2'>
          Email
        </AdasTypography>
        <AdasTypography>{updatedUser?.email}</AdasTypography>
        {isEditPhone ? (
          <PhoneNumberForm
            user={updatedUser}
            open={isEditPhone}
            close={() => setIsEditPhone(false)}
            onSaveSuccess={onSaveUserSuccessHandler}
          />
        ) : (
          <AdasBox
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '1rem'
            }}
          >
            <AdasBox>
              <AdasTypography sx={labelStyle} variant='subtitle2'>
                Phone Number
              </AdasTypography>
              <AdasTypography>{phoneNumberFormat(updatedUser.phone)}</AdasTypography>
            </AdasBox>
            <AdasButton
              sx={{ alignSelf: 'flex-end' }}
              startIcon={<ModeEditOutlineOutlinedIcon />}
              key='2'
              aria-label='Cancel'
              color='primary'
              onClick={() => setIsEditPhone(true)}
            >
              Edit
            </AdasButton>
          </AdasBox>
        )}
        <AdasDivider sx={{ margin: '1rem 0' }} />

        {isEditPassword ? (
          <PasswordForm user={updatedUser} close={() => setIsEditPassword(false)} onSaveSuccess={() => {}} />
        ) : (
          <AdasBox sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <AdasBox>
              <AdasTypography sx={labelStyle} variant='subtitle2'>
                Password
              </AdasTypography>
              <AdasTypography>**************</AdasTypography>
            </AdasBox>
            <AdasButton
              sx={{ alignSelf: 'flex-end' }}
              startIcon={<LockResetIcon />}
              key='2'
              aria-label='Cancel'
              color='primary'
              onClick={() => setIsEditPassword(true)}
            >
              Reset
            </AdasButton>
          </AdasBox>
        )}
      </AdasCard>
    </AdasBox>
  )
}

UserProfile.propTypes = { user: PropTypes.object, closeDrawer: PropTypes.func.isRequired }
