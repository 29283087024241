import { useRef, useState } from 'react'
import { CircularProgress } from '@mui/material'
import PropTypes from 'prop-types'
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'

import { useCenterStore } from '@caradasstore/CenterStore'
import { useMessageStore } from '@caradasstore/MessageStore'
import { VinScannerModal } from '@components/vin'
import { AdasBox, AdasButton, AdasDivider, AdasInputAdornment, AdasTextField } from '@components/wrapper-components'
import { requestVehicleInfo, requestVinDataForQuotesWOInvoicesByVin } from '../../../api/api'
import { validations } from '../../../utils/utils'
import classes from '../shared/VehicleInfo.module.css'

export const VinInput = (props) => {
  const { updateVehicleInfo, updateVINSearchList, shouldFetchOnlyVinList = false, prepopulateVin } = props
  const clearAllMessages = useMessageStore((store) => store.clearAllMessages)
  const ccId = useCenterStore((store) => store.ccId)
  const [open, setOpen] = useState(false)
  const [valid, setValid] = useState({ vin: true })
  const [touched, setTouched] = useState({ vin: false })
  const [loading, setLoading] = useState(false)
  const [formInputValues, setFormInputValues] = useState({
    vin: prepopulateVin || ''
  })

  const vinManualInputHandler = (e) => {
    const value = e.target.value
    setFormInputValues((prev) => {
      const updated = {
        ...prev,
        vin: value
      }
      return updated
    })
    setValid({ vin: validations.vin(value) })
    setTouched({ vin: true })
  }

  const manualRef = useRef()
  const vinRef = useRef()

  const vinClickHandler = () => {
    setOpen(true)
  }

  const closeModalFunc = () => {
    setOpen(false)
    manualRef.current.focus()
  }

  const vinSuccessHandler = (props) => {
    updateVehicleInfo(props)
    setFormInputValues((prevValues) => {
      const updatedValues = {
        ...prevValues,
        vin: props.vin
      }
      return updatedValues
    })
    setValid({ vin: validations.vin(props.vin) })
    setOpen(false)
  }

  const populateVehicleInfo = (vin) => {
    if (shouldFetchOnlyVinList) {
      setFormInputValues((prev) => {
        const updated = {
          ...prev,
          vin
        }
        return updated
      })

      setValid({ vin: validations.vin(vin) })
      fetchVinList(vin)
      return
    } else {
      setLoading(true)
      const vehicleInfoRequest = requestVehicleInfo({
        vin: vin
      })
      vehicleInfoRequest
        .then((response) => {
          if (response && response.status === 200) {
            const { color_ext, doors, engine, make, model, vehicle_type, year, trim, image_url, safetySystems, fuel_capacity_gal } =
              response.data

            const vehicle = {
              year: year,
              make: make,
              model: model,
              color: color_ext,
              type: vehicle_type,
              engine: engine,
              doors: doors,
              trim: trim,
              imageUrl: image_url,
              safetySystems: safetySystems,
              fuel: fuel_capacity_gal
            }

            vinSuccessHandler({
              data: vehicle,
              vin
            })
          }
        })
        .catch((err) => {
          clearAllMessages()
          setValid({ vin: false })
          setTouched({ vin: true })
          console.log('ERROR ', err.message)
        })
        .finally(() => setLoading(false))
    }
  }

  const fetchVinList = (vin) => {
    setLoading(true)
    const vehicleInfoRequest = requestVinDataForQuotesWOInvoicesByVin({
      vin: vin,
      ccid: ccId
    })
    vehicleInfoRequest
      .then((response) => {
        if (response && response.status === 200) {
          updateVINSearchList({
            vin: vin,
            vinList: response.data
          })
        }
      })
      .catch((err) => {
        console.log('ERROR ', err.message)
      })
      .finally(() => setLoading(false))
  }

  return (
    <AdasBox>
      <AdasTextField
        fullWidth
        key={'vin'}
        id='outlined-basic'
        sx={{
          paddingTop: '5px'
        }}
        label='VIN'
        variant='outlined'
        onChange={vinManualInputHandler}
        className={classes.formField}
        name='vin'
        value={formInputValues.vin}
        inputRef={manualRef}
        innerRef={vinRef}
        error={!valid.vin && touched.vin}
        inputProps={{
          maxLength: 17
        }}
        helperText={!valid.vin && touched.vin && formInputValues.vin.length === 17 ? 'VIN is not valid, please try again.' : ''}
        InputProps={{
          endAdornment: (
            <AdasInputAdornment position='start' sx={{ color: '#0b7ef3' }}>
              <AdasButton
                onClick={() => populateVehicleInfo(formInputValues.vin)}
                disabled={formInputValues.vin.length !== 17 || loading}
                variant='link'
                sx={{ textTransform: 'none' }}
              >
                {loading ? <CircularProgress size={'1.5rem'} /> : 'Find VIN'}
              </AdasButton>
              <AdasDivider orientation='vertical' flexItem />
              <DocumentScannerIcon onClick={vinClickHandler} /> Scan
            </AdasInputAdornment>
          )
        }}
      />
      {open && <VinScannerModal open={open} onClose={closeModalFunc} setOpen={setOpen} vinSuccessHandler={populateVehicleInfo} />}
    </AdasBox>
  )
}

VinInput.propTypes = {
  updateVehicleInfo: PropTypes.func,
  updateVINSearchList: PropTypes.func,
  shouldFetchOnlyVinList: PropTypes.bool,
  prepopulateVin: PropTypes.string
}
