import React from 'react'
import { domToReact } from 'html-react-parser'
import dayjs from 'dayjs'
import { ROLES_HIERARCHY } from '../constants'
import { downloadOEMStatementPDF } from 'api/api'
import { Logger } from '../logger'

export const phoneNumberFormat = (input) => {
  if (input) {
    input = input.replace(/\D/g, '')

    // Trim the remaining input to ten characters, to preserve phone number format
    input = input.substring(0, 10)

    // Based upon the length of the string, we add formatting as necessary
    var size = input.length
    if (size === 0) {
      /*eslint no-empty: "error"*/
    } else if (size < 4) {
      input = '(' + input
    } else if (size < 7) {
      input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6)
    } else {
      input = '(' + input.substring(0, 3) + ') ' + input.substring(3, 6) + '-' + input.substring(6, 10)
    }
    return input
  } else {
    return ''
  }
}

export function currencyFormatter(value) {
  const numberValue = Number(value)
  const valueFormatted = Number.isNaN(numberValue)
    ? '$XX.XX'
    : `$${numberValue.toLocaleString('en-us', {
        minimumFractionDigits: 2
      })}`
  return valueFormatted
}

export function ccidFormatter(value) {
  if (value === undefined || value === null) {
    return ''
  }
  const valueString = value.toString()
  return 'CC-' + valueString.padStart(4, '0')
}

export const zipCodeInput = (input) => {
  if (input) {
    input = input.replace(/\D/g, '')
    input = input.substring(0, 5)
    return input
  } else {
    return ''
  }
}

export const currencyInput = (input) => {
  if (input) {
    input = input.replace(/\D/g, '')
    return input
  } else {
    return ''
  }
}

export const sortByName = (a, b) => {
  const name1 = a.name.toUpperCase()
  const name2 = b.name.toUpperCase()

  let comparison = 0

  if (name1 > name2) {
    comparison = 1
  } else if (name1 < name2) {
    comparison = -1
  }
  return comparison
}

export const sortByDate = (a, b) => {
  return Date.parse(a.created) - Date.parse(b.created)
}

export const getRole = (user) => {
  if (user.is_admin) {
    return 'Admin'
  } else if (user.is_owner) {
    return 'Owner'
  } else if (user.is_manager) {
    return 'Manager'
  } else if (user.role) {
    return user.role
  } else {
    return 'Tech'
  }
}

export const validations = {
  name: (value) => {
    return !!value
  },
  email: (value) => {
    return value && value.match(/^[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+(?:\.[a-zA-Z0-9!#$%&'*+\-/=?^_`{|}~]+)*@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/)
  },
  phone: (value) => {
    if (value) {
      return value.replace(/\D/g, '').length >= 10
    }
    return false
  },
  price: (value) => {
    return !!value || value === 0
  },
  city: (value) => {
    return value && value.match(/^[A-Za-z-' ]+$/)
  },
  state: (value) => {
    return !!value
  },
  zip: (value) => {
    return !!value
  },
  address1: (value) => {
    return !!value
  },
  category: (value) => {
    return !!value
  },
  vin: (value) => {
    if (value) {
      return value.length === 17
    }
    return false
  },
  qboClassName: (value) => {
    return value.match(/^[A-Za-z]*$/)
  }
}

const validateScore = (value, limit = 10) => {
  if (!value || value === ' ') {
    return false
  }
  const intValue = parseInt(value)
  return 0 <= intValue && intValue <= limit
}

export const scoreValidations = {
  preCalDocScore: (value) => validateScore(value),
  preCalPhotoScore: (value) => validateScore(value),
  prodCalScore: (value) => validateScore(value),
  prodCalSrvCustScore: (value) => validateScore(value),
  calDocScore: (value) => validateScore(value, 20),
  calPhotoScore: (value) => validateScore(value, 20),
  postDocScore: (value) => validateScore(value),
  postPhotoScore: (value) => validateScore(value)
}

export const isTech = (user) => {
  return !user.is_admin && !user.is_owner && !user.is_manager
}

export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  )
}

export const processCenterRoles = (user) => {
  if (user.is_admin) {
    return ['Admin', []]
  }
  if (user.is_owner) {
    return ['Owner', user.calibrationCentersOwned]
  }
  if (user.is_manager) {
    return ['Manager', user.calibrationCentersManaged]
  }
  if (user.calibrationCentersTech.length > 0) {
    return ['Tech', user.calibrationCentersTech]
  }
  return ['?', []]
}

export function downloadAsFile(data, fileName) {
  const element = document.createElement('a')
  element.href = URL.createObjectURL(data)
  element.download = fileName
  document.body.appendChild(element) // Required for this to work in FireFox
  element.click()
}

export const formatDate = (date, format) => {
  if (date) {
    return dayjs(date).format(format)
  }
  return null
}

export const formatTime = (seconds) => {
  const days = Math.floor(seconds / (3600 * 24))
  const hours = Math.floor((seconds % (3600 * 24)) / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = Math.floor(seconds % 60)

  let formattedTime = ''
  if (days > 0) formattedTime += `${days} ${days > 1 ? 'days' : 'day'}`
  else if (hours > 0) formattedTime += `${hours} ${hours > 1 ? 'hours' : 'hour'}`
  else if (minutes > 0) formattedTime += `${minutes} ${minutes > 1 ? 'mins' : 'min'}`
  else formattedTime += `${secs} seconds`

  return formattedTime
}

export const convertToLocalTimezone = (str) => {
  const adjustedDateTimeString = str
    .replace(' ', 'T') // Correctly separate date and time with T
    .replace(/\.\+/, '+')
  const date = new Date(adjustedDateTimeString)
  return date.toLocaleDateString()
}

export const roundToGreatestInteger = (number) => {
  // Check if the number has a decimal part
  if (number % 1 !== 0) {
    // If there's a decimal part, add 1 to its integer part
    return Math.floor(number) + 1
  } else {
    // If the number is already an integer, return the same number
    return number
  }
}

export const debounce = (func, delay) => {
  let timeoutId
  return function (...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => func.apply(this, args), delay)
  }
}

export const formatDateTime = (date, format) => {
  if (date) {
    const dateString = dayjs(date).format(format)
    const hours = (date.getHours() % 12 || 12).toString().padStart(2, '0')
    const minutes = date.getMinutes().toString().padStart(2, '0')
    return `${dateString}  ${hours}h${minutes}m`
  }
  return null
}

export const htmlParseOptions = {
  replace: (domNode) => {
    if (domNode.type === 'tag' && domNode.name === 'p') {
      // Modify attributes or add styles here
      const modifiedProps = {
        ...domNode.attribs, // Include existing attributes
        style: { margin: '0', marginBottom: '8px', ...domNode.attribs.style } // Override margin to 0
      }
      return React.createElement(domNode.name, modifiedProps, domToReact(domNode.children, htmlParseOptions))
    }
    // Return undefined to preserve the original element
    return undefined
  }
}

export const checkPermission = ({ currentUserRole, targetUserRole }) => {
  return ROLES_HIERARCHY.indexOf(currentUserRole) > ROLES_HIERARCHY.indexOf(targetUserRole)
}

export const handleDownloadOEMStatementPDF = async (id) => {
  try {
    const dataBlob = await downloadOEMStatementPDF(id)
    const downloadURL = URL.createObjectURL(dataBlob.data)
    window.open(downloadURL, '_blank')
  } catch (error) {
    Logger.error({ payload: { file: 'utils', function: 'handleDownloadOEMStatementPDF' }, message: error })
  }
}

export const convertToMBSize = (size) => `${(size / 1000000).toFixed(2)} MB`
